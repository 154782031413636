import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import { reads } from 'macro-decorators';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import CallScreenSharingContainer from 'frontend/components/call/screen-sharing-container';
import CallSpeaker from 'frontend/components/call/speaker';
import Component from '@glimmer/component';
import WaitingPlaceholder from 'frontend/components/waiting-placeholder';
import Whiteboard from 'frontend/components/whiteboard';
import styles from './main-view.module.scss';
export default class CallMainView extends Component {
    @service('chime.local-audio-video')
    localAudioVideo;
    @service('chime.remote-videos')
    remoteVideos;
    @service('chime.content-sharing')
    contentSharing;
    @service
    appointment;
    @service
    uiElements;
    @tracked
    mainViewWidth = 0;
    @tracked
    mainViewHeight = 0;
    widthProportion = 16;
    heightProportion = 9;
    element = null;
    @reads('appointment.mainAttendeeId')
    mainAttendeeId;
    @reads('appointment.localAttendeeId')
    localAttendeeId;
    @reads('appointment.hasMainView')
    hasMainView;
    @reads('contentSharing.isWhiteboardShared')
    showWhiteboard;
    initMainView = modifier((element1)=>{
        if (!this.args.sidebarElement) return;
        let resizeObserver1 = new ResizeObserver(this.resizeMainVideo);
        this.element = element1;
        resizeObserver1.observe(this.args.sidebarElement);
        window.addEventListener('resize', this.resizeMainVideo);
        this.resizeMainVideo();
        return ()=>{
            window.removeEventListener('resize', this.resizeMainVideo);
            resizeObserver1.disconnect();
        };
    });
    @action
    resizeMainVideo() {
        this.resizeMainVideoTask.perform();
    }
    get style() {
        return htmlSafe(`width:${this.mainViewWidth}px;height:${this.mainViewHeight}px;`);
    }
    get showScreenShare() {
        return this.contentSharing.isScreenShared && !this.uiElements.pinnedAttendeeId;
    }
    get mainVideoTileId() {
        if (!this.mainAttendeeId) return null;
        return this.mainAttendeeId === this.localAttendeeId ? this.localAudioVideo.tileId : this.remoteVideos.attendeeIdToTileId[this.mainAttendeeId];
    }
    resizeMainVideoTask = restartableTask(async ()=>{
        await timeout(100);
        if (!this.element?.isConnected) return;
        let { widthProportion: widthProportion1, heightProportion: heightProportion1 } = this;
        let { height: height1, width: contentWidth1 } = this.element.parentElement.getBoundingClientRect();
        let width1 = contentWidth1 - this.args.sidebarElement.clientWidth;
        if (height1 / width1 < heightProportion1 / widthProportion1) {
            this.mainViewWidth = Math.round((height1 / heightProportion1) * widthProportion1);
            this.mainViewHeight = height1;
        } else {
            this.mainViewWidth = width1;
            this.mainViewHeight = Math.round((width1 / widthProportion1) * heightProportion1);
        }
    });
    static{
        template(`
    {{#if this.hasMainView}}
      <div class="{{styles.component}} video" {{this.initMainView}} data-test-main-view>
        {{#if this.showScreenShare}}
          <CallScreenSharingContainer data-test-main-view-screen-sharing />
        {{else if this.showWhiteboard}}
          <Whiteboard />
        {{else if this.mainAttendeeId}}
          <CallSpeaker
            @videoTileId={{this.mainVideoTileId}}
            @attendeeId={{this.mainAttendeeId}}
            @isMainView={{true}}
            style={{this.style}}
          />
        {{/if}}
      </div>
    {{else}}
      <WaitingPlaceholder />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
