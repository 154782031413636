import { reads } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class UiElementsService extends Service {
  @service('chime.meeting-manager') meetingManager;

  @tracked isSidebarShown = true;
  @tracked isGridView = false;
  @tracked isSelfViewShown = true;
  @tracked pinnedAttendeeId = null;

  @reads('meetingManager.localAttendeeId') localAttendeeId;

  toggleSidebar() {
    this.isSidebarShown = !this.isSidebarShown;
  }

  toggleGridView() {
    this.isGridView = !this.isGridView;
  }

  toggleSelfView() {
    this.isSelfViewShown = !this.isSelfViewShown;

    if (this.pinnedAttendeeId !== this.localAttendeeId) return;

    this.pinnedAttendeeId = null;
  }

  handlePin(attendeeId) {
    this.pinnedAttendeeId = attendeeId === this.pinnedAttendeeId ? null : attendeeId;
  }

  handleSharing() {
    this.pinnedAttendeeId = null;
    if (!this.isGridView) return;

    this.toggleGridView();
  }

  reset() {
    if (this.isGridView) this.toggleGridView();
    if (this.pinnedAttendeeId) this.handlePin(this.pinnedAttendeeId);
    if (!this.isSelfViewShown) this.toggleSelfView();
    if (!this.isSidebarShown) this.toggleSidebar();
  }
}
