import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { offset } from '@floating-ui/dom';
import { on } from '@ember/modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import AttachPopover from 'ember-attacher/components/attach-popover';
import Component from '@glimmer/component';
import styles from './share-popover.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class CallControlsSharePopover extends Component {
    @service('twilio/room')
    twilioRoom;
    @service
    session;
    @service
    audioVideoAdapter;
    @service
    floatingUiElements;
    @reads('session.roomModel.featureThChime')
    featureThChime;
    @reads('floatingUiElements.controlsShown')
    controlsShown;
    middleware = [
        offset(6)
    ];
    displayMediaAvailable = navigator.mediaDevices.getDisplayMedia || navigator.getDisplayMedia;
    @action
    toggleScreenShare() {
        this.audioVideoAdapter.toggleScreenSharing();
    }
    @action
    toggleWhiteboardShare() {
        this.audioVideoAdapter.toggleWhiteboardSharing();
    }
    static{
        template(`
    {{#if this.controlsShown}}
      <AttachPopover
        @class={{styles.popover}}
        @placement="top-start"
        @floatingElementContainer=".room-wrapper"
        @middleware={{this.middleware}}
      >
        <p>Share</p>
        <ul role="menu" class={{styles.sharemenu}} id={{@ariaControls}}>
          <li
            role="menuitem"
            class={{unless this.displayMediaAvailable "disabled"}}
            {{on "click" this.toggleScreenShare}}
            data-test-share-screen
          >
            {{svgJar "computer-screen" class=styles.icon}}
            <span>Your screen</span>
          </li>
          <li role="menuitem" {{on "click" this.toggleWhiteboardShare}} data-test-share-whiteboard>
            {{svgJar "whiteboard" class=styles.icon}}
            <span>Whiteboard</span>
          </li>
        </ul>
      </AttachPopover>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
