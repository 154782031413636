import Service from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class ChimeSdkService extends Service {
  async load() {
    if (this.sdk) return;
    this.sdk = await import('amazon-chime-sdk-js');
  }
}
