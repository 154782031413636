import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import Component from '@glimmer/component';
import styles from './action-button.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class ActionButton extends Component {
    @action
    click() {
        this.args.onClick?.();
    }
    static{
        template(`
    <button {{on "click" this.click}} class={{styles.component}} type="button" ...attributes>
      {{svgJar @icon}}
      <div>{{@title}}</div>
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
