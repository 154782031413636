/* import __COLOCATED_TEMPLATE__ from './palette.hbs'; */
import { attributeBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './palette.module.scss';

@classic
@attributeBindings('role', 'testAttribute:data-test-palette-name')
@classNames(styles.component)
export default class WhiteboardToolbarPalette extends Component {
  styles = styles;

  role = 'radiogroup';

  @computed('name')
  get testAttribute() {
    return isEmberTesting() ? this.name : null;
  }
}
