/* import __COLOCATED_TEMPLATE__ from './event.hbs'; */
import { action } from '@ember/object';
import { attributeBindings, classNames } from '@ember-decorators/component';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './event.module.scss';

@classic
@attributeBindings('data-test-event-notification')
@classNames(styles.component)
export default class NotificationsEvent extends Component {
  @service floatingUiElements;

  'data-test-event-notification' = isEmberTesting();

  @reads('floatingUiElements.eventNotificationOptions.text') notificationText;
  @reads('floatingUiElements.eventNotificationOptions.icon') notificationIcon;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.floatingUiElements.hideEventNotificationTask.perform();
  }

  @action
  closeNotification(evt) {
    evt?.stopPropagation();
    this.floatingUiElements.hideEventNotification();
  }
}
