/* import __COLOCATED_TEMPLATE__ from './pen-size-select.hbs'; */
import { PEN_SIZE_OPTIONS } from 'frontend/constants/whiteboard';
import { attributeBindings } from '@ember-decorators/component';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@attributeBindings('role', 'testAttribute:data-test-pen-size-select')
export default class WhiteboardPenSizeSelect extends Component {
  role = 'radiogroup';
  penSizeOptions = PEN_SIZE_OPTIONS;
  testAttribute = isEmberTesting();
}
