import { BASE_HEIGHT, BASE_WIDTH } from 'frontend/constants/whiteboard';
import BaseEntity from 'frontend/entities/whiteboard/base';

const PADDING = 40;
const MAX_WIDTH = BASE_WIDTH - PADDING * 2;
const MAX_HEIGHT = BASE_HEIGHT - PADDING * 2;

export default class WhiteboardImage extends BaseEntity {
  type = 'image';

  setInitAttributes({ imageElement, ...attributes }) {
    this.konvaObject.setAttrs({
      image: imageElement,
      src: imageElement.src,
      ...this.calculateBoundingRect(imageElement),
      ...attributes,
    });
  }

  calculateBoundingRect(imageElement) {
    let { width: initialWidth, height: initialHeight } = imageElement;
    let ratio = Math.max(initialWidth / MAX_WIDTH, initialHeight / MAX_HEIGHT, 1);
    let width = initialWidth / ratio;
    let height = initialHeight / ratio;
    let x = (BASE_WIDTH - width) / 2;
    let y = (BASE_HEIGHT - height) / 2;

    return { width, height, x, y };
  }

  resize({ width, height }) {
    this.update({ width, height });
  }
}
