import { SETTINGS_TABS } from 'frontend/constants/settings';
import { reads } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class MediaSettingsService extends Service {
  @service session;
  @service floatingUiElements;

  @tracked activeSettingsTab;

  @reads('session.isHost') isHost;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;

  openSettingsModal(tab) {
    this.activeSettingsTab = tab || this.defaultSettingsTab();
    this.floatingUiElements.isSettingsModalShown = true;
  }

  closeSettingsModal() {
    this.floatingUiElements.isSettingsModalShown = false;
  }

  defaultSettingsTab() {
    return this.isHost && this.featureThClinicianAuth
      ? SETTINGS_TABS.hostControl
      : SETTINGS_TABS.video;
  }
}
