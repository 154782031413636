const EVENT_LOGGER_URL = '/frontend/events';

export default class EventLogger {
  constructor(chimeSdk) {
    let { POSTLogger, LogLevel } = chimeSdk;

    let loggerOptions = {
      url: EVENT_LOGGER_URL,
      logLevel: LogLevel.INFO,
    };

    this.postLogger = new POSTLogger(loggerOptions);
  }

  log(event, attributes) {
    this.postLogger.info(JSON.stringify({ event, attributes }));
  }

  destroy() {
    this.postLogger.destroy();
  }
}
