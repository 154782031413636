import { action } from '@ember/object';
import { keepLatestTask, timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import EventLogger from 'frontend/entities/chime/event-logger';
import Service, { service } from '@ember/service';

const METRICS_INTERVAL = 3000;
export const MEETING_EVENTS = {
  meetingStartSucceeded: 'meetingStartSucceeded',
  meetingEnded: 'meetingEnded',
  receivingAudioDropped: 'receivingAudioDropped',
  signalingDropped: 'signalingDropped',
  meetingReconnected: 'meetingReconnected',
  meetingFailed: 'meetingFailed',
  metricsDidReceive: 'metricsDidReceive',
};

export default class ChimeEventManagerService extends Service {
  @service('chime.sdk') sdkService;
  @service('chime.meeting-manager') meetingManager;
  @service('chime.local-audio-video') localAudioVideo;

  logger;
  externalMeetingId;

  @reads('sdkService.sdk') chimeSdk;
  @reads('meetingManager.meetingSession') meetingSession;

  sendClientMetricsTask = keepLatestTask(async clientMetricReport => {
    if (!this.externalMeetingId) return;

    let observableMetrics = clientMetricReport.getObservableMetrics();
    let observableVideoMetrics = clientMetricReport.getObservableVideoMetrics();
    let metricsMap = observableVideoMetrics[clientMetricReport.selfAttendeeId] || {};
    let [videoMetrics] = Object.values(metricsMap);
    let attributes = {
      attendeeId: clientMetricReport.selfAttendeeId,
      audioPacketLossPercent: observableMetrics.audioPacketLossPercent,
      audioUpstreamJitterMs: observableMetrics.audioUpstreamJitterMs,
      audioUpstreamRoundTripTimeMs: observableMetrics.audioUpstreamRoundTripTimeMs,
      externalMeetingId: this.externalMeetingId,
    };

    if (videoMetrics?.videoUpstreamBitrate) {
      attributes.videoUpstreamBitrate = videoMetrics.videoUpstreamBitrate;
      attributes.videoUpstreamJitterMs = videoMetrics.videoUpstreamJitterMs;
      attributes.videoUpstreamPacketLossPercent = videoMetrics.videoUpstreamPacketLossPercent;
      attributes.videoUpstreamRoundTripTimeMs = videoMetrics.videoUpstreamRoundTripTimeMs;
    }

    this.logger.log(MEETING_EVENTS.metricsDidReceive, attributes);
    await timeout(METRICS_INTERVAL);
  });

  setup() {
    let { eventController, audioVideo } = this.meetingSession;

    this.externalMeetingId = this.meetingSession.configuration.externalMeetingId;
    this.metricsObserver = { metricsDidReceive: this.metricsDidReceive };
    this.eventsObserver = { eventDidReceive: this.eventDidReceive };
    this.logger = new EventLogger(this.chimeSdk);

    eventController.addObserver(this.eventsObserver);
    audioVideo.addObserver(this.metricsObserver);
  }

  @action
  metricsDidReceive(clientMetricReport) {
    this.sendClientMetricsTask.perform(clientMetricReport);
  }

  @action
  eventDidReceive(name, attrs) {
    let { meetingHistory, ...attributes } = attrs;

    switch (name) {
      case MEETING_EVENTS.meetingStartSucceeded:
        this.logger.log(name, attributes);
        break;
      case MEETING_EVENTS.meetingEnded:
        this.logger.log(name, {
          attendeeId: attributes.attendeeId,
          timestampMs: attributes.timestampMs,
        });
        this.logger.destroy();
        break;
    }
  }

  reset() {
    this.externalMeetingId = null;
    this.meetingSession?.eventController?.removeObserver(this.eventsObserver);
    this.meetingSession?.audioVideo?.removeObserver(this.metricsObserver);
    this.sendClientMetricsTask.cancelAll();
  }
}
