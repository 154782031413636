import { copy } from 'ember-copy';
import emberO from '@ember/object';
import pht from 'frontend/utils/persistent-hash-table';

export default function (key) {
  return {
    get() {
      return pht.get(key);
    },
    set(_, value) {
      pht.set(key, value);
      return value;
    },
  };
}

export function computedPersistentObject(key, defaults = {}) {
  return {
    get() {
      return emberO.create(JSON.parse(pht.get(key)) || copy(defaults, true));
    },
    set(_, value) {
      pht.set(key, JSON.stringify(value));
      return value;
    },
  };
}

export function computedPersistentJSON(key) {
  return {
    get() {
      try {
        return JSON.parse(pht.get(key));
      } catch {
        return null;
      }
    },
    set(_, value) {
      pht.set(key, JSON.stringify(value));
      return value;
    },
  };
}
