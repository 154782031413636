export function formatNetworkQualityLog(message) {
  try {
    let filteredResult = ['audio', 'video'].reduce((result, title) => {
      if (result.level === 5) {
        return { level: 5 };
      }
      ['recv', 'send'].forEach(key => {
        if (result[title][key] === 5) {
          delete result[title][`${key}Stats`];
        } else {
          Object.keys(result[title][`${key}Stats`]).forEach(statTitle => {
            if (result[title][`${key}Stats`][statTitle].level === 5) {
              delete result[title][`${key}Stats`][statTitle];
            }
          });
        }
      });
      return result;
    }, message);
    return filteredResult;
  } catch (e) {
    return message;
  }
}
