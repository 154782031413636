import { template } from "@ember/template-compiler";
import styles from './title.module.scss';
export default template(`
  <div class={{styles.component}}>
    {{@title}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
