import { PARTICIPANT_PERMISSIONS, TIMER_START_TYPES } from 'frontend/constants/settings';
import { SETTINGS_PATH } from 'frontend/constants';
import { cannotChangeSettings } from 'frontend/utils/dialogs';
import { computed, getProperties, setProperties } from '@ember/object';
import { headers } from 'frontend/utils/fetch-data';
import { notEmpty, reads } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class AppointmentSettingsService extends Service {
  timerStartType = TIMER_START_TYPES.calendarAppointmentStarts;
  timerCountsDown = false;
  backgroundEnabled;
  chatEnabled;
  confirmEnding;
  sharingEnabled;
  waitingRoomEnabled;

  @reads('updateSettingsTask.isRunning') isSettingUpdating;
  @notEmpty('updateSettingsTask.last') isDirty;

  @task(function* (attributes) {
    let { timerStartType, timerCountsDown } = this;
    let body = JSON.stringify({
      data: { type: 'appointment_settings', id: this.id, attributes },
    });
    let init = { method: 'PATCH', body, headers: headers() };

    this.change(attributes);

    let { ok } = yield fetch(`${SETTINGS_PATH}/${this.id}`, init);
    if (ok) return;

    this.change({ timerStartType, timerCountsDown });

    let { isConfirmed } = yield cannotChangeSettings();
    if (!isConfirmed) return;

    this.updateSettingsTask.perform(attributes);
  })
  updateSettingsTask;

  @computed(...PARTICIPANT_PERMISSIONS)
  get participantPermissions() {
    return getProperties(this, PARTICIPANT_PERMISSIONS);
  }

  change(settings) {
    setProperties(this, settings);
  }

  update(updatedParams) {
    return this.updateSettingsTask.perform(updatedParams);
  }
}
