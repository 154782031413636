import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import classic from 'ember-classic-decorator';

import Route from '@ember/routing/route';

@classic
export default class AppointmentRoomRoute extends Route {
  @service persistentProperties;
  @service session;
  @service router;

  queryParams = { referrer: { replace: true } };

  @reads('persistentProperties.userName') userName;

  beforeModel(transition) {
    super.beforeModel(transition);
    if (this.userName) return;
    this.router.transitionTo('appointment');
  }

  model(params, transition) {
    this.session.setInWaitingRoom(!this.isHost);

    return super.model(params, transition);
  }

  @action
  willTransition(transition) {
    transition.router.refresh();
  }
}
