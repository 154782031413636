import { TRACK_KINDS } from 'frontend/constants/twilio';

export const isDataTrack = track => {
  return track?.kind === TRACK_KINDS.data;
};

export const isVideoTrack = track => {
  return track?.kind === TRACK_KINDS.video;
};

export const isAudioTrack = track => {
  return track?.kind === TRACK_KINDS.audio;
};
