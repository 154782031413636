/* import __COLOCATED_TEMPLATE__ from './speaker.hbs'; */
import { TRACK_TYPE } from 'frontend/constants';
import { action, computed, set } from '@ember/object';
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { htmlSafe } from '@ember/template';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { modifier } from 'ember-modifier';
import { observes } from '@ember-decorators/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { throttle } from '@ember/runloop';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from 'frontend/components/call/speaker.module.scss';

@classic
@classNameBindings('highlighted', 'speaker::hidden', 'visible::hidden', 'gridView', 'mirrorVideo')
@attributeBindings(
  'isLocalParticipant:data-test-local-participant',
  'testSpeakerContainer:data-test-speaker-container'
)
@classNames(styles.component)
export default class TwilioSpeaker extends Component {
  styles = styles;

  @service('twilio/video-stream') videoStream;
  @service('twilio/room') twilioRoom;
  @service floatingUiElements;
  @service session;
  @service appointmentSettings;
  @service persistentProperties;
  @service audioVideoAdapter;

  index;
  mainView = false;
  highlighted = false;
  defaultRatio = 9 / 16;
  morePopoverShown = false;
  hidePin = false;
  #cachedVideoSource;

  @reads('floatingUiElements.controlsShown') controlsShown;
  @reads('videoStream.isSharingScreen') isSharingScreen;
  @reads('videoStream.selectedParticipant') selectedParticipant;
  @reads('videoStream.shareParticipant') shareParticipant;
  @reads('videoStream.participants') participants;
  @reads('videoStream.localParticipant') localParticipant;
  @reads('videoStream.dominantSpeaker') dominantSpeaker;
  @reads('videoStream.mainSpeaker') mainSpeaker;
  @reads('videoStream.roomModel.featureTwilioPeerToPeer') featureTwilioPeerToPeer;
  @reads('twilioRoom.disableNetworkQualityIndicator') disableNetworkQualityIndicator;
  @reads('twilioRoom.isWhiteboarding') isWhiteboarding;
  @reads('speaker.reconnecting') reconnecting;
  @reads('speaker.userData.profileImageUrl') profileImageUrl;
  @reads('speaker.noVideo') noVideoStream;
  @reads('session.isHost') isHost;
  @reads('appointmentSettings.waitingRoomEnabled') waitingRoomEnabled;

  @computed('persistentProperties.isSelfViewMirrored', 'isLocalParticipant')
  get mirrorVideo() {
    return this.persistentProperties.isSelfViewMirrored && this.isLocalParticipant;
  }

  @computed('speaker.dimensionsChangeTrigger')
  get style() {
    if (this.speaker?.stream) {
      let { width, height } = this.speaker.stream.videoDimensions;
      if (!this.mainView && this.speaker.stream.hasVideo && width < height) {
        return htmlSafe(`width:${this.defaultRatio * 100}%;`);
      }
    }
    return htmlSafe('width:100%;');
  }

  @computed(
    'index',
    'participants.[]',
    'localParticipant.{noVideo,muted}',
    'mainView',
    'mainSpeaker'
  )
  get speaker() {
    if (this.mainView) {
      return this.mainSpeaker;
    } else if (this.index === undefined) {
      return this.localParticipant;
    } else {
      return this.participants[this.index];
    }
  }

  @computed('speaker')
  get testSpeakerContainer() {
    if (!isEmberTesting()) return null;

    return this.speaker?.sid || true;
  }

  @computed('speaker', 'localParticipant')
  get isLocalParticipant() {
    return this.speaker === this.localParticipant;
  }

  @computed(
    'gridView',
    'isWhiteboarding',
    'hidePin',
    'isHost',
    'isPinned',
    'waitingRoomEnabled',
    'isLocalParticipant'
  )
  get isPinVisible() {
    if (this.hidePin || this.gridView || this.isWhiteboarding) return false;

    return this.isPinned || !this.waitingRoomEnabled || !this.isHost || this.isLocalParticipant;
  }

  @computed('isPinned', 'isPinVisible', 'mainView', 'isMoreButtonVisible')
  get pinButtonClass() {
    return [
      'pin-button',
      !this.isPinVisible && 'hidden',
      this.isPinned && 'pinned',
      this.mainView && 'main-view',
      this.isPinned && this.isMoreButtonVisible && 'shifted',
    ]
      .filter(Boolean)
      .join(' ');
  }

  @computed('isHost', 'isLocalParticipant', 'waitingRoomEnabled')
  get isMoreButtonVisible() {
    return this.isHost && !this.isLocalParticipant && this.waitingRoomEnabled;
  }

  @computed('morePopoverShown', 'isMoreButtonVisible')
  get moreButtonClasses() {
    return ['more-button', !this.isMoreButtonVisible && 'hidden', this.morePopoverShown && 'active']
      .filter(Boolean)
      .join(' ');
  }

  @computed(
    'speaker',
    'mainSpeaker',
    'mainView',
    'selectedParticipant',
    'shareParticipant',
    'gridView'
  )
  get visible() {
    if (this.mainView || (this.speaker && this.gridView)) {
      return true;
    }

    if (!this.speaker) return false;

    return (
      (this.speaker !== this.mainSpeaker || this.shareParticipant) &&
      this.speaker !== this.selectedParticipant
    );
  }

  @computed('mainSpeaker', 'speaker', 'selectedParticipant')
  get isPinned() {
    return this.selectedParticipant && this.mainSpeaker === this.speaker;
  }

  @computed('gridView', 'isWhiteboarding')
  get isPinOptionShown() {
    return !(this.gridView || this.isWhiteboarding);
  }

  @computed('isPinned', 'isHost', 'isLocalParticipant', 'isPinOptionShown')
  get moreButtonOptions() {
    let showSendToWaitingRoomOption = this.isHost && !this.isLocalParticipant;

    return [
      this.isPinOptionShown && { title: this.isPinned ? 'Unpin' : 'Pin', onClick: this.handlePin },
      showSendToWaitingRoomOption && {
        title: 'Send to waiting room',
        onClick: this.handleSendToWaitingRoom,
      },
    ].filter(Boolean);
  }

  // eslint-disable-next-line ember/no-observers
  @observes(
    'mainView',
    'speaker.muted',
    'dominantSpeaker',
    'shareParticipant',
    'selectedParticipant',
    'gridView'
  )
  highlight() {
    // eslint-disable-next-line ember/no-runloop
    throttle(this, this._setHighlight, 1000);
  }

  @action
  _setHighlight() {
    if (
      this.selectedParticipant ||
      this.isSharingScreen ||
      this.shareParticipant ||
      this.gridView
    ) {
      let isHighlighted =
        !this.mainView && !this.speaker?.muted && this.dominantSpeaker === this.speaker;
      set(this, 'highlighted', isHighlighted);
    } else {
      set(this, 'highlighted', false);
    }
  }

  @action
  handlePin() {
    this.videoStream.handlePin(this.speaker);
  }

  @action
  handleSendToWaitingRoom() {
    this.session.sendToWaitingRoom(this.speaker);
  }

  @computed(`speaker.tracks`)
  get audioSource() {
    return this.audioVideoAdapter.getTrack({
      participant: this.speaker,
      trackType: TRACK_TYPE.audio,
    });
  }

  @computed(`speaker.tracks`)
  get videoSource() {
    return this.audioVideoAdapter.getTrack({
      participant: this.speaker,
      trackType: TRACK_TYPE.camera,
    });
  }

  @action
  markSpeakerStalled() {
    set(this.speaker, 'stalled', true);
  }

  @action
  unmarkSpeakerStalled() {
    set(this.speaker, 'stalled', false);
  }

  initVideo = modifier(element => {
    let videoSource = this.videoSource;
    let detachFn;
    if (videoSource && this.#cachedVideoSource !== videoSource) {
      this.#cachedVideoSource = videoSource;
      detachFn = this.audioVideoAdapter.attachMediaSource({ element, source: videoSource });
    }

    return () => {
      detachFn?.();
      if (element.isConnected) return;

      this.#cachedVideoSource = null;
    };
  });

  initAudio = modifier(element => {
    this.audioVideoAdapter.attachMediaSource({ element, source: this.audioSource });
  });
}
