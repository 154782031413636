/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import {
  attributeBindings,
  classNameBindings,
  classNames,
  tagName,
} from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './item.module.scss';

@tagName('button')
@attributeBindings('ariaLabel', 'disabled')
@classNameBindings('separator')
@classNames(styles.component)
@classic
export default class TwilioHeaderControlsItem extends Component {
  @reads('controlOptions.separator') separator;
  @reads('controlOptions.disabled') disabled;
  @reads('controlOptions.active') active;
  @reads('controlOptions.ariaLabel') ariaLabel;

  @action
  click() {
    if (!this.disabled) {
      this.onClick?.();
    }
  }
}
