import { service } from '@ember/service';
import { submittedKey } from 'frontend/components/post-call-rating';
import Route from '@ember/routing/route';
import moment from 'moment-timezone';
import pht from 'ember-simplepractice/utils/persistent-hash-table';

export default class AppointmentRatingRoute extends Route {
  @service router;

  beforeModel() {
    super.beforeModel(...arguments);

    let { startTime } = this.modelFor('appointment');
    let { room_id: roomId } = this.paramsFor('appointment');
    if (startTime.isAfter(moment()) || pht.get(submittedKey(roomId))) {
      return this.router.transitionTo('appointment');
    }
  }

  model() {
    let { featureTwilioPeerToPeer } = this.modelFor('appointment');
    let { room_id: roomId } = this.paramsFor('appointment');
    return { roomId, roomType: featureTwilioPeerToPeer ? 'peer-to-peer' : 'group' };
  }
}
