/* import __COLOCATED_TEMPLATE__ from './post-call-rating.hbs'; */
import { action } from '@ember/object';
import { gte, lt } from 'macro-decorators';
import { service } from '@ember/service';
import { toLeft } from 'ember-animated/transitions/move-over';
import { tracked } from 'tracked-built-ins';
import Component from '@glimmer/component';
import fade from 'ember-animated/transitions/fade';
import pht from 'ember-simplepractice/utils/persistent-hash-table';
import styles from './post-call-rating.module.scss';

export function submittedKey(roomId) {
  return `rating/${roomId}`;
}

const STARS = [
  { name: 'Very poor' },
  { name: 'Poor' },
  { name: 'Fair' },
  { name: 'Good' },
  { name: 'Excellent' },
];
const EXCELLENT_RATING = STARS.findIndex(({ name }) => name === 'Excellent');

export default class PostCallRating extends Component {
  @service mixpanel;
  @service router;
  @service session;

  @tracked selectedStar = undefined;
  @tracked chosenStar = undefined;
  @tracked selectedReasons = [];
  @tracked isSubmitted = false;

  transitionFade = fade;
  transitionToLeft = toLeft;
  stars = STARS;
  poorReasons = [
    'Video was frozen',
    'Audio was slow',
    'Video and audio weren’t in sync',
    'Shared screen was frozen / blurry',
    'Loud fan noise / overheating',
    'Other',
  ];
  styles = styles;

  @gte('selectedStar', 0) hasSelectedStar;
  @gte('chosenStar', 0) hasChosenStar;
  @lt('chosenStar', EXCELLENT_RATING) isLessThanExcellent;

  get activeStar() {
    return this.hasSelectedStar ? this.selectedStar : this.chosenStar;
  }

  @action
  toggleReason(reason) {
    this.selectedReasons = this.selectedReasons.includes(reason)
      ? this.selectedReasons.filter(r => r !== reason)
      : [...this.selectedReasons, reason];
  }

  @action
  submit() {
    let { featureThChime, featureThAdaptiveSimulcast, roomId } = this.session.roomModel;
    let properties = {
      'Star Rating': this.chosenStar + 1,
      'Video Platform': featureThChime ? 'Chime' : 'Twilio',
      'Issue Type': this.isLessThanExcellent ? this.selectedReasons : [],
      'Unique TH Link': `${location.origin}${this.router.urlFor('appointment')}`,
      'max_participants_on_call': this.session.maxParticipantsOnCall,
    };

    if (!featureThChime) {
      properties.simulcast = featureThAdaptiveSimulcast ? 'enabled' : 'disabled';
    }

    pht.set(submittedKey(roomId), true);
    this.isSubmitted = true;
    this.mixpanel.track('rating: Telehealth call', properties);
  }
}
