import { set, setProperties } from '@ember/object';

export default class BaseEntity {
  konvaObject;
  isLocked = false;
  history = [];
  uuid;

  constructor({ uuid, KonvaConstructor, ...attributes }) {
    let konvaObject = new KonvaConstructor({ uuid, id: uuid, rotation: 0, visible: true });

    setProperties(this, { konvaObject, uuid });
    this.setInitAttributes(attributes);
  }

  update(attrs, actionData) {
    this.konvaObject.setAttrs(attrs);
    this._addToHistory(actionData);
  }

  setIsLocked(isLocked) {
    set(this, 'isLocked', isLocked);
  }

  addToLayer(layer, actionData) {
    layer.add(this.konvaObject);
    this._addToHistory(actionData);
  }

  processActionStatusChange(actionId, undone) {
    let action = this.history.findBy('id', actionId);

    if (!action) return;

    set(action, 'undone', !!undone);

    let relevantAction = this._getRelevantAction();
    let createActionUndone = undone && !relevantAction;
    let isDeleted =
      createActionUndone || this.history.some(({ attrs, undone }) => !attrs.visible && !undone);
    let attrs = { ...relevantAction?.attrs, visible: !isDeleted };

    this.konvaObject.setAttrs(attrs);
  }

  _addToHistory(actionData, attributes) {
    if (!actionData || this.isLocked) return;

    let attrs = attributes || this.konvaObject.clone().getAttrs();
    let action = { attrs, ...actionData };
    let newHistory = [...this.history, action].sortBy('timestamp');

    set(this, 'history', newHistory);
  }

  _getRelevantAction() {
    let relevantAction;

    for (let action of this.history) {
      if (action.undone && action.attrs.visible) break;
      relevantAction = action;
    }

    return relevantAction;
  }

  ensureActionInHistory(actionData, attributes) {
    let action = this.history.findBy('id', actionData.id);

    if (!action) {
      this._addToHistory(actionData, attributes);
    }
  }

  setInitAttributes(attributes) {
    this.konvaObject.setAttrs(attributes);
  }

  get isDeleted() {
    return this.konvaObject.visible() === false;
  }
}
