/* import __COLOCATED_TEMPLATE__ from './detailed-view.hbs'; */
import { JOIN_REQUEST_STATUSES } from 'frontend/constants/session';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './detailed-view.module.scss';

@classic
@classNames(styles.component)
export default class NotificationsJoinRequestDetailedView extends Component {
  statuses = JOIN_REQUEST_STATUSES;
}
