import { template } from "@ember/template-compiler";
import { RECORDING_NOTIFICATION_STATES } from 'frontend/services/floating-ui-elements';
import { on } from '@ember/modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './notification.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class TwilioRecordingNotification extends Component {
    @service
    floatingUiElements;
    @reads('floatingUiElements.recordingNotificationState')
    notificationState;
    closeNotification = ()=>{
        this.floatingUiElements.hideRecordingNotification();
    };
    notificationPropertiesSet = {
        [RECORDING_NOTIFICATION_STATES.waiting]: {
            text: 'Waiting for client to allow recording',
            style: 'waiting',
            svg: 'spinner'
        },
        [RECORDING_NOTIFICATION_STATES.allowed]: {
            text: 'Client allowed recording',
            style: 'allowed',
            svg: 'check-circle-green',
            showCloseButton: true
        },
        [RECORDING_NOTIFICATION_STATES.declined]: {
            text: 'Client didn’t allow recording',
            style: 'declined',
            svg: 'alert',
            showCloseButton: true
        },
        [RECORDING_NOTIFICATION_STATES.stopped]: {
            text: 'Clinician stopped recording',
            style: 'stopped',
            showCloseButton: true
        }
    };
    get notificationProperties() {
        return this.notificationPropertiesSet[this.notificationState];
    }
    static{
        template(`
    <div
      class="{{styles.component}} {{this.notificationProperties.style}}"
      data-test-client-consent-notification
    >
      {{#if this.notificationProperties.svg}}
        {{svgJar this.notificationProperties.svg}}
      {{/if}}
      <span class="text">
        {{this.notificationProperties.text}}
      </span>
      {{#if this.notificationProperties.showCloseButton}}
        <button
          type="button"
          {{on "click" this.closeNotification}}
          aria-label="close notification"
          data-test-client-consent-notification-close-button
        >
          {{svgJar "close"}}
        </button>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
