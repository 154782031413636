import { TRACK_HASH_LENGTH } from 'frontend/constants/twilio';

const NAME_MAX_LENGTH = 16;

export function initials(name) {
  if (!name) {
    return '';
  }

  return name
    .split(' ')
    .copyWithin(1, -1)
    .slice(0, 2)
    .map(part => part[0])
    .join('')
    .toUpperCase();
}

export function parseTrackInfo(trackName = '') {
  let [_hash, uuid, type, ...rest] = trackName.split(':').reverse();

  // TODO: Cleanup before the next release
  if (!rest.length || _hash.length !== TRACK_HASH_LENGTH) {
    [uuid, type, ...rest] = trackName.split(':').reverse();
    _hash = null;
  }

  let name = rest.reverse().join(':');

  return {
    name,
    type,
    uuid,
    initials: initials(name),
    trimmedName: trimmedName(name),
  };
}

export function trimmedName(name) {
  let nameMask = new RegExp(`(.{${NAME_MAX_LENGTH}}).+`);
  return name?.replace(nameMask, '$1...');
}
