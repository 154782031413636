/* import __COLOCATED_TEMPLATE__ from './text-size-select.hbs'; */
import { attributeBindings } from '@ember-decorators/component';
import Component from '@ember/component';
import TEXT_SIZES from 'frontend/constants/text-sizes';
import classic from 'ember-classic-decorator';

@classic
@attributeBindings('role')
export default class WhiteboardTextSizeSelect extends Component {
  role = 'radiogroup';

  get textSizeOptions() {
    return Object.keys(TEXT_SIZES).map(label => ({
      label,
      fontSize: TEXT_SIZES[label],
    }));
  }
}
