
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("frontend/app", function(){ return i("frontend/app.js");});
d("frontend/config/environment", function(){ return i("frontend/config/environment.js");});
d("frontend/constants/backgrounds", function(){ return i("frontend/constants/backgrounds.js");});
d("frontend/constants/chime", function(){ return i("frontend/constants/chime.js");});
d("frontend/constants/colors", function(){ return i("frontend/constants/colors.js");});
d("frontend/constants/index", function(){ return i("frontend/constants/index.js");});
d("frontend/constants/mixpanel", function(){ return i("frontend/constants/mixpanel.js");});
d("frontend/constants/session", function(){ return i("frontend/constants/session.js");});
d("frontend/constants/settings", function(){ return i("frontend/constants/settings.js");});
d("frontend/constants/shapes", function(){ return i("frontend/constants/shapes.js");});
d("frontend/constants/stickers", function(){ return i("frontend/constants/stickers.js");});
d("frontend/constants/text-sizes", function(){ return i("frontend/constants/text-sizes.js");});
d("frontend/constants/twilio", function(){ return i("frontend/constants/twilio.js");});
d("frontend/constants/whiteboard", function(){ return i("frontend/constants/whiteboard.js");});
d("frontend/entities/chime/attendee", function(){ return i("frontend/entities/chime/attendee.js");});
d("frontend/entities/chime/event-logger", function(){ return i("frontend/entities/chime/event-logger.js");});
d("frontend/entities/whiteboard/base", function(){ return i("frontend/entities/whiteboard/base.js");});
d("frontend/entities/whiteboard/image", function(){ return i("frontend/entities/whiteboard/image.js");});
d("frontend/entities/whiteboard/index", function(){ return i("frontend/entities/whiteboard/index.js");});
d("frontend/entities/whiteboard/line", function(){ return i("frontend/entities/whiteboard/line.js");});
d("frontend/entities/whiteboard/shape", function(){ return i("frontend/entities/whiteboard/shape.js");});
d("frontend/entities/whiteboard/shapes/arrow", function(){ return i("frontend/entities/whiteboard/shapes/arrow.js");});
d("frontend/entities/whiteboard/shapes/circle", function(){ return i("frontend/entities/whiteboard/shapes/circle.js");});
d("frontend/entities/whiteboard/shapes/grid", function(){ return i("frontend/entities/whiteboard/shapes/grid.js");});
d("frontend/entities/whiteboard/shapes/index", function(){ return i("frontend/entities/whiteboard/shapes/index.js");});
d("frontend/entities/whiteboard/shapes/line", function(){ return i("frontend/entities/whiteboard/shapes/line.js");});
d("frontend/entities/whiteboard/shapes/square", function(){ return i("frontend/entities/whiteboard/shapes/square.js");});
d("frontend/entities/whiteboard/shapes/triangle", function(){ return i("frontend/entities/whiteboard/shapes/triangle.js");});
d("frontend/entities/whiteboard/text", function(){ return i("frontend/entities/whiteboard/text.js");});
d("frontend/entities/whiteboard/vanishing-line", function(){ return i("frontend/entities/whiteboard/vanishing-line.js");});
d("frontend/initializers/deprecations", function(){ return i("frontend/initializers/deprecations.js");});
d("frontend/router", function(){ return i("frontend/router.js");});
d("frontend/services/action-cable", function(){ return i("frontend/services/action-cable.js");});
d("frontend/services/appointment-settings", function(){ return i("frontend/services/appointment-settings.js");});
d("frontend/services/appointment", function(){ return i("frontend/services/appointment.js");});
d("frontend/services/audio-video-adapter", function(){ return i("frontend/services/audio-video-adapter.js");});
d("frontend/services/auto-quality", function(){ return i("frontend/services/auto-quality.js");});
d("frontend/services/call", function(){ return i("frontend/services/call.js");});
d("frontend/services/chat", function(){ return i("frontend/services/chat.js");});
d("frontend/services/chime/connection-health", function(){ return i("frontend/services/chime/connection-health.js");});
d("frontend/services/chime/content-sharing", function(){ return i("frontend/services/chime/content-sharing.js");});
d("frontend/services/chime/data-messaging", function(){ return i("frontend/services/chime/data-messaging.js");});
d("frontend/services/chime/event-manager", function(){ return i("frontend/services/chime/event-manager.js");});
d("frontend/services/chime/local-audio-video", function(){ return i("frontend/services/chime/local-audio-video.js");});
d("frontend/services/chime/meeting-manager", function(){ return i("frontend/services/chime/meeting-manager.js");});
d("frontend/services/chime/remote-videos", function(){ return i("frontend/services/chime/remote-videos.js");});
d("frontend/services/chime/roster", function(){ return i("frontend/services/chime/roster.js");});
d("frontend/services/chime/sdk", function(){ return i("frontend/services/chime/sdk.js");});
d("frontend/services/data-fetcher", function(){ return i("frontend/services/data-fetcher.js");});
d("frontend/services/devices-adapter", function(){ return i("frontend/services/devices-adapter.js");});
d("frontend/services/error-handling", function(){ return i("frontend/services/error-handling.js");});
d("frontend/services/floating-ui-elements", function(){ return i("frontend/services/floating-ui-elements.js");});
d("frontend/services/media-devices", function(){ return i("frontend/services/media-devices.js");});
d("frontend/services/media-settings", function(){ return i("frontend/services/media-settings.js");});
d("frontend/services/messaging", function(){ return i("frontend/services/messaging.js");});
d("frontend/services/mixpanel", function(){ return i("frontend/services/mixpanel.js");});
d("frontend/services/persistent-properties", function(){ return i("frontend/services/persistent-properties.js");});
d("frontend/services/remote-logger", function(){ return i("frontend/services/remote-logger.js");});
d("frontend/services/room-channel", function(){ return i("frontend/services/room-channel.js");});
d("frontend/services/session", function(){ return i("frontend/services/session.js");});
d("frontend/services/sound-notification", function(){ return i("frontend/services/sound-notification.js");});
d("frontend/services/twilio/data-track", function(){ return i("frontend/services/twilio/data-track.js");});
d("frontend/services/twilio/local-tracks", function(){ return i("frontend/services/twilio/local-tracks.js");});
d("frontend/services/twilio/recording", function(){ return i("frontend/services/twilio/recording.js");});
d("frontend/services/twilio/remote-tracks", function(){ return i("frontend/services/twilio/remote-tracks.js");});
d("frontend/services/twilio/room", function(){ return i("frontend/services/twilio/room.js");});
d("frontend/services/twilio/video-stream", function(){ return i("frontend/services/twilio/video-stream.js");});
d("frontend/services/ui-elements", function(){ return i("frontend/services/ui-elements.js");});
d("frontend/services/whiteboard", function(){ return i("frontend/services/whiteboard.js");});
d("frontend/utils/app-link", function(){ return i("frontend/utils/app-link.js");});
d("frontend/utils/computed-persistent-key", function(){ return i("frontend/utils/computed-persistent-key.js");});
d("frontend/utils/computed-session-storage", function(){ return i("frontend/utils/computed-session-storage.js");});
d("frontend/utils/detect-browser", function(){ return i("frontend/utils/detect-browser.js");});
d("frontend/utils/devices", function(){ return i("frontend/utils/devices.js");});
d("frontend/utils/dialogs", function(){ return i("frontend/utils/dialogs.js");});
d("frontend/utils/fetch-data", function(){ return i("frontend/utils/fetch-data.js");});
d("frontend/utils/files", function(){ return i("frontend/utils/files.js");});
d("frontend/utils/format-logs", function(){ return i("frontend/utils/format-logs.js");});
d("frontend/utils/load-image", function(){ return i("frontend/utils/load-image.js");});
d("frontend/utils/mirage/clinician-mock", function(){ return i("frontend/utils/mirage/clinician-mock.js");});
d("frontend/utils/mirage/mock-chime", function(){ return i("frontend/utils/mirage/mock-chime.js");});
d("frontend/utils/name-utils", function(){ return i("frontend/utils/name-utils.js");});
d("frontend/utils/network-connection", function(){ return i("frontend/utils/network-connection.js");});
d("frontend/utils/number-array", function(){ return i("frontend/utils/number-array.js");});
d("frontend/utils/persistent-hash-table", function(){ return i("frontend/utils/persistent-hash-table.js");});
d("frontend/utils/processors/crop-video-chime", function(){ return i("frontend/utils/processors/crop-video-chime.js");});
d("frontend/utils/processors/crop-video", function(){ return i("frontend/utils/processors/crop-video.js");});
d("frontend/utils/processors/is-supported", function(){ return i("frontend/utils/processors/is-supported.js");});
d("frontend/utils/text", function(){ return i("frontend/utils/text.js");});
d("frontend/utils/transform-object", function(){ return i("frontend/utils/transform-object.js");});
d("frontend/utils/twilio", function(){ return i("frontend/utils/twilio.js");});
d("frontend/utils/whiteboard", function(){ return i("frontend/utils/whiteboard.js");});
d("frontend/services/page-title", function(){ return i("frontend/services/page-title.js");});
d("frontend/services/-ea-motion", function(){ return i("frontend/services/-ea-motion.js");});
d("frontend/services/file-queue", function(){ return i("frontend/services/file-queue.js");});
d("frontend/instance-initializers/boolean-input-focus", function(){ return i("frontend/instance-initializers/boolean-input-focus.js");});
d("frontend/instance-initializers/bugsnag", function(){ return i("frontend/instance-initializers/bugsnag.js");});
d("frontend/services/alert-manager", function(){ return i("frontend/services/alert-manager.js");});
d("frontend/services/body-scroll-manager", function(){ return i("frontend/services/body-scroll-manager.js");});
d("frontend/services/browser-update-banner", function(){ return i("frontend/services/browser-update-banner.js");});
d("frontend/services/csrf-token", function(){ return i("frontend/services/csrf-token.js");});
d("frontend/services/file-upload", function(){ return i("frontend/services/file-upload.js");});
d("frontend/services/pop-sequencer", function(){ return i("frontend/services/pop-sequencer.js");});
d("frontend/services/pop-sequencer/-state-machine", function(){ return i("frontend/services/pop-sequencer/-state-machine.js");});
d("frontend/services/pop-sequencer/-step-node", function(){ return i("frontend/services/pop-sequencer/-step-node.js");});
d("frontend/services/stripe", function(){ return i("frontend/services/stripe.js");});
d("frontend/transforms/json", function(){ return i("frontend/transforms/json.js");});
d("frontend/transforms/moment", function(){ return i("frontend/transforms/moment.js");});
d("frontend/utils/async-script-loader", function(){ return i("frontend/utils/async-script-loader.js");});
d("frontend/utils/banners", function(){ return i("frontend/utils/banners.js");});
d("frontend/utils/compact-object", function(){ return i("frontend/utils/compact-object.js");});
d("frontend/utils/component-validations", function(){ return i("frontend/utils/component-validations.js");});
d("frontend/utils/error-handling", function(){ return i("frontend/utils/error-handling.js");});
d("frontend/utils/filter-by-term", function(){ return i("frontend/utils/filter-by-term.js");});
d("frontend/utils/generate-uuid", function(){ return i("frontend/utils/generate-uuid.js");});
d("frontend/utils/google-maps", function(){ return i("frontend/utils/google-maps.js");});
d("frontend/utils/is-testing", function(){ return i("frontend/utils/is-testing.js");});
d("frontend/utils/macros/inactive-validation-field", function(){ return i("frontend/utils/macros/inactive-validation-field.js");});
d("frontend/utils/modals", function(){ return i("frontend/utils/modals.js");});
d("frontend/utils/pdf", function(){ return i("frontend/utils/pdf.js");});
d("frontend/utils/setup-banners", function(){ return i("frontend/utils/setup-banners.js");});
d("frontend/utils/setup-modals", function(){ return i("frontend/utils/setup-modals.js");});
d("frontend/utils/setup-pdf", function(){ return i("frontend/utils/setup-pdf.js");});
d("frontend/utils/signatures", function(){ return i("frontend/utils/signatures.js");});
d("frontend/utils/state-groups", function(){ return i("frontend/utils/state-groups.js");});
d("frontend/utils/toasts", function(){ return i("frontend/utils/toasts.js");});
d("frontend/utils/transitions", function(){ return i("frontend/utils/transitions.js");});
d("frontend/utils/waiters", function(){ return i("frontend/utils/waiters.js");});
d("frontend/validators/stripe-card-expiry", function(){ return i("frontend/validators/stripe-card-expiry.js");});
d("frontend/component-managers/glimmer", function(){ return i("frontend/component-managers/glimmer.js");});
d("frontend/initializers/app-version", function(){ return i("frontend/initializers/app-version.js");});
d("frontend/initializers/ember-cli-rails-addon-csrf", function(){ return i("frontend/initializers/ember-cli-rails-addon-csrf.js");});
d("frontend/instance-initializers/global-ref-cleanup", function(){ return i("frontend/instance-initializers/global-ref-cleanup.js");});
d("frontend/container-debug-adapter", function(){ return i("frontend/container-debug-adapter.js");});
d("frontend/services/-ensure-registered", function(){ return i("frontend/services/-ensure-registered.js");});
d("frontend/utils/calculate-position", function(){ return i("frontend/utils/calculate-position.js");});
d("frontend/initializers/paste-event", function(){ return i("frontend/initializers/paste-event.js");});
d("frontend/utils/titleize", function(){ return i("frontend/utils/titleize.js");});
d("frontend/services/panel-actions", function(){ return i("frontend/services/panel-actions.js");});
d("frontend/validators/alias", function(){ return i("frontend/validators/alias.js");});
d("frontend/validators/belongs-to", function(){ return i("frontend/validators/belongs-to.js");});
d("frontend/validators/collection", function(){ return i("frontend/validators/collection.js");});
d("frontend/validators/confirmation", function(){ return i("frontend/validators/confirmation.js");});
d("frontend/validators/date", function(){ return i("frontend/validators/date.js");});
d("frontend/validators/dependent", function(){ return i("frontend/validators/dependent.js");});
d("frontend/validators/ds-error", function(){ return i("frontend/validators/ds-error.js");});
d("frontend/validators/exclusion", function(){ return i("frontend/validators/exclusion.js");});
d("frontend/validators/format", function(){ return i("frontend/validators/format.js");});
d("frontend/validators/has-many", function(){ return i("frontend/validators/has-many.js");});
d("frontend/validators/inclusion", function(){ return i("frontend/validators/inclusion.js");});
d("frontend/validators/inline", function(){ return i("frontend/validators/inline.js");});
d("frontend/validators/length", function(){ return i("frontend/validators/length.js");});
d("frontend/validators/messages", function(){ return i("frontend/validators/messages.js");});
d("frontend/validators/number", function(){ return i("frontend/validators/number.js");});
d("frontend/validators/presence", function(){ return i("frontend/validators/presence.js");});
d("frontend/initializers/viewport-config", function(){ return i("frontend/initializers/viewport-config.js");});
d("frontend/services/in-viewport", function(){ return i("frontend/services/in-viewport.js");});
d("frontend/services/infinity", function(){ return i("frontend/services/infinity.js");});
d("frontend/initializers/liquid-fire", function(){ return i("frontend/initializers/liquid-fire.js");});
d("frontend/services/liquid-fire-transitions", function(){ return i("frontend/services/liquid-fire-transitions.js");});
d("frontend/transitions/cross-fade", function(){ return i("frontend/transitions/cross-fade.js");});
d("frontend/transitions/default", function(){ return i("frontend/transitions/default.js");});
d("frontend/transitions/explode", function(){ return i("frontend/transitions/explode.js");});
d("frontend/transitions/fade", function(){ return i("frontend/transitions/fade.js");});
d("frontend/transitions/flex-grow", function(){ return i("frontend/transitions/flex-grow.js");});
d("frontend/transitions/fly-to", function(){ return i("frontend/transitions/fly-to.js");});
d("frontend/transitions/move-over", function(){ return i("frontend/transitions/move-over.js");});
d("frontend/transitions/scale", function(){ return i("frontend/transitions/scale.js");});
d("frontend/transitions/scroll-then", function(){ return i("frontend/transitions/scroll-then.js");});
d("frontend/transitions/to-down", function(){ return i("frontend/transitions/to-down.js");});
d("frontend/transitions/to-left", function(){ return i("frontend/transitions/to-left.js");});
d("frontend/transitions/to-right", function(){ return i("frontend/transitions/to-right.js");});
d("frontend/transitions/to-up", function(){ return i("frontend/transitions/to-up.js");});
d("frontend/transitions/wait", function(){ return i("frontend/transitions/wait.js");});
d("frontend/templates/appointment", function(){ return i("frontend/templates/appointment.hbs");});
d("frontend/controllers/appointment", function(){ return i("frontend/controllers/appointment.js");});
d("frontend/routes/appointment", function(){ return i("frontend/routes/appointment.js");});
d("frontend/templates/appointment/room", function(){ return i("frontend/templates/appointment/room.hbs");});
d("frontend/controllers/appointment/room", function(){ return i("frontend/controllers/appointment/room.js");});
d("frontend/routes/appointment/room", function(){ return i("frontend/routes/appointment/room.js");});
d("frontend/templates/appointment/rating", function(){ return i("frontend/templates/appointment/rating.hbs");});
d("frontend/routes/appointment/rating", function(){ return i("frontend/routes/appointment/rating.js");});
d("frontend/templates/appointment/ended", function(){ return i("frontend/templates/appointment/ended.hbs");});
d("frontend/templates/appointment/index", function(){ return i("frontend/templates/appointment/index.hbs");});
d("frontend/templates/download-app", function(){ return i("frontend/templates/download-app.hbs");});
d("frontend/controllers/download-app", function(){ return i("frontend/controllers/download-app.js");});
d("frontend/routes/index", function(){ return i("frontend/routes/index.js");});
d("frontend/templates/application", function(){ return i("frontend/templates/application.hbs");});
d("frontend/templates/error/invalid-appointment", function(){ return i("frontend/templates/error/invalid-appointment.hbs");});
d("frontend/templates/error/session-creation-failed", function(){ return i("frontend/templates/error/session-creation-failed.hbs");});
d("frontend/templates/error/video-disabled", function(){ return i("frontend/templates/error/video-disabled.hbs");});
d("frontend/templates/unsupported-browser", function(){ return i("frontend/templates/unsupported-browser.hbs");});





if (!runningTests) {
  i("../app").default.create({"rootElement":"[data-ember-root]","name":"frontend","version":"0.0.0"});
}

