import { helper } from '@ember/component/helper';
import ENV from 'frontend/config/environment';

const SUPPORT_URL = 'https://support.simplepractice.com/hc/en-us';

export function routeFor([name, path]) {
  /* eslint-disable camelcase */
  let staticRoutes = {
    support_article_url: `${SUPPORT_URL}/articles/${path}`,
    support_article_attachment: `${SUPPORT_URL}/article_attachments/${path}`,
    support_url: SUPPORT_URL,
  };

  if (staticRoutes[name]) {
    if (name === 'support_article_url' || name === 'support_article_attachment') {
      if (
        ['development', 'test'].some(env => ENV.railsEnv === env || ENV.environment === 'test') &&
        typeof path !== 'string'
      ) {
        throw new Error(`route-for expected a string, but received a ${typeof path}`);
      }
    }
    return staticRoutes[name];
  } else {
    throw new Error(`route-for could not find static route ${name}`);
  }
}

export default helper(routeFor);
