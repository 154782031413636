import { action, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { isTablet } from 'frontend/utils/detect-browser';
import { redirectToApp } from 'frontend/utils/app-link';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class DownloadAppController extends Controller {
  @service() router;
  @service() persistentProperties;

  tabletOpenCounterLimit = 3;
  queryParams = ['roomId', 'origin'];
  isTablet = isTablet();
  isModalShown = false;
  appModalTitle = 'Always allow Telehealth to open video links in the app?';
  browserModalTitle = 'Always allow Telehealth to open video links in the browser?';

  @alias('persistentProperties.tabletOpenType') tabletOpenType;
  @alias('persistentProperties.tabletOpenCounter') tabletOpenCounter;
  @alias('persistentProperties.tabletSelectedOpenType') tabletSelectedOpenType;

  @action
  async openApp() {
    let params = this.getProperties(this.queryParams);
    if (isTablet) {
      if (this.tabletOpenType === 'app') {
        this.incrementCounter();

        if (this.tabletOpenCounter >= this.tabletOpenCounterLimit) {
          set(this, 'isModalShown', true);
        } else {
          await redirectToApp(params);
        }
      } else {
        set(this, 'tabletOpenType', 'app');
        set(this, 'tabletOpenCounter', 1);
        await redirectToApp(params);
      }
    } else {
      await redirectToApp(params);
    }
  }

  @action
  openInBrowser() {
    if (isTablet) {
      if (this.tabletOpenType === 'browser') {
        this.incrementCounter();

        if (this.tabletOpenCounter >= this.tabletOpenCounterLimit) {
          set(this, 'isModalShown', true);
        } else {
          this._openInBrowser();
        }
      } else {
        set(this, 'tabletOpenType', 'browser');
        set(this, 'tabletOpenCounter', 1);
        this._openInBrowser();
      }
    } else {
      this._openInBrowser();
    }
  }

  @action
  async submitModal() {
    set(this, 'tabletSelectedOpenType', this.tabletOpenType);
    set(this, 'isModalShown', false);
    await this.openSelectedType();
  }

  @action
  async cancelModal() {
    set(this, 'isModalShown', false);
    await this.openSelectedType();
  }

  async openSelectedType() {
    if (this.tabletOpenType === 'app') {
      await redirectToApp(this.getProperties(this.queryParams));
    } else if (this.tabletOpenType === 'browser') {
      this._openInBrowser();
    }
  }

  _openInBrowser() {
    let { roomId } = this;
    this.router.transitionTo('appointment', roomId);
  }

  incrementCounter() {
    set(this, 'tabletOpenCounter', this.tabletOpenCounter + 1);
  }
}
