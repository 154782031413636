export const STICKERS = {
  star: 'star',
  heart: 'heart',
  rainbow: 'rainbow',
  thumbsUp: 'thumbs up',
  happy: 'happy',
  sad: 'sad',
  check: 'check mark',
  cross: 'x',
  questionMark: 'question mark',
  logoRainbow: 'logo rainbow',
  logoBlue: 'logo butterfly',
};

export const STICKERS_CONFIG = {
  [STICKERS.star]: { iconName: STICKERS.star },
  [STICKERS.heart]: { secretSticker: STICKERS.logoBlue, iconName: STICKERS.heart },
  [STICKERS.rainbow]: { secretSticker: STICKERS.logoRainbow, iconName: STICKERS.rainbow },
  [STICKERS.thumbsUp]: { iconName: 'thumbs-up' },
  [STICKERS.happy]: { iconName: STICKERS.happy },
  [STICKERS.sad]: { iconName: STICKERS.sad },
  [STICKERS.check]: { iconName: 'check' },
  [STICKERS.cross]: { iconName: 'cross' },
  [STICKERS.questionMark]: { iconName: 'question' },
  [STICKERS.logoRainbow]: { iconName: 'logo-rainbow', secret: true },
  [STICKERS.logoBlue]: { iconName: 'logo-blue', secret: true },
};
