import { isMobileOrTablet } from 'frontend/utils/detect-browser';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class IndexRoute extends Route {
  @service router;

  beforeModel() {
    super.beforeModel(...arguments);

    if (isMobileOrTablet()) {
      return this.router.transitionTo('download-app');
    }
  }
}
