import Line from './line';

export default class Arrow extends Line {
  wingspanWidth = 12;
  wingspanHeight = 22;

  _sceneFunc(ctx) {
    let height = this.height();

    ctx.save();
    ctx.translate(0, height / 2);
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(this.width(), 0);
    ctx.translate(this.width(), 0);
    ctx.moveTo(-this.wingspanWidth, this.wingspanHeight / 2);
    ctx.lineTo(0, 0);
    ctx.lineTo(-this.wingspanWidth, -this.wingspanHeight / 2);
    ctx.restore();
    ctx.strokeShape(this);
  }
}
