import { DEFAULT_LINE_OPTIONS, WHITEBOARD_ENTITIES } from 'frontend/constants/whiteboard';
import { drawLine } from 'frontend/utils/whiteboard';
import BaseEntity from 'frontend/entities/whiteboard/base';

let MIN_HIT_STROKE_WIDTH = 10;

export default class WhiteboardLine extends BaseEntity {
  type = WHITEBOARD_ENTITIES.line;

  setInitAttributes(attributes) {
    let { strokeWidth } = attributes;

    this.konvaObject.setAttrs({
      ...DEFAULT_LINE_OPTIONS,
      sceneFunc: this._sceneFunc,
      hitStrokeWidth: Math.max(strokeWidth, MIN_HIT_STROKE_WIDTH),
      ...attributes,
    });
  }

  get points() {
    return this.konvaObject.points();
  }

  update(attrs) {
    let { width, height } = attrs;

    if (width || height) {
      this.resize({ width, height });
    }
    super.update(...arguments);
  }

  resize({ width, height }) {
    let { width: currentWidth, height: currentHeight } = this.konvaObject.size();
    let deltaWidth = width / currentWidth;
    let deltaHeight = height / currentHeight;
    let points = this.points;
    let newPoints = [];

    for (let i = 0; i < points.length; i += 2) {
      newPoints.push(points[i] * deltaWidth, points[i + 1] * deltaHeight);
    }
    this.konvaObject.points(newPoints);
  }

  _sceneFunc(context, _shape) {
    let points = this.points();
    context.beginPath();
    context.moveTo(points[0], points[1]);

    // According to the spec, a browser should prune all zero-length line segments from the path.
    // https://html.spec.whatwg.org/multipage/canvas.html#trace-a-path
    if (points.length === 2) {
      // We cannot just call `context.fill()` as this way there will no Konva "hit" on click (as the Line has no fill)
      context.arc(points[0], points[1], 1, 0, Math.PI * 2);
    } else {
      drawLine(context, points);
    }

    context.strokeShape(this);
  }
}
