import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import pianoSoundSrc from '/assets/audio/piano.mp3';
import styles from './test-sound.module.scss';
export default class TestSound extends Component {
    @tracked
    isSoundPlaying = false;
    @service
    errorHandling;
    constructor(){
        super(...arguments);
        this.testAudio = new Audio(pianoSoundSrc);
        this.testAudio.addEventListener('ended', ()=>{
            this.isSoundPlaying = false;
        });
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.testAudio.pause();
        this.testAudio = null;
    }
    @action
    onClick() {
        this.isSoundPlaying ? this.testAudio.pause() : this.testAudio.play();
        this.isSoundPlaying = !this.isSoundPlaying;
    }
    initTestAudio = modifier(()=>{
        if (!this.args.sinkId) return;
        // eslint-disable-next-line promise/prefer-await-to-then
        this.testAudio.setSinkId?.(this.args.sinkId).catch((e1)=>this.errorHandling.notifyError(e1));
    });
    static{
        template(`
    <button
      class={{styles.component}}
      type="button"
      {{on "click" this.onClick}}
      {{this.initTestAudio}}
      ...attributes
    >
      {{if this.isSoundPlaying "Playing..." "Play test sound"}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
