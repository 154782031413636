/* import __COLOCATED_TEMPLATE__ from './grid-view.hbs'; */
import { action, computed, set, setProperties } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { htmlSafe } from '@ember/template';
import { observes } from '@ember-decorators/object';
import { reads } from '@ember/object/computed';
import { scheduleOnce, throttle } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from 'frontend/components/call/grid-view.module.scss';

@classic
@classNames(styles.component)
export default class TwilioGridView extends Component {
  @service('twilio/video-stream') videoStream;

  maxParticipantsCount = 16;
  widthProportion = 16;
  heightProportion = 9;
  mainViewWidth = 0;
  mainViewHeight = 0;
  page = 1;

  @reads('videoStream.participants') participants;
  @reads('videoStream.hideSelfView') hideSelfView;
  @reads('videoStream.shareParticipant') shareParticipant;
  @reads('videoStream.isSharingScreen') isSharingScreen;

  didInsertElement() {
    super.didInsertElement(...arguments);
    window.addEventListener('resize', this.resizeUsersContainer);
    this.resizeUsersContainer();
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    window.removeEventListener('resize', this.resizeUsersContainer);
  }

  @action
  resizeUsersContainer() {
    throttle(this, this._resizeUsersContainer, 100);
  }

  // eslint-disable-next-line ember/no-observers
  @observes('participantsCountStyle')
  triggerResize() {
    scheduleOnce('afterRender', this.resizeUsersContainer);
  }

  @computed('participants.[]', 'hideSelfView', 'shareParticipant')
  get visibleUsersCount() {
    return this.participants.length + (this.hideSelfView ? 0 : 1) + (this.shareParticipant ? 1 : 0);
  }

  @computed('visibleUsersCount')
  get participantsCountStyle() {
    if (this.visibleUsersCount === 1) {
      return 'single';
    } else if (this.visibleUsersCount === 2) {
      return 'two';
    } else if (this.visibleUsersCount < 5) {
      return 'up-to-4';
    } else if (this.visibleUsersCount < 10) {
      return 'up-to-9';
    }
    return 'up-to-16';
  }

  @computed('visibleUsersCount')
  get hasPagination() {
    return this.visibleUsersCount > this.maxParticipantsCount;
  }

  @computed('page', 'hasPagination')
  get paginationPageClass() {
    return this.hasPagination ? `page-${this.page}` : '';
  }

  @computed('page')
  get paginationButtonClass() {
    return this.page === 1 ? 'right' : 'left';
  }

  @computed('mainViewWidth', 'mainViewHeight')
  get style() {
    return htmlSafe(`width:${this.mainViewWidth}px;height:${this.mainViewHeight}px;`);
  }

  _resizeUsersContainer() {
    if (!this.element) {
      return;
    }
    let { widthProportion, heightProportion } = this;
    let specificWidthProportion =
      this.visibleUsersCount === 2 ? widthProportion * 2 : widthProportion;
    let { height, width } = this.element.getBoundingClientRect();

    if (height / width < heightProportion / specificWidthProportion) {
      setProperties(this, {
        mainViewWidth: Math.round((height / heightProportion) * specificWidthProportion),
        mainViewHeight: height,
      });
    } else {
      setProperties(this, {
        mainViewWidth: width,
        mainViewHeight: Math.round((width / specificWidthProportion) * heightProportion),
      });
    }
  }

  @action
  toggleScreenSharing() {
    this.videoStream.toggleScreenSharing();
  }

  @action
  changePagination(buttonType) {
    if (buttonType === 'right') {
      set(this, 'page', this.page + 1);
    } else {
      set(this, 'page', this.page - 1);
    }
  }
}
