export const COLORS = {
  smoke400: '#BBBBBB',
  smoke500: '#707070',
  smoke700: '#333333',
  ocean500: '#1371C8',
  wine500: '#EA3917',
  mint500: '#14BF71',
  lemon500: '#FFC117',
  white: '#FFFFFF',
  plum500: '#756DEC',
};
