import { template } from "@ember/template-compiler";
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import styles from './star.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default template(`
  <div
    role="button"
    title={{@star.name}}
    class={{concat
      styles.component
      (if @isActivated " is-activated")
      (if @hasNameDisplayed " has-name-displayed")
    }}
    {{on "mouseover" (fn @setSelected @idx)}}
    {{on "mouseout" (fn @setSelected undefined)}}
    {{on "click" (fn @setChosen @idx)}}
    ...attributes
  >
    {{svgJar "star"}}
    <span>{{@star.name}}</span>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
