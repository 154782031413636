/* import __COLOCATED_TEMPLATE__ from './preview-video.hbs'; */
import { action } from '@ember/object';
import { and, reads } from 'macro-decorators';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './preview-video.module.scss';

export default class WelcomeScreenPreviewVideo extends Component {
  @service persistentProperties;
  @service audioVideoAdapter;

  @tracked viewWidth = 0;
  @tracked viewHeight = 0;

  styles = styles;
  labelHeight = 30;
  userFormWidth = 492;
  margins = 32;
  widthProportion = 16;
  heightProportion = 9;

  @reads('persistentProperties.isSelfViewMirrored') mirrorVideo;
  @reads('audioVideoAdapter.isVideoPublished') isVideoPublished;
  @reads('audioVideoAdapter.hasLocalCameraSource') hasLocalCameraSource;
  @reads('audioVideoAdapter.videoInputSource') videoInputSource;
  @and('hasLocalCameraSource', 'isVideoPublished') isVideoShown;

  get style() {
    return htmlSafe(`width:${this.viewWidth}px;height:${this.viewHeight}px;`);
  }

  initContainer = modifier(() => {
    window.addEventListener('resize', this.resizePreviewContainer);
    this.#resize();

    return () => {
      window.removeEventListener('resize', this.resizePreviewContainer);
    };
  });

  initLocalVideo = modifier(videoElement => {
    this.audioVideoAdapter.attachLocalVideo({ videoElement, mediaSource: this.videoInputSource });

    return () => this.audioVideoAdapter.detachLocalVideo(videoElement);
  });

  @action
  resizePreviewContainer() {
    this.resizePreviewContainerTask.perform();
  }

  resizePreviewContainerTask = restartableTask(async () => {
    await timeout(100);
    this.#resize();
  });

  #normalizeDimension(size, proportion) {
    return size - (size % proportion);
  }

  #resize() {
    let { widthProportion, heightProportion } = this;
    let { height: baseHeight, width: baseWidth } =
      this.args.parentContainer.getBoundingClientRect();

    let height = this.#normalizeDimension(baseHeight - this.labelHeight * 2, heightProportion);
    let width = this.#normalizeDimension(
      baseWidth - this.userFormWidth - this.margins,
      widthProportion
    );

    if (height / width < heightProportion / widthProportion) {
      this.viewWidth = (height / heightProportion) * widthProportion;
      this.viewHeight = height;
    } else {
      this.viewWidth = width;
      this.viewHeight = (width / widthProportion) * heightProportion;
    }
  }
}
