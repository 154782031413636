/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { empty } from '@ember/object/computed';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './avatar.module.scss';

// taken from simplepractice/frontend/app/rails/user-avatar.js
let DS_COLOR_CLASSES = [
  'gradient-amber-300',
  'gradient-wine-400',
  'gradient-ocean-200',
  'gradient-sky-300',
  'gradient-lemon-500',
  'gradient-steel-300',
  'gradient-mint-200',
  'gradient-wine-200',
  'gradient-salmon-500',
];

@classic
@classNameBindings('placeholder', 'colorClass')
@classNames('avatar', styles.component)
@attributeBindings('data-test-profile-avatar')
export default class ProfileAvatar extends Component {
  styles = styles;

  @empty('profileImageUrl') placeholder;

  'data-test-profile-avatar' = isEmberTesting();

  @computed('shownName')
  get firstNameInitial() {
    return this.shownName?.charAt(0).toUpperCase();
  }

  @computed('userId')
  get colorClass() {
    if (!this.userId) return '';

    let index = parseInt(this.userId, 10) % DS_COLOR_CLASSES.length;
    return DS_COLOR_CLASSES[index];
  }
}
