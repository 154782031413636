import {
  BACKGROUND_ID,
  BASE_HEIGHT,
  BASE_WIDTH,
  DEFAULT_OPACITY,
  PREVIEW_OPACITY,
  STICKERS,
  STICKERS_CONFIG,
  TRANSFORMER_ID,
  WHITEBOARD_COLORS,
  WHITEBOARD_SHAPES,
  WHITEBOARD_TOOLS,
} from 'frontend/constants/whiteboard';
import { COLORS } from 'frontend/constants/colors';

export function getPointerPosition(stage) {
  let pointer = stage.getPointerPosition();

  return {
    x: pointer.x / stage.scaleX(),
    y: pointer.y / stage.scaleY(),
  };
}

export function rotatePoint({ x, y }, rad) {
  let rcos = Math.cos(rad);
  let rsin = Math.sin(rad);
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
}

export function angleToRad(angle) {
  return (Math.PI / 180) * angle;
}

export function isOutOfStage(stage, { x, y, width, height, rotation }) {
  let box = getClientRect({ x, y, width, height, rotation });
  return (
    box.x < 0 ||
    box.y < 0 ||
    box.x + box.width > stage.width() / stage.scaleX() ||
    box.y + box.height > stage.height() / stage.scaleY()
  );
}

function getClientRect({ x: pivotX, y: pivotY, width, height, rotation }) {
  let angle = angleToRad(rotation);
  let p1 = getCorner({ pivotX, pivotY, angle });
  let p2 = getCorner({ pivotX, pivotY, diffX: width, angle });
  let p3 = getCorner({ pivotX, pivotY, diffX: width, diffY: height, angle });
  let p4 = getCorner({ pivotX, pivotY, diffY: height, angle });
  let minX = Math.min(p1.x, p2.x, p3.x, p4.x);
  let minY = Math.min(p1.y, p2.y, p3.y, p4.y);
  let maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
  let maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

function getCorner({ pivotX, pivotY, diffX = 0, diffY = 0, angle }) {
  let distance = Math.sqrt(diffX * diffX + diffY * diffY);
  angle += Math.atan2(diffY, diffX);
  let x = pivotX + distance * Math.cos(angle);
  let y = pivotY + distance * Math.sin(angle);
  return { x, y };
}

export const getCursorStyle = tool => {
  switch (tool) {
    case WHITEBOARD_TOOLS.draw:
    case WHITEBOARD_TOOLS.vanishingPen:
      return 'none';
    case WHITEBOARD_TOOLS.text:
      return 'text';
    default:
      return 'auto';
  }
};

export const drawLine = (context, points) => {
  let length = points.length;

  for (let i = 1; i < Math.floor((length - 2) / 6); i++) {
    let shiftLength = i * 6 + 2;
    context.bezierCurveTo(...points.slice(shiftLength, shiftLength + 6));
  }

  let tailLength = (length - 2) % 6;
  let tailStart = length - tailLength;
  if (tailLength < 2) {
    context.lineTo(points[tailStart - 2], points[tailStart - 1]);
  } else if (tailLength < 4) {
    context.lineTo(points[tailStart], points[tailStart + 1]);
  } else {
    context.quadraticCurveTo(...points.slice(tailStart, tailStart + 4));
  }
};

const getArrangedCoordinate = (point, size, maxPoint, minPoint = 0) =>
  point < minPoint ? minPoint : Math.min(point, maxPoint - size);

export const fitInStage = ({ x, y, width, height, strokeWidth }) => {
  let offset = strokeWidth ? strokeWidth / 2 : 0;

  return {
    x: getArrangedCoordinate(x, width + offset, BASE_WIDTH, offset),
    y: getArrangedCoordinate(y, height + offset, BASE_HEIGHT, offset),
  };
};

export const getColorPaletteOptions = () =>
  Object.entries(WHITEBOARD_COLORS).map(([title, value]) => ({
    title,
    value,
    style: `background-color: ${value};${
      value === COLORS.white ? `box-shadow: inset 0 0 0 1px ${COLORS.smoke400}` : ''
    }`,
  }));
export const getShapesPaletteOptions = () =>
  Object.keys(WHITEBOARD_SHAPES).map(title => ({
    title,
    value: title,
    iconName: `shape-${title}`,
    testAttribute: `shape="${title}"`,
  }));

export const getStickersPaletteOptions = () =>
  Object.values(STICKERS).map(title => ({
    title,
    value: title,
    iconName: `sticker-${STICKERS_CONFIG[title].iconName}`,
    className: STICKERS_CONFIG[title].secret ? 'hidden' : null,
  }));

export const getOpacityOption = previewMode => (previewMode ? PREVIEW_OPACITY : DEFAULT_OPACITY);

export const getStickerSVGContent = sticker =>
  document.querySelector(`input[value="${sticker}"]`).nextElementSibling.outerHTML;

export const getWhiteboardJSON = layer => {
  let layerClone = layer.clone();

  layerClone.children.forEach(child => {
    let { attrs } = child;

    if (attrs.previewMode || [TRANSFORMER_ID, BACKGROUND_ID].includes(attrs.id)) {
      child.destroy();
    }
  });

  return layerClone.toJSON();
};

export const isDoubleClick = ({ detail }) => detail === 2;

export const isText = whiteboardObject => whiteboardObject?.type === WHITEBOARD_TOOLS.text;
