import { template } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import styles from './reconnecting-banner.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default template(`
  <div
    class={{concat styles.component (if @small " small")}}
    ...attributes
    data-test-reconnecting-banner
  >
    <div class="svg-wrapper">
      {{svgJar "network-connection-0"}}
    </div>
    <div>Trying to reconnect...</div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
