import { WHITEBOARD_TOOLS } from 'frontend/constants/whiteboard';
import BaseEntity from 'frontend/entities/whiteboard/base';

export default class WhiteboardText extends BaseEntity {
  type = WHITEBOARD_TOOLS.text;

  setInitAttributes(attributes) {
    this.konvaObject.setAttrs({ lineHeight: 1.4, ...attributes });
  }
}
