import { DATA_TRACK_CONSTRAINTS, DATA_TRACK_MESSAGE_TYPES } from 'frontend/constants';
import { LocalDataTrack } from 'twilio-video';
import { PARTICIPANT_EVENTS, PING_TIMEOUT_MS } from 'frontend/constants/twilio';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { set } from '@ember/object';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import MessagingService from 'frontend/services/messaging';
import classic from 'ember-classic-decorator';

@classic
/* eslint-disable-next-line @simplepractice/simplepractice/require-native-class-id */
export default class TwilioDataTrack extends MessagingService {
  @service persistentProperties;

  track;
  trackPublished = {};
  localParticipant;
  pingTasks = new Map();
  maxBytesAllowed = 16384;

  @reads('persistentProperties.userName') userName;
  @reads('persistentProperties.uuid') userIdentity;

  initDataTrackPublished(localParticipant) {
    this.trackPublished.promise = new Promise((resolve, reject) => {
      this.trackPublished.resolve = resolve;
      this.trackPublished.reject = reject;
    });
    set(this, 'localParticipant', localParticipant);
  }

  createDataTrack(trackName) {
    let track = new LocalDataTrack({ ...DATA_TRACK_CONSTRAINTS, name: trackName });
    set(this, 'track', track);
    return track;
  }

  async send(data) {
    await waitForProperty(this.trackPublished, 'promise', Boolean);
    await this.trackPublished.promise;

    this.track.send(JSON.stringify(data));
  }

  handleRemoteReceivingReady(participant, callback) {
    participant.on(PARTICIPANT_EVENTS.trackPublished, publication => {
      if (publication.kind === 'data') {
        let id = participant.identity;
        let pingTaskInstance = this.pingParticipantTask.perform(id);

        this.pingTasks.set(id, { instance: pingTaskInstance, callback });
      }
    });
  }

  pingParticipantTask = task(async participantIdentity => {
    let retryCount = 0;
    while (retryCount !== 5) {
      this.sendMessage(
        {
          participantIdentity,
          handlerPayload: {
            message: { pingUuid: participantIdentity },
            type: DATA_TRACK_MESSAGE_TYPES.responseToPing,
          },
        },
        DATA_TRACK_MESSAGE_TYPES.pingParticipant
      );
      retryCount++;
      await timeout(PING_TIMEOUT_MS);
    }
    this.pingTasks.delete(participantIdentity);
  });

  _handlePing(messageObject) {
    let { handlerPayload, participantIdentity } = messageObject.message;
    let { message, type, options } = handlerPayload;

    if (participantIdentity !== this.localParticipant.identity) return;
    this.sendMessage(message, type, options);
  }

  #handleResponseToPing(messageObject) {
    let { pingUuid } = messageObject.message;

    this.pingTasks.get(pingUuid)?.callback();
    this.pingTasks.get(pingUuid)?.instance.cancel();
    this.pingTasks.delete(pingUuid);
  }

  receiveMessage(messageObject) {
    let { type } = messageObject;

    switch (type) {
      case DATA_TRACK_MESSAGE_TYPES.pingParticipant:
        return this._handlePing(messageObject);
      case DATA_TRACK_MESSAGE_TYPES.responseToPing:
        return this.#handleResponseToPing(messageObject);
      default:
        super.receiveMessage(messageObject);
    }
  }
}
