import { action } from '@ember/object';
import { enqueueTask, timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import MessagingService from 'frontend/services/messaging';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';

export const DATA_MESSAGE_TOPIC = 'data-message';
const SENDING_INTERVAL_MS = 10;

@classic
export default class ChimeDataMessaging extends MessagingService {
  @service('chime.meeting-manager') meetingManager;

  @reads('meetingManager.localAttendeeId') localAttendeeId;

  maxBytesAllowed = 1800;

  setup() {
    this.meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage(
      DATA_MESSAGE_TOPIC,
      this.dataMessageHandler
    );
  }

  resetDataMessaging() {
    this.sendDataMessageTask.cancelAll();
  }

  createMessage(message, type, options = {}) {
    return {
      uuid: generateUUID().substring(0, 8),
      type,
      message,
      ...options,
    };
  }

  @action
  dataMessageHandler(dataMessage) {
    let { senderExternalUserId, timestampMs, senderAttendeeId } = dataMessage;
    let messageObject = {
      participantSid: senderExternalUserId,
      senderAttendeeId,
      timestamp: timestampMs,
      ...JSON.parse(dataMessage.text()),
    };

    this.receiveMessage(messageObject);
  }

  send(data) {
    this.sendDataMessageTask.perform(data);
  }

  sendMessage(message = {}, type = '', options = {}) {
    let messageObject = super.sendMessage(message, type, options);

    messageObject.participantSid = this.participantSid;

    return messageObject;
  }

  sendDataMessageTask = enqueueTask(async data => {
    this.meetingManager.audioVideo?.realtimeSendDataMessage(DATA_MESSAGE_TOPIC, data);
    await timeout(SENDING_INTERVAL_MS);
  });
}
