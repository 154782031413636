/* import __COLOCATED_TEMPLATE__ from './permissions-modal.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { modifier } from 'ember-modifier';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './permissions-modal.module.scss';

@classic
@classNames('permissions-container', styles.component)
@classNameBindings('isFirefox:firefox', 'isEdge:edge')
export default class PermissionsModal extends Component {
  delayDuration = 1000;

  get isFirefox() {
    return /Firefox|FxiOS/.test(window.navigator.userAgent);
  }

  get isEdge() {
    return /(Edge\/[\d.]+|Trident\/8)/.test(window.navigator.userAgent);
  }

  onSetup = modifier(async element => {
    await waitForLater(this.delayDuration);
    element.parentNode.style.opacity = '1';
  });
}
