import { computed, set, setProperties } from '@ember/object';
import { equal, not, sum } from '@ember/object/computed';
import { isSafari } from 'frontend/utils/detect-browser';
import { task, timeout } from 'ember-concurrency';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';

export const QUALITY_TYPES = {
  HD: 'HD',
  qHD: 'qHD',
  VGA: 'VGA',
  QCIF: 'QCIF',
};

const QUALITY_CONSTRAINTS = {
  [QUALITY_TYPES.HD]: {
    width: { exact: 1280 },
    height: { exact: 720 },
  },
  [QUALITY_TYPES.qHD]: {
    width: { exact: 960 },
    height: { exact: 540 },
  },
  [QUALITY_TYPES.VGA]: {
    width: { exact: 640 },
    height: { exact: 360 },
  },
  [QUALITY_TYPES.QCIF]: {
    width: { exact: 176 },
    height: { exact: 99 },
  },
};

const TRANSITIONS_MAPPING = {
  [QUALITY_TYPES.HD]: {
    5: QUALITY_TYPES.HD,
    4: QUALITY_TYPES.HD,
    3: QUALITY_TYPES.HD,
    2: QUALITY_TYPES.qHD,
    1: QUALITY_TYPES.qHD,
  },
  [QUALITY_TYPES.qHD]: {
    5: QUALITY_TYPES.HD,
    4: QUALITY_TYPES.HD,
    3: QUALITY_TYPES.qHD,
    2: QUALITY_TYPES.VGA,
    1: QUALITY_TYPES.VGA,
  },
  [QUALITY_TYPES.VGA]: {
    5: QUALITY_TYPES.qHD,
    4: QUALITY_TYPES.qHD,
    3: QUALITY_TYPES.VGA,
    2: QUALITY_TYPES.QCIF,
    1: QUALITY_TYPES.QCIF,
  },
  [QUALITY_TYPES.QCIF]: {
    5: QUALITY_TYPES.VGA,
    4: QUALITY_TYPES.VGA,
    3: QUALITY_TYPES.QCIF,
    2: QUALITY_TYPES.QCIF,
    1: QUALITY_TYPES.QCIF,
  },
};

@classic
export default class AutoQualityService extends Service {
  DELAY_BEFORE_UPDATE = 20_000; // 20 sec

  started = false;
  mediaStreamTrack;
  storedLevels = [];
  currentQuality = QUALITY_TYPES.HD;
  @sum('storedLevels') levelsSum;
  @equal('currentQuality', QUALITY_TYPES.QCIF) isQcif;
  @not('isQcif') notQcif;

  @computed('storedLevels.[]', 'levelsSum')
  get averageLevel() {
    return this.levelsSum / this.storedLevels.length;
  }

  initAuto({ mediaStreamTrack }) {
    if (isSafari()) {
      return;
    }
    this.stopAuto();
    setProperties(this, {
      mediaStreamTrack,
      started: true,
    });
    let { width } = mediaStreamTrack.getSettings();
    for (let qualityName in QUALITY_CONSTRAINTS) {
      if (QUALITY_CONSTRAINTS[qualityName].width.exact === width) {
        set(this, 'currentQuality', qualityName);
      }
    }

    this.updateQualityIfNeededTask.perform();
  }

  stopAuto() {
    setProperties(this, {
      mediaStreamTrack: undefined,
      currentQuality: QUALITY_TYPES.HD,
      storedLevels: [],
      started: false,
    });
    this.updateQualityIfNeededTask.cancelAll();
  }

  handleNetworkLevelUpdate(level) {
    if (this.started) {
      this.storedLevels.pushObject(level);
    }
  }

  @task(function* () {
    yield timeout(this.DELAY_BEFORE_UPDATE);
    let newLevel = TRANSITIONS_MAPPING[this.currentQuality][Math.round(this.averageLevel)];
    if (newLevel && newLevel !== this.currentQuality) {
      try {
        yield this.mediaStreamTrack.applyConstraints({
          ...this.mediaStreamTrack.getConstraints(),
          ...QUALITY_CONSTRAINTS[newLevel],
        });

        set(this, 'currentQuality', newLevel);
      } catch (err) {
        // noop
      }
    }
    this.storedLevels.clear();
    this.updateQualityIfNeededTask.perform();
  })
  updateQualityIfNeededTask;
}
