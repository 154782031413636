import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import set from 'ember-set-helper/helpers/set';
import styles from './user-form.module.scss';
export default class UserForm extends Component {
    @service
    session;
    @tracked
    isNameDirty = false;
    @tracked
    userName = this.session.userName;
    nameMaxLength = 70;
    @reads('session.roomModel.featureThClinicianAuth')
    featureThClinicianAuth;
    @reads('session.roomModel.featureThChime')
    featureThChime;
    @reads('session.isHost')
    isHost;
    get errorMessage() {
        let nameLength1 = this.userName?.trim().length;
        if (nameLength1 === 0 || nameLength1 === undefined) {
            return '* field required';
        } else if (nameLength1 > this.nameMaxLength) {
            return `* name is too long (maximum is ${this.nameMaxLength} characters)`;
        }
        return null;
    }
    @action
    setUserName(userName1) {
        this.session.setUserName(userName1);
    }
    @action
    onInput({ target: target1 }) {
        this.userName = target1.value;
        this.isNameDirty = true;
    }
    @action
    onSubmit(event1) {
        event1.preventDefault();
        this.setUserName(this.userName?.trim());
        this.isNameDirty = true;
        if (this.errorMessage) return;
        this.args.submitForm?.();
    }
    static{
        template(`
    <form class={{styles.component}} {{on "submit" this.onSubmit}} autocomplete="off">
      <label>
        {{if this.featureThClinicianAuth "What’s your name?" "Enter your name to join"}}
      </label>
      <input
        value={{this.userName}}
        aria-label="Name"
        autocomplete="off"
        class="input {{if (and this.isNameDirty this.errorMessage) 'not-valid'}}"
        data-test-name-input
        name="name"
        placeholder=""
        required
        {{on "input" this.onInput}}
        {{on "blur" (set this "isNameDirty" true)}}
        {{on "focus" (set this "isNameDirty" false)}}
      />
      <div class="error-message {{unless this.errorMessage 'empty'}}" data-test-name-input-error>
        {{this.errorMessage}}
      </div>
      <button class="action-button" type="submit" disabled={{@submitDisabled}} data-test-submit>
        {{#if this.featureThClinicianAuth}}
          {{if this.isHost "Start video appointment" "Ask to join"}}
        {{else}}
          Join Video Appointment
        {{/if}}
      </button>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
