import { camelize, capitalize, underscore } from '@ember/string';
import { typeOf } from '@ember/utils';

const transformObject = (object, fn) => {
  return Object.entries(object).reduce((result, [key, value]) => {
    result[fn(key)] = typeOf(value) === 'object' ? transformObject(value, fn) : value;

    return result;
  }, {});
};

export function camelCaseKeys(object) {
  return transformObject(object, camelize);
}

export function underscoreKeys(object) {
  return transformObject(object, underscore);
}

export function capitalizeKeys(object) {
  return transformObject(object, capitalize);
}

export function dropNullValues(object) {
  return Object.entries(object).reduce((result, [key, value]) => {
    if (value !== null) {
      result[key] = typeOf(value) === 'object' ? dropNullValues(value) : value;
    }

    return result;
  }, {});
}
