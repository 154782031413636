import { COMMON_SHAPES_CONFIG } from 'frontend/constants/shapes';
import BaseEntity from 'frontend/entities/whiteboard/base';

export default class WhiteboardShape extends BaseEntity {
  type = 'shape';

  setInitAttributes(attributes) {
    this.konvaObject.setAttrs({ ...COMMON_SHAPES_CONFIG, ...attributes });
  }

  resize({ width, height }) {
    let resizeOptions = this.konvaObject.getResizeOptions?.({ width, height }) || { width, height };

    this.update(resizeOptions);
  }
}
