/* import __COLOCATED_TEMPLATE__ from './timer.hbs'; */
import { TIMER_START_TYPES } from 'frontend/constants/settings';
import { classNames } from '@ember-decorators/component';
import { computed, set } from '@ember/object';
import { equal, notEmpty, or, reads } from '@ember/object/computed';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './timer.module.scss';

let DEFAULT_TIME_LEFT = '00:00';

@classic
@classNames(styles.component)
export default class TwilioHeaderControlsTimer extends Component {
  styles = styles;

  @service appointmentSettings;
  @service session;

  timeLeft = DEFAULT_TIME_LEFT;
  isLong = false;

  @reads('appointmentSettings.timerCountsDown') timerCountsDown;
  @reads('appointmentSettings.timerStartType') timerStartType;
  @reads('session.hostJoinTime') hostJoinTime;
  @reads('session.firstParticipantJoinTime') firstParticipantJoinTime;
  @reads('session.isHost') isHost;
  @reads('model.featureThClinicianAuth') featureThClinicianAuth;
  @or('isPopoverShown', 'controlsShown') activeTimer;
  @notEmpty('startTime') isTimerStarted;
  @equal('timerStartType', TIMER_START_TYPES.calendarAppointmentStarts) isTimerStartsByCalendar;

  @(task(function* () {
    while (true) {
      this.setTime();
      if (isEmberTesting()) {
        break;
      }
      yield timeout(1000);
    }
  }).drop())
  runTimerTask;

  @computed('model.{startTime,endTime}')
  get plannedDuration() {
    return this.model.endTime.diff(this.model.startTime);
  }

  @computed('plannedDuration')
  get durationInMinutes() {
    return moment.duration(this.plannedDuration).asMinutes();
  }

  @computed('isLong')
  get timeFormat() {
    return this.isLong ? 'H:mm:ss' : 'mm:ss';
  }

  get isNegative() {
    return (
      this.isTimerStarted && this.timerCountsDown && moment().isAfter(this.timerCountsDownEndpoint)
    );
  }

  @computed('model.endTime', 'plannedDuration', 'startTime', 'isTimerStartsByCalendar')
  get timerCountsDownEndpoint() {
    if (this.isTimerStartsByCalendar) return this.model.endTime;

    let actualEndTime = this.startTime.clone().add(this.plannedDuration);

    return actualEndTime?.isBefore(this.model.endTime) ? actualEndTime : this.model.endTime;
  }

  @computed('model.startTime', 'timerStartType', 'hostJoinTime', 'firstParticipantJoinTime')
  get startTime() {
    switch (this.timerStartType) {
      case TIMER_START_TYPES.hostJoins:
        return this.hostJoinTime;
      case TIMER_START_TYPES.firstParticipantJoins:
        return this.firstParticipantJoinTime;
      case TIMER_START_TYPES.calendarAppointmentStarts:
      default:
        return this.model.startTime;
    }
  }

  @computed('timerCountsDown', 'isTimerStarted', 'isHost', 'featureThClinicianAuth')
  get timerDetails() {
    if (!this.isHost || !this.featureThClinicianAuth) return '';

    let prefix = 'Timer start';
    let postfix = this.timerCountsDown
      ? `, and ${this.isTimerStarted ? 'is counting' : 'counts'} down.`
      : '.';

    switch (this.timerStartType) {
      case TIMER_START_TYPES.hostJoins:
        return `${prefix}ed when host joined${postfix}`;
      case TIMER_START_TYPES.firstParticipantJoins:
        return this.isTimerStarted
          ? `${prefix}ed when first participant joined${postfix}`
          : `${prefix}s when first participant joins${postfix}`;
      case TIMER_START_TYPES.calendarAppointmentStarts:
      default:
        return `${prefix}ed at calendar appointment time${postfix}`;
    }
  }

  setTime() {
    let isWaitingToStart = this.isTimerStartsByCalendar && this.model.startTime.isAfter(moment());

    if (isWaitingToStart) return;
    this.#setIsLong();
    this.#setTimeLeft();
  }

  get durationFromStart() {
    return moment.duration(moment().diff(this.startTime));
  }

  get durationFromEnd() {
    return moment.duration(Math.abs(moment(this.timerCountsDownEndpoint).diff(moment())));
  }

  get durationLeft() {
    return this.timerCountsDown ? this.durationFromEnd : this.durationFromStart;
  }

  #setIsLong() {
    let isLong = this.isTimerStarted && this.durationLeft.hours() > 0;
    set(this, 'isLong', isLong);
  }

  #setTimeLeft() {
    let timeLeft = this.isTimerStarted
      ? moment.utc(this.durationLeft.asMilliseconds()).format(this.timeFormat)
      : DEFAULT_TIME_LEFT;

    if (this.isNegative) {
      timeLeft = `-${timeLeft}`;
    }

    set(this, 'timeLeft', timeLeft);
  }
}
