import {
  DEFAULT_BLUR_PROCESSOR_OPTIONS,
  DEFAULT_VIRTUAL_PROCESSOR_OPTIONS,
  PROCESSOR_TYPES,
  VIRTUAL_BACKGROUNDS_MAP,
} from 'frontend/constants/backgrounds';
import {
  DEFAULT_ZOOM_COEFFICIENT,
  HD_VIDEO_CONSTRAINTS,
  LD_VIDEO_CONSTRAINTS,
  NOISE_CANCELLATION_VENDOR_OPTIONS,
  SCREEN_CONSTRAINTS,
  SD_VIDEO_CONSTRAINTS,
  SD_WIDE_VIDEO_CONSTRAINTS,
  TRACK_TYPE,
  VIDEO_QUALITY_TYPES,
} from 'frontend/constants';
import { TRACK_HASH_LENGTH } from 'frontend/constants/twilio';
import { alias, equal, notEmpty, reads } from '@ember/object/computed';
import { computed, set, setProperties } from '@ember/object';
import { importAsset } from 'frontend/helpers/import-asset';
import { isSafari } from 'frontend/utils/detect-browser';
import { loadImage } from 'frontend/utils/load-image';
import { parseTrackInfo } from 'frontend/utils/name-utils';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import CropVideoProcessor from 'frontend/utils/processors/crop-video';
import Service, { service } from '@ember/service';
import Video from 'twilio-video';
import classic from 'ember-classic-decorator';
import isSupported from 'frontend/utils/processors/is-supported';

@classic
export default class TwilioLocalTracksService extends Service {
  @service errorHandling;
  @service mediaDevices;
  @service session;
  @service persistentProperties;
  @service mixpanel;

  @tracked processorsSupported = isSupported();
  @tracked hdNotSupported = false;

  audioTrack;
  videoTrack;
  screenTrack;
  screenAudioTrack;
  sdWideNotSupported = false;
  constraintRelaxed = false;
  hasConfirmedTrack = false;

  @alias('persistentProperties.uuid') uuid;
  @alias('persistentProperties.selectedAudioInputDeviceId') selectedAudioInputDeviceId;
  @alias('persistentProperties.selectedVideoDeviceId') selectedVideoDeviceId;
  @alias('persistentProperties.selectedVideoQuality') selectedVideoQuality;
  @alias('persistentProperties.selectedVideoProcessorType') selectedVideoProcessorType;

  @reads('mediaDevices.videoDevices') localVideoDevices;
  @reads('mediaDevices.audioInputDevices') localAudioDevices;
  @reads('persistentProperties.noiseCancellationEnabled') noiseCancellationEnabled;
  @reads('persistentProperties.userName') userName;
  @reads('session.roomModel.featureThEnsureTracks') featureThEnsureTracks;
  @notEmpty('localVideoDevices') hasVideo;
  @notEmpty('localAudioDevices') hasAudio;
  @equal('selectedVideoQuality', VIDEO_QUALITY_TYPES.auto) isAutoQuality;

  @computed('audioTrack', 'videoTrack')
  get localTracks() {
    return [this.audioTrack, this.videoTrack].filter(track => track !== undefined);
  }

  trackName(type, userName) {
    let trackName = `${userName}:${type}:${this.uuid}`;

    if (!this.featureThEnsureTracks) return trackName;

    let trackHash = Date.now().toString().slice(-TRACK_HASH_LENGTH);

    return `${trackName}:${trackHash}`;
  }

  _noiseCancellationConstraint() {
    return this.noiseCancellationEnabled
      ? { noiseCancellationOptions: NOISE_CANCELLATION_VENDOR_OPTIONS }
      : {};
  }

  _autoVideoConstraint() {
    if (this.hdNotSupported) {
      return this._sdVideoConstraint();
    } else {
      return HD_VIDEO_CONSTRAINTS;
    }
  }

  _sdVideoConstraint() {
    return this.sdWideNotSupported ? SD_VIDEO_CONSTRAINTS : SD_WIDE_VIDEO_CONSTRAINTS;
  }

  async changeVideoQuality(value) {
    set(this, 'selectedVideoQuality', value);
  }

  async getLocalAudioTrack(deviceId, userName) {
    let name = this.trackName(TRACK_TYPE.audio, userName);
    let options = { name, ...this._noiseCancellationConstraint() };
    let newTrack = await this._createLocalTrack(
      this._twilioCreateLocalAudioTrack,
      { ...options, deviceId: deviceId ? { exact: deviceId } : undefined },
      options
    );

    set(this, 'audioTrack', newTrack);
    return newTrack;
  }

  async getLocalVideoTrack({ deviceId, userName, deviceChanged }) {
    if (deviceChanged) {
      setProperties(this, {
        sdWideNotSupported: false,
      });
      this.hdNotSupported = false;
    }
    let name = this.trackName(TRACK_TYPE.camera, userName);
    let newTrack;

    try {
      newTrack = await this._createLocalVideoTrack(name, deviceId);
    } catch {
      newTrack = await this._createLocalTrack(
        await this._twilioCreateLocalVideoTrack,
        { name, deviceId: { exact: deviceId } },
        { name }
      );
    }

    await this.setTrackVideoProcessor(newTrack, this.selectedVideoProcessorType);
    return newTrack;
  }

  async _createLocalVideoTrack(name, deviceId) {
    try {
      let options = this.#getLocalVideoTrackOptions(deviceId, name);

      return await this._twilioCreateLocalVideoTrack(options);
    } catch (err) {
      this._handleCreateVideoTrackFail(err);
      // retry if have not tried HD and SD-wide yet.
      if (!this.hdNotSupported || !this.sdWideNotSupported) {
        return await this._createLocalVideoTrack(name, deviceId);
      } else {
        throw err;
      }
    }
  }

  async _createLocalTrack(func, options, simplifiedOptions) {
    try {
      return await func(options);
    } catch (err) {
      return await func(simplifiedOptions);
    }
  }

  get screenConstraint() {
    return isSafari() ? SCREEN_CONSTRAINTS.video : SCREEN_CONSTRAINTS;
  }

  async getScreenTrack(participant, userName) {
    let stream = await this.mediaDevices.getDisplayMedia(this.screenConstraint);
    let audioTrack = stream.getTracks().findBy('kind', 'audio');
    let videoTrack = stream.getTracks().findBy('kind', 'video');
    let publicationVideo = await participant.publishTrack(videoTrack, {
      name: this.trackName(TRACK_TYPE.screensharing, userName),
    });

    this.enrichTrackInfo(publicationVideo.track);
    set(this, 'screenTrack', publicationVideo.track);

    if (audioTrack) {
      let publicationAudio = await participant.publishTrack(audioTrack, {
        name: this.trackName(TRACK_TYPE.screenAudio, userName),
      });
      set(this, 'screenAudioTrack', publicationAudio.track);
    }

    return { audioTrack: this.screenAudioTrack, videoTrack: this.screenTrack };
  }

  removeLocalVideoTrack() {
    if (this.videoTrack) {
      this.videoTrack.stop();
      setProperties(this, {
        videoTrack: undefined,
        sdWideNotSupported: false,
      });
      this.hdNotSupported = false;
    }
  }

  removeLocalAudioTrack() {
    if (this.audioTrack) {
      this.audioTrack.stop();
      set(this, 'audioTrack', undefined);
    }
  }

  localTrackConstraints(userName, videoPublished, simplified = false) {
    let { hasAudio, hasVideo, selectedAudioInputDeviceId, selectedVideoDeviceId } = this;

    let hasSelectedAudioDevice = this.localAudioDevices.some(
      device => selectedAudioInputDeviceId && device.deviceId === selectedAudioInputDeviceId
    );

    if (simplified) {
      return {
        video: hasVideo &&
          videoPublished && {
            name: this.trackName(TRACK_TYPE.camera, userName),
          },
        audio: hasAudio && {
          name: this.trackName(TRACK_TYPE.audio, userName),
          ...this._noiseCancellationConstraint(),
        },
      };
    } else {
      let videoTrackName = this.trackName(TRACK_TYPE.camera, userName);

      return {
        video:
          hasVideo &&
          videoPublished &&
          this.#getLocalVideoTrackOptions(selectedVideoDeviceId, videoTrackName),
        audio: hasAudio && {
          ...(hasSelectedAudioDevice && { deviceId: { exact: selectedAudioInputDeviceId } }),
          name: this.trackName(TRACK_TYPE.audio, userName),
          ...this._noiseCancellationConstraint(),
        },
      };
    }
  }

  _handleAutoQualityFail() {
    if (!this.hdNotSupported) {
      this.hdNotSupported = true;
      return setProperties(this, {
        constraintRelaxed: true,
        selectedVideoQuality: VIDEO_QUALITY_TYPES.sd,
      });
    }
    if (!this.sdWideNotSupported) {
      return setProperties(this, {
        sdWideNotSupported: true,
        constraintRelaxed: true,
      });
    }
    if (this.selectedVideoDeviceId) {
      setProperties(this, {
        selectedVideoDeviceId: undefined,
        constraintRelaxed: true,
      });
    }
  }

  _handleCreateVideoTrackFail(err) {
    if (err.name === 'OverconstrainedError' || err.message === 'Starting video failed') {
      switch (this.selectedVideoQuality) {
        case VIDEO_QUALITY_TYPES.hd:
          setProperties(this, {
            selectedVideoQuality: VIDEO_QUALITY_TYPES.auto,
          });
          this.hdNotSupported = true;
          break;
        case VIDEO_QUALITY_TYPES.sd:
          if (this.sdWideNotSupported) {
            set(this, 'selectedVideoDeviceId', undefined);
          } else {
            set(this, 'sdWideNotSupported', true);
          }
          break;
        default:
          this._handleAutoQualityFail();
      }
    } else {
      this._handleAutoQualityFail();
    }
  }

  async _createLocalTracks(userName, videoPublished) {
    try {
      let localTrackConstraints = this.localTrackConstraints(userName, videoPublished);
      set(this, 'constraintRelaxed', false);
      return await this._twilioCreateLocalTracks(localTrackConstraints);
    } catch (err) {
      this._handleCreateVideoTrackFail(err);
      if (err.message.match('audio') && this.selectedAudioInputDeviceId) {
        setProperties(this, {
          selectedAudioInputDeviceId: undefined,
          constraintRelaxed: true,
        });
      }
      // retry if have not tried HD and SD-wide yet.
      if (this.constraintRelaxed) {
        return await this._createLocalTracks(userName, videoPublished);
      } else {
        throw err;
      }
    }
  }

  @(task(function* (userName, { audioPublished, videoPublished }) {
    let { audioTrack, videoTrack, hasAudio, hasVideo } = this;
    let skipTracksCreation =
      (!hasAudio && !hasVideo) || (userName === this.userName && (audioTrack || videoTrack));
    let tracks;

    if (skipTracksCreation) return Promise.resolve();

    try {
      tracks = yield this._createLocalTracks(userName, videoPublished);
    } catch (err) {
      this._handleCreateVideoTrackFail(err);
      if (err.message.match('audio') && this.selectedAudioInputDeviceId) {
        set(this, 'selectedAudioInputDeviceId', undefined);
      }
      tracks = yield this._createLocalTrack(
        this._twilioCreateLocalTracks,
        this.localTrackConstraints(userName, videoPublished),
        this.localTrackConstraints(userName, videoPublished, true)
      );
    }

    let newVideoTrack = tracks.findBy('kind', 'video');
    let newAudioTrack = tracks.findBy('kind', 'audio');

    if (newVideoTrack) {
      this.setLocalVideoTrack(newVideoTrack);
      yield this.setTrackVideoProcessor(newVideoTrack, this.selectedVideoProcessorType);
    }
    if (newAudioTrack) {
      audioPublished ? newAudioTrack.enable() : newAudioTrack.disable();
      this.setLocalAudioTrack(newAudioTrack);
    }
  }).restartable())
  getAudioAndVideoTracksTask;

  _setProcessor(videoTrack, processor) {
    if (videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor);
    }
    if (processor) {
      videoTrack.addProcessor(processor);
    }
    set(this, 'processor', processor);
  }

  async getVideoProcessor(processorType) {
    switch (processorType) {
      case PROCESSOR_TYPES.crop: {
        return new CropVideoProcessor(DEFAULT_ZOOM_COEFFICIENT);
      }
      case PROCESSOR_TYPES.blur: {
        if (this.blurProcessor) {
          return this.blurProcessor;
        } else {
          let { GaussianBlurBackgroundProcessor } = await import('@twilio/video-processors');
          let blurProcessor = new GaussianBlurBackgroundProcessor(DEFAULT_BLUR_PROCESSOR_OPTIONS);

          await this._loadProcessorModel(blurProcessor);
          set(this, 'blurProcessor', blurProcessor);
          return blurProcessor;
        }
      }
      case PROCESSOR_TYPES.none:
        return null;
      default: {
        if (VIRTUAL_BACKGROUNDS_MAP[processorType]) {
          let virtualBackgroundProcessor = await this._getVirtualBackground(processorType);

          set(this, 'virtualBackgroundProcessor', virtualBackgroundProcessor);
          return virtualBackgroundProcessor;
        }

        return null;
      }
    }
  }

  async _loadProcessorModel(processor) {
    await processor.loadModel();
  }

  async _getVirtualBackground(slug) {
    try {
      let srcKey =
        this.#videoConstraint === HD_VIDEO_CONSTRAINTS
          ? VIDEO_QUALITY_TYPES.hd
          : VIDEO_QUALITY_TYPES.sd;
      let importedImage = importAsset([VIRTUAL_BACKGROUNDS_MAP[slug][srcKey]]);

      if (this.virtualBackgroundProcessor) {
        this.virtualBackgroundProcessor.backgroundImage = await loadImage(importedImage, true);

        return this.virtualBackgroundProcessor;
      } else {
        let [{ VirtualBackgroundProcessor }, image] = await Promise.all([
          import('@twilio/video-processors'),
          loadImage(importedImage, true),
        ]);
        let virtualBackgroundProcessor = new VirtualBackgroundProcessor({
          ...DEFAULT_VIRTUAL_PROCESSOR_OPTIONS,
          backgroundImage: image,
        });

        await this._loadProcessorModel(virtualBackgroundProcessor);
        return virtualBackgroundProcessor;
      }
    } catch {
      return null;
    }
  }

  setLocalVideoTrack(videoTrack) {
    this.enrichTrackInfo(videoTrack);
    set(this, 'videoTrack', videoTrack);
  }

  setLocalAudioTrack(audioTrack) {
    this.enrichTrackInfo(audioTrack);
    set(this, 'audioTrack', audioTrack);
  }

  async setTrackVideoProcessor(videoTrack, processorType) {
    let processor = await this.getVideoProcessor(processorType);

    if (videoTrack) {
      this._setProcessor(videoTrack, processor);
    }
    set(this, 'selectedVideoProcessorType', processorType);
  }

  enrichTrackInfo(track) {
    let { type } = parseTrackInfo(track.name);

    set(track, 'type', type);
  }

  _twilioCreateLocalTracks() {
    return Video.createLocalTracks(...arguments);
  }

  _twilioCreateLocalAudioTrack() {
    return Video.createLocalAudioTrack(...arguments);
  }

  _twilioCreateLocalVideoTrack() {
    return Video.createLocalVideoTrack(...arguments);
  }

  #getLocalVideoTrackOptions(deviceId, trackName) {
    let hasSelectedVideoDevice = this.localVideoDevices.some(
      device => deviceId && device.deviceId === deviceId
    );

    return {
      ...this.#videoConstraint,
      name: trackName,
      ...(hasSelectedVideoDevice && { deviceId: { exact: deviceId } }),
    };
  }
  get #videoConstraint() {
    switch (this.selectedVideoQuality) {
      case VIDEO_QUALITY_TYPES.ld:
        return LD_VIDEO_CONSTRAINTS;
      case VIDEO_QUALITY_TYPES.sd:
        return this._sdVideoConstraint();
      case VIDEO_QUALITY_TYPES.hd:
        return HD_VIDEO_CONSTRAINTS;
      default:
        return this._autoVideoConstraint();
    }
  }

  handleTrackSubscriptionConfirmed(trackName) {
    let confirmedTrackByName = this.localTracks.findBy('name', trackName);

    if (!confirmedTrackByName) return;

    set(confirmedTrackByName, 'isConfirmedByHost', true);
    set(this, 'hasConfirmedTrack', true);
  }

  reset() {
    this.removeLocalVideoTrack();
    this.removeLocalAudioTrack();
    set(this, 'hasConfirmedTrack', false);
  }
}
