/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './modal.module.scss';

@classic
@classNames(styles.component)
export default class SharedModal extends Component {
  @action
  didInsertCallback() {
    this.didInsert?.();
  }
}
