import { Shape } from 'konva/lib/Shape';

export default class Circle extends Shape {
  _sceneFunc(context) {
    let rx = this.width() / 2;
    let ry = this.height() / 2;

    context.translate(rx, ry);
    context.beginPath();
    context.ellipse(0, 0, rx, ry, 0, 0, Math.PI * 2);
    context.strokeShape(this);
  }
}
