import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './notification.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class CallChatNotification extends Component {
    @service
    chat;
    @service
    floatingUiElements;
    @reads('floatingUiElements.hideChatNotificationTask')
    closeNotificationTask;
    @reads('chat.lastUnseenMessage')
    newMessage;
    @action
    closeNotification(evt1) {
        evt1?.stopPropagation();
        this.floatingUiElements.setChatNotificationVisibility(false);
    }
    @action
    onClick() {
        this.closeNotificationTask.cancelAll();
        this.chat.toggleChat();
    }
    scheduleCloseNotification = modifier(()=>this.closeNotificationTask.perform(this.newMessage));
    static{
        template(`
    {{! template-lint-disable no-invalid-interactive }}
    <div class={{styles.component}} {{on "click" this.onClick}} data-test-chat-notification>
      <p class="message-preview-wrapper">
        <span class="name">{{this.newMessage.participantName}}:</span>
        <span class="text" {{this.scheduleCloseNotification}}>
          {{this.newMessage.message}}
        </span>
      </p>
      <button
        type="button"
        {{on "click" this.closeNotification}}
        aria-label="close notification"
        data-test-chat-notification-close-button
      >
        {{svgJar "close"}}
      </button>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
