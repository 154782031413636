import { isDataTrack } from 'frontend/utils/twilio';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';

export default class TwilioRemoteTracksService extends Service {
  @service mixpanel;

  firstEnsureTimeoutMs = 10_000;
  secondEnsureTimeoutMs = 35_000;
  skipTrackEnsuring = isEmberTesting();

  ensureParticipantHasTracks(participant) {
    if (this.skipTrackEnsuring) return;

    [this.firstEnsureTimeoutMs, this.secondEnsureTimeoutMs].forEach(timeoutMs => {
      later(() => {
        if (!participant || participant.state === 'disconnected') return;

        let participantPublications = Array.from(participant.tracks.values()).filter(
          publication => !isDataTrack(publication)
        );

        if (participantPublications.length) return;

        this.mixpanel.track('participant with no tracks detected', {
          'participant_sid': participant.sid,
          duration: timeoutMs / 1000,
        });
      }, timeoutMs);
    });
  }
}
