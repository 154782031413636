export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    let reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function base64toArrayBuffer(dataURI) {
  let byteString = atob(dataURI.split(',')[1]);
  let arrayBuffer = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return arrayBuffer;
}

export const getChunks = (base64String, chunkSize) => {
  return base64String.match(new RegExp(`.{1,${chunkSize}}`, 'g'));
};
