import { fetchDynamicAppLink } from 'frontend/utils/fetch-data';
import { isNewIpad } from 'frontend/utils/detect-browser';
import { later } from '@ember/runloop';
import ENV from 'frontend/config/environment';
import MobileDetect from 'mobile-detect';

export function getAppLink() {
  let md = new MobileDetect(navigator?.userAgent);
  return md.os() === 'iOS' || isNewIpad() ? ENV.iosMobileApp : ENV.androidMobileApp;
}

export async function redirectToApp({ roomId, origin } = {}) {
  if (typeof roomId === 'undefined') {
    _redirectToUrl(ENV.mobileAppScheme);
  } else {
    let dynamicLink = await fetchDynamicAppLink({ roomId, origin });
    if (dynamicLink) {
      _redirectToUrl(dynamicLink);
    } else {
      later(() => {
        _redirectToUrl(getAppLink());
      }, 2500);
      _redirectToUrl(ENV.mobileAppScheme + roomId);
    }
  }
}

function _redirectToUrl(url) {
  window.location = url;
}
