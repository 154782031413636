import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './media-modal-container.module.scss';

@classic
@classNames(styles.component)
export default class SharedMediaModalContainer extends Component {
  styles = styles;
}
