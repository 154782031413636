/* import __COLOCATED_TEMPLATE__ from './main-view.hbs'; */
import { action, computed, setProperties } from '@ember/object';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { htmlSafe } from '@ember/template';
import { observes } from '@ember-decorators/object';
import { reads } from '@ember/object/computed';
import { scheduleOnce, throttle } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from 'frontend/components/call/main-view.module.scss';

@classic
@classNames('video', styles.component)
@classNameBindings('hasMainView::hidden')
export default class TwilioMainView extends Component {
  @service('twilio/video-stream') videoStream;
  @service('twilio/room') room;

  sidebarWidth = 240;
  widthProportion = 16;
  heightProportion = 9;
  mainViewWidth = 0;
  mainViewHeight = 0;

  @reads('videoStream.isSharingScreen') isSharingScreen;
  @reads('videoStream.hasMainView') hasMainView;
  @reads('videoStream.mainSpeaker') mainSpeaker;
  @reads('videoStream.selectedParticipant') selectedParticipant;
  @reads('videoStream.gridView') gridView;
  @reads('room.sharedEntity') sharedEntity;
  @reads('room.isWhiteboarding') showWhiteboard;
  @reads('room.isScreenShared') isScreenShared;

  didInsertElement() {
    super.didInsertElement(...arguments);
    window.addEventListener('resize', this.resizeSpeakerVideo);
    this.resizeSpeakerVideo();
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    window.removeEventListener('resize', this.resizeSpeakerVideo);
  }

  // eslint-disable-next-line ember/no-observers
  @observes(
    'hasMainView',
    'videoStream.{mainSpeaker,showSidebar,gridView}',
    'mainSpeaker.dimensionsChangeTrigger'
  )
  triggerResize() {
    if (this.hasMainView) {
      // eslint-disable-next-line ember/no-runloop
      scheduleOnce('afterRender', this.resizeSpeakerVideo);
    }
  }

  @action
  resizeSpeakerVideo() {
    // eslint-disable-next-line ember/no-runloop
    throttle(this, this._resizeSpeakerVideo, 100, false);
  }

  @computed('mainViewWidth', 'mainViewHeight')
  get style() {
    return htmlSafe(`width:${this.mainViewWidth}px;height:${this.mainViewHeight}px;`);
  }

  @computed('isScreenShared', 'selectedParticipant')
  get showScreenShare() {
    return this.isScreenShared && !this.selectedParticipant;
  }

  @computed('mainSpeaker')
  get hasMainSpeaker() {
    return this.mainSpeaker;
  }

  _resizeSpeakerVideo() {
    if (!this.mainSpeaker || !this.element) return;

    let { widthProportion, heightProportion } = this;
    let { height, width: contentWidth } = this.element.parentElement.getBoundingClientRect();
    let width = contentWidth - (this.videoStream.showSidebar ? this.sidebarWidth : 0);

    if (height / width < heightProportion / widthProportion) {
      setProperties(this, {
        mainViewWidth: Math.round((height / heightProportion) * widthProportion),
        mainViewHeight: height,
      });
    } else {
      setProperties(this, {
        mainViewWidth: width,
        mainViewHeight: Math.round((width / widthProportion) * heightProportion),
      });
    }
  }
}
