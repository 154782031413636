/* import __COLOCATED_TEMPLATE__ from './profile-menu.hbs'; */
import { action } from '@ember/object';
import { offset } from '@floating-ui/dom';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './profile-menu.module.scss';

export default class ProfileMenu extends Component {
  @service('twilio/video-stream') videoStream;
  @service session;
  @service floatingUiElements;

  @tracked isTooltipShown = false;

  styles = styles;

  @reads('session.isHost') isHost;
  @reads('session.userData') userData;
  @reads('userData.clinicianName') clinicianName;
  @reads('userData.profileImageUrl') profileImageUrl;
  @reads('floatingUiElements.controlsShown') controlsShown;
  @reads('floatingUiElements.profilePopoverShown') isPopoverShown;

  get shownName() {
    return `${this.clinicianName}${this.isHost ? ' (Host)' : ''}`;
  }

  get popoverMiddleware() {
    return this.args.isNameShown ? null : [offset({ crossAxis: -16 })];
  }

  @action
  handleMouseEnter() {
    this.isTooltipShown = true;
  }

  @action
  handleMouseLeave() {
    this.isTooltipShown = false;
  }

  @action
  handlePopoverChange(isShown) {
    this.floatingUiElements.setProfilePopoverVisibility(isShown);
    this.isTooltipShown = false;
  }
}
