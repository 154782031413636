/* import __COLOCATED_TEMPLATE__ from './announcement-modal.hbs'; */
import { action, set } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class AnnouncementModal extends Component {
  @service persistentProperties;
  @service session;

  featureToAnnounce;
  featuresToAnnounce = [
    {
      name: 'featureThAppleReactions',
      tagline: 'alert',
      title: 'Apple devices may show emojis during video calls',
      description: htmlSafe(
        'Reactions, a new feature from Apple, is currently turned on by default on iOS 17 and macOS Sonoma, and may show emojis during video calls based on perceived gestures by video participants. <br><br> <strong>This feature is not part of SimplePractice Telehealth and SimplePractice does not have control over these settings.</strong> <a href="https://support.simplepractice.com/hc/en-us/articles/360002997731-Telehealth-FAQs#whydidisee" target="_blank">Learn how to turn off Reactions in your device settings</a>'
      ),
      confirmButtonText: 'Got it',
      onConfirm: this.close,
      taglineType: 'alert',
      textAlign: 'left',
      buttonsAlign: 'right',
      showCount: 2,
    },
  ];

  @reads('session.roomModel') roomModel;

  init() {
    super.init(...arguments);
    let featureToAnnounce = this.featuresToAnnounce.reverse().find(this.#shouldBeAnnounced);

    set(this, 'featureToAnnounce', featureToAnnounce);
  }

  @action
  close() {
    if (this.featureToAnnounce.showCount) {
      let featureName = this.#getPersistedPropertyName(this.featureToAnnounce);
      this.persistentProperties.setProp(
        featureName,
        parseInt(this.persistentProperties[featureName]) + 1
      );
    } else {
      this.persistentProperties.setProp(
        this.#getPersistedPropertyName(this.featureToAnnounce),
        true
      );
    }
    set(this, 'featureToAnnounce', null);
  }

  #getPersistedPropertyName(feature) {
    return `${feature.name}Announced`;
  }

  #getShowCount(feature) {
    return parseInt(this.persistentProperties[this.#getPersistedPropertyName(feature)]);
  }

  #shouldBeAnnounced = feature => {
    if (feature.name === 'featureThAppleReactions') {
      return this.#getShowCount(feature) < feature.showCount;
    }
    return (
      this.roomModel[feature.name] &&
      !this.persistentProperties[this.#getPersistedPropertyName(feature)]
    );
  };
}
