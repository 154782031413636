/* import __COLOCATED_TEMPLATE__ from './controls.hbs'; */
import { action, computed, set } from '@ember/object';
import { alias, reads } from '@ember/object/computed';
import { classNames, tagName } from '@ember-decorators/component';
import { confirmation } from 'frontend/utils/modals';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './controls.module.scss';

@classic
@tagName('header')
@classNames('controls', styles.component)
export default class WhiteboardControls extends Component {
  @service('twilio/room') twilioRoom;
  @service('whiteboard') whiteboardService;
  @service persistentProperties;
  @service mixpanel;
  @service session;
  @service appointment;
  @service audioVideoAdapter;

  @alias('persistentProperties.wbComplianceConfirmed') wbComplianceConfirmed;
  @reads('whiteboardService.isLocked') isWhiteboardLocked;
  @reads('whiteboardService.nextAction') nextAction;
  @reads('whiteboardService.currentAction') currentAction;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('audioVideoAdapter.isSharing') isSharing;

  @computed('lockedButton', 'clearButton', 'undoRedoButtons')
  get globalActions() {
    return [
      this.lockedButton,
      this.clearButton,
      ...this.undoRedoButtons,
      {
        title: 'Save',
        icon: 'download',
        noSeparator: true,
        onClick: this.saveWhiteboardAsImage,
      },
    ].filter(Boolean);
  }

  @computed('isWhiteboardLocked', 'isSharing')
  get lockedButton() {
    if (!this.isSharing && !this.isWhiteboardLocked) return null;

    let lockedButtonTooltip = this.isSharing
      ? `${this.isWhiteboardLocked ? 'Only you' : 'Anyone'} can edit this Whiteboard`
      : 'Only the presenter can edit this Whiteboard';

    return {
      name: this.isWhiteboardLocked ? 'Unlock' : 'Lock',
      title: this.isWhiteboardLocked ? 'Locked' : 'Unlocked',
      icon: this.isWhiteboardLocked ? 'locked' : 'unlocked',
      onClick: this.isSharing ? this.toggleWhiteboardLocked : null,
      disabled: !this.isSharing,
      tooltip: lockedButtonTooltip,
    };
  }

  @computed('isSharing', 'isWhiteboardLocked', 'currentAction', 'nextAction')
  get undoRedoButtons() {
    if (!this.isSharing && this.isWhiteboardLocked) {
      return [];
    }

    let undoButton = {
      title: 'Undo',
      icon: 'undo',
      disabled: !this.currentAction,
      ghost: !this.currentAction,
      noSeparator: true,
      onClick: () => this.onHistoryMove(this.currentAction, { undo: true }),
    };
    let redoButton = {
      title: 'Redo',
      icon: 'redo',
      disabled: !this.nextAction,
      ghost: !this.nextAction,
      onClick: () => this.onHistoryMove(this.nextAction, { redo: true }),
    };

    return [undoButton, redoButton];
  }

  @action
  async saveWhiteboardAsImage() {
    await this.showComplianceModal();
    this.mixpanel.track('whiteboard saved');
    let dataURL = this.stage.toDataURL({
      mimeType: 'image/jpeg',
      pixelRatio: 2,
    });

    this.whiteboardService.setActiveObject(null);

    let link = document.createElement('a');

    link.download = `Whiteboard-${moment().format('MM-DD-YY')}`;
    link.href = dataURL;
    document.body.appendChild(link);
    if (!isEmberTesting()) {
      link.click();
    }
    document.body.removeChild(link);
  }

  async showComplianceModal() {
    if (this.wbComplianceConfirmed) return;

    await confirmation({
      title: 'Not intended for signatures',
      text: 'The Telehealth Whiteboard by SimplePractice should not be used for signing legal or clinical documents.',
      confirmButtonText: 'Got it',
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
    });
    set(this, 'wbComplianceConfirmed', true);
  }

  @action
  toggleWhiteboardLocked() {
    this.mixpanel.track(`whiteboard ${this.isWhiteboardLocked ? 'unlocked' : 'locked'}`);
    this.audioVideoAdapter.toggleWhiteboardLocked();
  }

  @computed('isSharing')
  get clearButton() {
    if (!this.isSharing) return null;

    return { title: 'Clear', icon: 'trash-bin', onClick: this.clearWhiteboard };
  }
}
