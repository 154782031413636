/* import __COLOCATED_TEMPLATE__ from './background-tab.hbs'; */
import { PROCESSOR_TYPES, VIRTUAL_BACKGROUNDS_MAP } from 'frontend/constants/backgrounds';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import { or, reads } from 'macro-decorators';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './background-tab.module.scss';

export default class SettingsModalBackgroundTab extends Component {
  @service('twilio.room') twilioRoom;
  @service('chime.local-audio-video') localAudioVideo;
  @service mixpanel;
  @service appointmentSettings;
  @service session;
  @service persistentProperties;
  @service audioVideoAdapter;

  @tracked previewVideoSource = null;
  @tracked scrollBarWidth = 0;

  styles = styles;
  videoElement = null;

  @reads('audioVideoAdapter.isBackgroundSupported') isBackgroundSupported;
  @reads('audioVideoAdapter.videoInputSource') generalVideoSource;
  @reads('audioVideoAdapter.isVideoPublished') isVideoPublished;
  @reads('appointmentSettings.backgroundEnabled') backgroundEnabled;
  @reads('session.isHost') isHost;
  @reads('session.roomModel.featureThHostControls') featureThHostControls;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('persistentProperties.isSelfViewMirrored') mirrorVideo;
  @reads('persistentProperties.selectedVideoProcessorType') selectedVideoProcessorType;
  @or('isVideoPublished', 'previewVideoSource') isPreviewVideoShown;

  get backgroundType() {
    return Object.keys(PROCESSOR_TYPES).includes(this.selectedVideoProcessorType)
      ? this.selectedVideoProcessorType
      : PROCESSOR_TYPES.none;
  }

  get backgroundTypes() {
    let basicTypes = [
      {
        slug: PROCESSOR_TYPES.none,
        img: '/assets/images/background-none.png',
        alt: 'No background',
      },
      {
        slug: PROCESSOR_TYPES.crop,
        img: '/assets/images/background-crop.png',
        alt: 'Crop background',
        disabled: !this.isBackgroundSupported,
      },
    ];

    if (this.featureThHostControls && !this.isHost && !this.backgroundEnabled) return basicTypes;

    return [
      ...basicTypes,
      {
        slug: PROCESSOR_TYPES.blur,
        img: '/assets/images/background-blur.png',
        alt: 'Blur background',
        disabled: !this.isBackgroundSupported,
      },
      ...Object.entries(VIRTUAL_BACKGROUNDS_MAP).map(([slug, sources]) => ({
        slug,
        img: sources.thumbnail,
        alt: `Virtual background: ${slug}`,
        disabled: !this.isBackgroundSupported,
      })),
    ];
  }

  get tilesContainerStyle() {
    let SETTINGS_TAB_PADDING = 24;
    return htmlSafe(`padding-right:${SETTINGS_TAB_PADDING - this.scrollBarWidth}px`);
  }

  initVideo = modifier(videoElement => {
    this.videoElement = videoElement;
    this.audioVideoAdapter.attachLocalVideo({
      videoElement,
      mediaSource: this.previewVideoSource || this.generalVideoSource,
    });
  });

  selectBackgroundType = task({ restartable: true }, async type => {
    if (this.backgroundType === type) return;

    this.mixpanel.track('background', { type });

    let track = this.generalVideoSource || this.previewVideoSource;

    await this.audioVideoAdapter.setVideoProcessor({ track, type });

    if ((this.featureThChime && this.generalVideoSource) || (!this.featureThChime && track)) return;

    let previewVideoSource = await this.audioVideoAdapter.createLocalVideo();
    if (this.previewVideoSource === previewVideoSource) return;

    this.previewVideoSource = previewVideoSource;
  });

  calculateScrollWidth = modifier(element => {
    this.scrollBarWidth = element.offsetWidth - element.clientWidth;
  });

  async willDestroy() {
    super.willDestroy(...arguments);
    if (!this.previewVideoSource) return;

    if (this.featureThChime) {
      await this.audioVideoAdapter.stopLocalVideo();
    } else {
      this.previewVideoSource.stop();
    }

    this.audioVideoAdapter.detachLocalVideo(this.videoElement);
  }
}
