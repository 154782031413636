import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class AppointmentController extends Controller {
  @service floatingUiElements;

  queryParams = ['origin'];

  @reads('floatingUiElements.isSettingsModalShown') showSettingsModal;
}
