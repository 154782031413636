export const TRACK_KINDS = {
  data: 'data',
  audio: 'audio',
  video: 'video',
};

export const ROOM_EVENTS = {
  participantConnected: 'participantConnected',
  participantDisconnected: 'participantDisconnected',
  dominantSpeakerChanged: 'dominantSpeakerChanged',
  disconnected: 'disconnected',
  reconnected: 'reconnected',
  recordingStarted: 'recordingStarted',
  recordingStopped: 'recordingStopped',
};

export const ROOM_STATES = {
  connected: 'connected',
  disconnected: 'disconnected',
  reconnecting: 'reconnecting',
};

export const PARTICIPANT_EVENTS = {
  trackPublished: 'trackPublished',
  trackPublicationFailed: 'trackPublicationFailed',
  trackUnpublished: 'trackUnpublished',
  trackEnabled: 'trackEnabled',
  trackDisabled: 'trackDisabled',
  trackSubscribed: 'trackSubscribed',
  networkQualityLevelChanged: 'networkQualityLevelChanged',
  reconnecting: 'reconnecting',
  reconnected: 'reconnected',
};

export const PUBLICATION_EVENTS = {
  subscribed: 'subscribed',
  unsubscribed: 'unsubscribed',
  switchedOn: 'switchedOn',
  switchedOff: 'switchedOff',
};

export const ERRORS = {
  tooManyParticipants: 53105,
  signalingConnectionError: 53000,
};

export const NETWORK_QUALITY_TIMEOUT = 120_000;
export const TRACK_HASH_LENGTH = 5;
export const TRACK_CONFIRMATION_TIMEOUT_MS = 10_000;
export const MAX_TRACK_CONFIRMATION_RETRIES = 3;
export const PING_TIMEOUT_MS = 1000;
