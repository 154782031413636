import { COLORS } from 'frontend/constants/colors';

export const COMMON_SHAPES_CONFIG = {
  stroke: COLORS.smoke700,
  strokeWidth: 2,
  hitStrokeWidth: 10,
};

export const SHAPES = {
  triangle: 'triangle',
  circle: 'circle',
  square: 'square',
  arrow: 'arrow',
  line: 'line',
  grid: 'grid',
};

export const WHITEBOARD_SHAPES = {
  [SHAPES.triangle]: {
    width: 370,
    height: 320,
    lineJoin: 'round',
  },
  [SHAPES.circle]: {
    width: 320,
    height: 320,
  },
  [SHAPES.square]: {
    width: 320,
    height: 320,
  },
  [SHAPES.arrow]: {
    width: 80,
    strokeWidth: 3,
    lineJoin: 'round',
    lineCap: 'round',
  },
  [SHAPES.line]: {
    width: 320,
    lineCap: 'round',
  },
  [SHAPES.grid]: {
    width: 320,
    height: 320,
  },
};
