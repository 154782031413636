import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class MediaDevicesService extends Service {
  @service persistentProperties;
  @service devicesAdapter;

  @tracked devices = [];

  get videoDevices() {
    return this.devices.filter(device => device.kind === 'videoinput');
  }

  get audioInputDevices() {
    return this.devices.filter(device => device.kind === 'audioinput');
  }

  get audioOutputDevices() {
    return this.devices.filter(device => device.kind === 'audiooutput');
  }

  getDevices = async () => {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    let devices = await this.enumerateDevices();
    this.devices = devices;
  };

  trackDevices() {
    // Backward compatibility with old Safari versions
    if (navigator.mediaDevices.addEventListener) {
      navigator.mediaDevices.addEventListener('devicechange', this.getDevices);
    } else if (navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = this.getDevices;
    }
    return this.getDevices();
  }

  // workaround for stubbing in tests
  enumerateDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }

  getUserMedia(constraints = { audio: true, video: true }) {
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  getDisplayMedia(constraints) {
    if (navigator.mediaDevices.getDisplayMedia) {
      return navigator.mediaDevices.getDisplayMedia(constraints);
    }

    return navigator.getDisplayMedia(constraints);
  }

  async takeDevicesControl(devices) {
    // Approach taken from the Twilio test react app, handles case when audio device is
    // connected in another tab in chrome.
    let shouldAskForMediaPermissions = devices.every(device => !(device.deviceId && device.label));
    if (shouldAskForMediaPermissions) {
      let mediaStream = await this.getUserMedia();
      mediaStream.getTracks().forEach(track => track.stop());
    }
  }
}
