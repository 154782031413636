/* import __COLOCATED_TEMPLATE__ from './scroll-arrow.hbs'; */
import { action, set } from '@ember/object';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { later, scheduleOnce } from '@ember/runloop';
import { observes } from '@ember-decorators/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from 'frontend/components/call/sidebar/scroll-arrow.module.scss';

@classic
@classNameBindings('up:up:down', 'hidden')
@classNames(styles.component)
export default class TwilioSidebarScrollArrow extends Component {
  @service('twilio/video-stream') videoStream;

  sidebarTileHeight = 135;
  hidden = true;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.handleScrollOrResize = this.handleScrollOrResize.bind(this);
    window.addEventListener('resize', () => later(this, this.handleScrollOrResize, 100));
    this.element.parentElement.addEventListener('scroll', this.handleScrollOrResize);
    this._handleSizeChange(false);
  }

  handleScrollOrResize() {
    this._handleSizeChange(true);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    window.removeEventListener('resize', this.handleScrollOrResize);
    this.element.parentElement.removeEventListener('scroll', this.handleScrollOrResize);
  }

  // eslint-disable-next-line ember/no-observers
  @observes('videoStream.participants.[]', 'videoStream.gridView')
  triggerSizeChange() {
    if (this.videoStream.gridView) {
      set(this, 'hidden', true);
    } else {
      scheduleOnce('afterRender', this._handleSizeChange.bind(this));
    }
  }

  _handleSizeChange(isEvent) {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    let { clientHeight, scrollTop, scrollHeight } = this.element?.parentElement || {};

    if (this.up) {
      return set(this, 'hidden', scrollTop === 0);
    } else {
      let { participants, hideSelfView } = this.videoStream;
      let blocksCount = participants.length + (hideSelfView ? 1 : 0);
      let noScroll = clientHeight >= this.sidebarTileHeight * blocksCount;
      let scrolledToBottom = isEvent && scrollTop + clientHeight === scrollHeight;

      set(this, 'hidden', noScroll || scrolledToBottom);
    }
  }

  @action
  click() {
    let el = this.element?.parentElement;
    el.scrollTop += this.up ? -el.clientHeight : el.clientHeight;
  }
}
