import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { initials, trimmedName } from 'frontend/utils/name-utils';
import { modifier } from 'ember-modifier';
import { not, reads } from 'macro-decorators';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AttendeeTile from 'frontend/components/call/attendee-tile';
import Component from '@glimmer/component';
import styles from './speaker.module.scss';
export default class CallPreviewVideoTile extends Component {
    @service('chime.meeting-manager')
    meetingManager;
    @service('chime.roster')
    rosterService;
    @service
    audioVideoAdapter;
    @service
    session;
    @service
    persistentProperties;
    @tracked
    isVideoStalled = false;
    @reads('session.userData.profileImageUrl')
    profileImageUrl;
    @reads('audioVideoAdapter.hasLocalCameraSource')
    hasVideo;
    @reads('audioVideoAdapter.videoInputSource')
    videoInputSource;
    @reads('persistentProperties.isSelfViewMirrored')
    isSelfViewMirrored;
    @reads('persistentProperties.userName')
    userName;
    @reads('rosterService.localAttendeeData')
    localAttendeeData;
    @not('audioVideoAdapter.isAudioPublished')
    muted;
    initVideo = modifier((videoElement1)=>{
        this.audioVideoAdapter.attachLocalVideo({
            videoElement: videoElement1,
            mediaSource: this.videoInputSource
        });
    });
    get networkLevel() {
        if (this.localAttendeeData?.audioSignalScore === undefined) return undefined;
        if (!this.hasVideo) return this.localAttendeeData?.audioSignalScore;
        return this.localAttendeeData?.networkLevelScore;
    }
    get trimmedName() {
        return trimmedName(this.userName);
    }
    get initials() {
        return initials(this.userName);
    }
    @action
    setVideoStalledState(state1) {
        this.isVideoStalled = state1;
    }
    static{
        template(`
    <div
      class={{concat styles.component (if this.isSelfViewMirrored " mirror-video")}}
      data-test-preview-video-container
    >
      <AttendeeTile
        @hasVideo={{this.hasVideo}}
        @initVideoModifier={{this.initVideo}}
        @initials={{this.initials}}
        @logoColor={{1}}
        @muted={{this.muted}}
        @profileImageUrl={{this.profileImageUrl}}
        @trimmedName={{this.trimmedName}}
        @networkLevel={{this.networkLevel}}
        @stalled={{this.isVideoStalled}}
        @setVideoStalledState={{this.setVideoStalledState}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
