/* import __COLOCATED_TEMPLATE__ from './transformer.hbs'; */
/* eslint-disable ember/no-runloop*/
import { TRANSFORMER_ID } from 'frontend/constants/whiteboard';
import { action, computed, set, setProperties } from '@ember/object';
import {
  angleToRad,
  getPointerPosition,
  isOutOfStage,
  rotatePoint,
} from 'frontend/utils/whiteboard';
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { equal, reads } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import { isTablet } from 'frontend/utils/detect-browser';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './transformer.module.scss';

const MIN_IMAGE_SIZE = 48;

@classic
@attributeBindings('style', 'draggable')
@classNameBindings('hide', 'tablet')
@classNames(styles.component)
export default class WhiteboardTransformer extends Component {
  @service whiteboard;
  style;
  dragBox;
  hide = false;
  listenedShapeAttrs = ['x', 'y', 'rotation', 'width', 'height', 'text', 'points'];
  listenedStageAttrs = ['width', 'height', 'scaleX', 'scaleY'];
  body = document.body;
  tablet = isTablet();

  @reads('activeObject.konvaObject') konvaObject;
  @equal('activeObject.type', 'text') isText;
  @equal('activeObject.type', 'line') isLine;

  @computed('isText', 'isLine', 'konvaObject')
  get hideResize() {
    return this.isText || (this.isLine && this.konvaObject.points().length === 2);
  }

  init() {
    super.init(...arguments);
    scheduleOnce('afterRender', this, this._updateStyle);
    set(this, 'activeObjectCache', this.activeObject);
    this.listenedStageAttrs.forEach(name =>
      this.stage.on(`${name}Change.transform`, () =>
        scheduleOnce('afterRender', this, this._updateStyle)
      )
    );

    this._initDragBox();
    this._addNodeListeners(this.konvaObject);
    window.addEventListener('keydown', this.keyDownCallback);
  }

  _addNodeListeners(node) {
    this.listenedShapeAttrs.forEach(name =>
      node.on(`${name}Change.transform`, () => scheduleOnce('afterRender', this, this._updateStyle))
    );
    node.on('dragmove', this.dragmove);
    node.on('dragend', this.dragend);
  }

  _removeNodeListeners(node) {
    this.listenedShapeAttrs.forEach(name => node.off(`${name}Change.transform`));
    node.off('dragmove', this.dragmove);
    node.off('dragend', this.dragend);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);

    this._removeNodeListeners(this.konvaObject);
    this.dragBox.destroy();
    window.removeEventListener('keydown', this.keyDownCallback);
  }

  didUpdateAttrs() {
    super.didUpdateAttrs(...arguments);
    this._removeNodeListeners(this.activeObjectCache.konvaObject);
    this.activeObjectCache.konvaObject.draggable(false);
    set(this, 'activeObjectCache', this.activeObject);
    this._initDragBox();
    this._addNodeListeners(this.konvaObject);

    scheduleOnce('afterRender', this, this._updateStyle);
  }

  _updateStyle() {
    let { konvaObject: node, stage, dragBox } = this;

    if (!node || !stage || !dragBox) return;

    let { x: left, y: top } = dragBox.position();
    let { width: w, height: h } = dragBox.size();

    set(
      this,
      'style',
      htmlSafe(
        `top:${top}px;left:${left}px;width:${w}px;height:${h}px;transform:rotate(${node.rotation()}deg);`
      )
    );
  }

  @action
  dragmove() {
    let { konvaObject, stage } = this;
    let box = konvaObject.getClientRect();
    let absPos = konvaObject.getAbsolutePosition();
    let offsetX = box.x - absPos.x;
    let offsetY = box.y - absPos.y;
    let newAbsPos = { ...absPos };

    if (box.x < 0) {
      newAbsPos.x = -offsetX;
    }
    if (box.y < 0) {
      newAbsPos.y = -offsetY;
    }
    if (box.x + box.width > stage.width()) {
      newAbsPos.x = stage.width() - box.width - offsetX;
    }
    if (box.y + box.height > stage.height()) {
      newAbsPos.y = stage.height() - box.height - offsetY;
    }
    konvaObject.absolutePosition(newAbsPos);
  }

  @action
  dragend() {
    this.onTransformationEnd();
  }

  @action
  rotateStart(evt) {
    evt.stopPropagation();
    this.dragBox.draggable(false);
    let node = this.konvaObject;
    let relativeCenter = rotatePoint(
      { x: (node.width() * this.stage.scaleX()) / 2, y: (node.height() * this.stage.scaleX()) / 2 },
      angleToRad(node.rotation())
    );
    let { clientX, clientY } = this._getClientPosition(evt);

    this.lockActiveObject();
    setProperties(this, {
      startX: clientX,
      startY: clientY,
      initialRotation: node.rotation(),
      rotationX: clientX + relativeCenter.x,
      rotationY: clientY + relativeCenter.y,
    });
    document.addEventListener('mousemove', this.rotate, false);
    document.addEventListener('touchmove', this.rotate, false);
    document.addEventListener('mouseup', this.rotateFinish, false);
    document.addEventListener('touchend', this.rotateFinish, false);
  }

  @action
  rotate(evt) {
    evt.stopPropagation();
    let { clientX, clientY } = this._getClientPosition(evt);
    let { startX, startY, rotationX, rotationY } = this;
    if (startX === clientX && startY === clientY) return;
    let oldAngle = Math.atan2(startY - rotationY, startX - rotationX) + Math.PI / 2;
    let newAngle = Math.atan2(clientY - rotationY, clientX - rotationX) + Math.PI / 2;
    let newRotation = this.initialRotation + (180 / Math.PI) * (newAngle - oldAngle);

    this.whiteboard.rotateAroundCenter(this.konvaObject, newRotation, this.stage);
    scheduleOnce('afterRender', this, this._updateStyle);
  }

  @action
  rotateFinish() {
    this.dragBox.draggable(true);
    document.removeEventListener('mousemove', this.rotate, false);
    document.removeEventListener('touchmove', this.rotate, false);
    document.removeEventListener('mouseup', this.rotateFinish, false);
    document.removeEventListener('touchend', this.rotateFinish, false);
    scheduleOnce('afterRender', this, this._updateStyle);
    setProperties(this, {
      startX: null,
      startY: null,
      initialRotation: null,
      rotationX: null,
      rotationY: null,
    });
    this.onTransformationEnd();
  }

  @action
  resizeStart(evt) {
    evt.stopPropagation();
    this.dragBox.draggable(false);
    document.addEventListener('mousemove', this.resize, false);
    document.addEventListener('touchmove', this.resize, false);
    document.addEventListener('mouseup', this.resizeFinish, false);
    document.addEventListener('touchend', this.resizeFinish, false);
    let { clientX, clientY } = this._getClientPosition(evt);

    setProperties(this, {
      clientX,
      clientY,
      initialWidth: this.konvaObject.width(),
      initialHeight: this.konvaObject.height(),
    });
  }

  @action
  resize(evt) {
    let { clientX, clientY } = this._getClientPosition(evt);
    if (clientX === this.clientX && clientY === this.clientY) return;
    let { x, y, rotation = 0 } = this.konvaObject.attrs;
    let pointer = getPointerPosition(this.stage);
    let dx = pointer.x - x;
    let dy = pointer.y - y;
    let point = rotatePoint({ x: dx, y: dy }, angleToRad(-rotation));
    let newWidth = Math.max(point.x, MIN_IMAGE_SIZE / this.stage.scaleX());
    let newHeight = Math.max(point.y, MIN_IMAGE_SIZE / this.stage.scaleX());
    if (isOutOfStage(this.stage, { x, y, width: newWidth, height: newHeight, rotation })) {
      return;
    }

    this.lockActiveObject();
    this.activeObject.resize({ width: newWidth, height: newHeight });
    scheduleOnce('afterRender', this, this._updateStyle);
  }

  @action
  resizeFinish() {
    this.dragBox.draggable(true);
    document.removeEventListener('mousemove', this.resize, false);
    document.removeEventListener('touchmove', this.resize, false);
    document.removeEventListener('mouseup', this.resizeFinish, false);
    document.removeEventListener('touchend', this.resizeFinish, false);
    scheduleOnce('afterRender', this, this._updateStyle);

    this.onTransformationEnd();
  }

  _getClientPosition(evt) {
    let clientX = evt.clientX || evt.touches[0]?.clientX;
    let clientY = evt.clientY || evt.touches[0]?.clientY;
    return { clientX, clientY };
  }

  _initDragBox() {
    let layer = this.konvaObject.getLayer();

    if (!layer) return;

    let dragBox = new this.whiteboard.Konva.Transformer({
      borderEnabled: true,
      borderStroke: 'transparent',
      rotateEnabled: false,
      nodes: [this.konvaObject],
      enabledAnchors: ['bottom-right'],
      anchorSize: 0,
      shouldOverdrawWholeArea: true,
      id: TRANSFORMER_ID,
    });

    this.dragBox?.destroy();
    layer.add(dragBox);
    setProperties(this, { dragBox });
  }

  @action
  keyDownCallback({ key }) {
    if (key === 'Backspace' || key === 'Delete') {
      this.deleteObject(this.activeObject);
    }
  }
}
