/* import __COLOCATED_TEMPLATE__ from './panel.hbs'; */
import { alias, reads } from '@ember/object/computed';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from 'frontend/components/call/sidebar/panel.module.scss';

@classic
@classNameBindings('isExpanded::collapsed', 'isHidden:hidden')
@classNames(styles.component)
export default class TwilioSidebarPanel extends Component {
  @service('twilio/video-stream') videoStream;

  @alias('videoStream.showSidebar') isExpanded;
  @reads('videoStream.hideSelfView') hideSelfView;
  @reads('videoStream.hasFixedMainView') hasFixedMainView;

  @reads('videoStream.dominantSpeaker') dominantSpeaker;
  @reads('videoStream.participants') participants;
  @reads('videoStream.shareParticipant') shareParticipant;

  @computed('hideSelfView', 'participants.[]', 'shareParticipant')
  get isHidden() {
    if (!this.hideSelfView) {
      return false;
    }

    let subscribersToHide = this.shareParticipant ? 0 : 1;
    return this.participants.length <= subscribersToHide;
  }
}
