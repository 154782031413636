import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import styles from './sharing-top.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default template(`
  <div class={{styles.component}}>
    <div class="banner-top notify">
      {{svgJar @icon class="icon screen-share"}}
      {{@title}}
    </div>
    {{#if @stopSharing}}
      <button class="banner-top action" {{on "click" @stopSharing}} type="button">
        {{svgJar "control-stop-sharing" class="icon"}}
        Stop sharing
      </button>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
