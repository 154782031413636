import { dependentKeyCompat } from '@ember/object/compat';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { not, notEmpty } from '@ember/object/computed';
import { set } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';

export const JOIN_NOTIFICATION_STATES = {
  hidden: 'hidden',
  short: 'short',
  detailed: 'detailed',
};

export const RECORDING_NOTIFICATION_STATES = {
  hidden: 'hidden',
  waiting: 'waiting',
  allowed: 'allowed',
  declined: 'declined',
  stopped: 'stopped',
};

@classic
export default class FloatingUiElementsService extends Service {
  chatNotificationShown = false;
  profilePopoverShown = false;
  showDiagnosticsModal = false;
  notificationShownDuration = isEmberTesting() ? 1 : 4_000;
  roomControlsShownDuration = isEmberTesting() ? 1 : 4_000;
  eventNotificationOptions;

  @tracked joinRequestNotificationState = JOIN_NOTIFICATION_STATES.hidden;
  @tracked recordingNotificationState = RECORDING_NOTIFICATION_STATES.hidden;
  @tracked controlsShown = true;
  @tracked isSettingsModalShown = false;

  @not('joinRequestNotificationHidden') joinRequestNotificationShown;
  @notEmpty('eventNotificationOptions') eventNotificationShown;

  @dependentKeyCompat
  get joinRequestNotificationHidden() {
    return this.joinRequestNotificationState === JOIN_NOTIFICATION_STATES.hidden;
  }

  @dependentKeyCompat
  get RecordingNotificationHidden() {
    return this.recordingNotificationState === RECORDING_NOTIFICATION_STATES.hidden;
  }

  @(task(function* () {
    do {
      yield timeout(this.roomControlsShownDuration);
    } while (this.profilePopoverShown && !isEmberTesting());

    this.setControlsVisibility(false);
  }).restartable())
  hideControlsTask;

  @(task(function* () {
    yield timeout(this.notificationShownDuration);
    this.setChatNotificationVisibility(false);
  }).drop())
  hideChatNotificationTask;

  @(task(function* () {
    yield timeout(this.notificationShownDuration);
    this.hideEventNotification();
  }).drop())
  hideEventNotificationTask;

  @(task(function* () {
    yield timeout(this.notificationShownDuration);
    this.hideRecordingNotification();
  }).drop())
  hideRecordingNotificationTask;

  setControlsVisibility(isShown) {
    set(this, 'controlsShown', isShown);
  }

  setProfilePopoverVisibility(isShown) {
    set(this, 'profilePopoverShown', isShown);
  }

  setChatNotificationVisibility(isShown) {
    set(this, 'chatNotificationShown', isShown);
  }

  showWaitingRecordingNotification() {
    this.recordingNotificationState = RECORDING_NOTIFICATION_STATES.waiting;
  }

  showAlowedRecordingNotification() {
    this.recordingNotificationState = RECORDING_NOTIFICATION_STATES.allowed;
    this.hideRecordingNotificationTask.perform();
  }

  showDeclinedRecordingNotification() {
    this.recordingNotificationState = RECORDING_NOTIFICATION_STATES.declined;
    this.hideRecordingNotificationTask.perform();
  }

  showStoppedRecordingNotification() {
    this.recordingNotificationState = RECORDING_NOTIFICATION_STATES.stopped;
    this.hideRecordingNotificationTask.perform();
  }

  hideRecordingNotification() {
    this.recordingNotificationState = RECORDING_NOTIFICATION_STATES.hidden;
  }

  showShortJoinRequestNotification() {
    this.joinRequestNotificationState = JOIN_NOTIFICATION_STATES.short;
  }

  showDetailedJoinRequestNotification() {
    this.joinRequestNotificationState = JOIN_NOTIFICATION_STATES.detailed;
  }

  hideJoinRequestNotification() {
    this.joinRequestNotificationState = JOIN_NOTIFICATION_STATES.hidden;
  }

  showEventNotification({ text, icon } = {}) {
    set(this, 'eventNotificationOptions', { text, icon });
  }

  hideEventNotification() {
    set(this, 'eventNotificationOptions', null);
  }

  toggleDiagnosticsModal() {
    set(this, 'showDiagnosticsModal', !this.showDiagnosticsModal);
  }
}
