import { _ as _applyDecoratedDescriptor, a as _initializerDefineProperty } from '../_rollupPluginBabelHelpers-ad98503d.js';
import { inject } from '@ember/service';
import Helper from '@ember/component/helper';
import { guidFor } from '@ember/object/internals';

var _dec, _class, _descriptor;
/**
  `{{page-title}}` helper used to set the title of the current route context.

  @public
  @method page-title
 */

let PageTitle = (_dec = inject('page-title'), (_class = class PageTitle extends Helper {
  get tokenId() {
    return guidFor(this);
  }

  constructor() {
    super(...arguments);

    _initializerDefineProperty(this, "tokens", _descriptor, this);

    this.tokens.push({
      id: this.tokenId
    });
  }

  compute(params, _hash) {
    let hash = { ..._hash,
      id: this.tokenId,
      title: params.join('')
    };
    this.tokens.push(hash);
    this.tokens.scheduleTitleUpdate();
    return '';
  }

  willDestroy() {
    super.willDestroy();
    this.tokens.remove(this.tokenId);
    this.tokens.scheduleTitleUpdate();
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tokens", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));

export { PageTitle as default };
