/* import __COLOCATED_TEMPLATE__ from './general-settings.hbs'; */
import { TIMER_START_TYPES } from 'frontend/constants/settings';
import { action } from '@ember/object';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './general-settings.module.scss';

let getTimerStartTypeLabel = timerStartType => timerStartType.replaceAll('_', ' ');

export default class SettingsModalGeneralSettings extends Component {
  @service appointmentSettings;
  @service persistentProperties;
  @service session;
  @service mixpanel;

  timerStartTypesList = Object.values(TIMER_START_TYPES).map(value => ({
    value,
    label: getTimerStartTypeLabel(value),
  }));
  styles = styles;

  @reads('appointmentSettings.timerCountsDown') timerCountsDown;
  @reads('appointmentSettings.timerStartType') timerStartType;
  @reads('appointmentSettings.isSettingUpdating') isSettingUpdating;
  @reads('session.isHost') isHost;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('persistentProperties.isTimerEnabled') isTimerEnabled;
  @reads('persistentProperties.isChatNotificationEnabled') isChatNotificationEnabled;

  get showHostTimerSettings() {
    return this.featureThClinicianAuth && this.isHost && this.isTimerEnabled;
  }

  @action
  timerShowChanged() {
    this.persistentProperties.setProp('isTimerEnabled', !this.isTimerEnabled);

    this.#trackTimerUpdated({ 'status': this.isTimerEnabled ? 'enabled' : 'disabled' });
  }

  @action
  async timerCountsDownChanged() {
    let timerProperty = `count ${!this.timerCountsDown ? 'down' : 'up'}`;

    await this.appointmentSettings.update({ timerCountsDown: !this.timerCountsDown });
    this.#trackTimerUpdated({ 'timer_property': timerProperty });
  }

  @action
  async timerStartTypeChanged(timerStartType) {
    await this.appointmentSettings.update({ timerStartType });
    this.#trackTimerUpdated({ 'timer_type': getTimerStartTypeLabel(timerStartType) });
  }

  @action
  toggleChatNotificationEnabled() {
    this.persistentProperties.setProp('isChatNotificationEnabled', !this.isChatNotificationEnabled);
    this.mixpanel.track('chat notifications updated', {
      status: this.isChatNotificationEnabled ? 'enabled' : 'disabled',
    });
  }

  #trackTimerUpdated(payload) {
    this.mixpanel.track('timer updated', payload);
  }
}
