import { template } from "@ember/template-compiler";
import { NETWORK_QUALITY_SCORE } from 'frontend/utils/network-connection';
import { array, concat } from '@ember/helper';
import { equal, not, reads } from 'macro-decorators';
import { routeFor } from 'frontend/helpers/route-for';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './network-quality-indicator.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
const TWILIO_NETWORK_LEVEL_MAP = {
    0: 0,
    1: 1,
    2: 1,
    3: 2,
    4: 3,
    5: 3
};
const SVG_NAMES = {
    [NETWORK_QUALITY_SCORE.noConnection]: 'network-connection-0',
    [NETWORK_QUALITY_SCORE.poor]: 'network-connection-1',
    [NETWORK_QUALITY_SCORE.good]: 'network-connection-2',
    [NETWORK_QUALITY_SCORE.excellent]: 'network-connection-3'
};
const LEVEL_DESCRIPTION = {
    [NETWORK_QUALITY_SCORE.noConnection]: 'No connection',
    [NETWORK_QUALITY_SCORE.poor]: 'Poor connection',
    [NETWORK_QUALITY_SCORE.good]: 'Average connection',
    [NETWORK_QUALITY_SCORE.excellent]: 'Excellent connection'
};
export default class NetworkQualityIndicator extends Component {
    @service
    session;
    @reads('session.roomModel.featureThChime')
    featureThChime;
    @equal('mappedLevel', NETWORK_QUALITY_SCORE.poor)
    isPoorConnection;
    @not('isPoorConnection')
    notPoorConnection;
    @equal('args.level', undefined)
    isConnecting;
    get mappedLevel() {
        return this.featureThChime ? this.args.level || NETWORK_QUALITY_SCORE.noConnection : TWILIO_NETWORK_LEVEL_MAP[this.args.level] || 0;
    }
    get svgForLevel() {
        return SVG_NAMES[this.mappedLevel];
    }
    get descriptionForLevel() {
        return LEVEL_DESCRIPTION[this.mappedLevel];
    }
    static{
        template(`
    <div
      class={{concat
        styles.component
        (if @reconnectingRoom " circleView")
        " network-quality-container"
      }}
      data-test-connection-level
    >
      {{#if this.isConnecting}}
        Connecting...
      {{else}}
        {{#unless @reconnectingRoom}}
          {{#if this.isPoorConnection}}
            <a
              href={{routeFor (array "support_article_url" "360029637211")}}
              target="_blank"
              rel="noopener noreferrer"
            >
              {{this.descriptionForLevel}}
            </a>
          {{else}}
            {{this.descriptionForLevel}}
          {{/if}}
          &nbsp;
        {{/unless}}

        {{svgJar this.svgForLevel}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
