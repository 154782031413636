import CropVideoProcessor from 'frontend/utils/processors/crop-video';

export default class CropVideoChimeProcessor {
  constructor(cropLevel, CanvasVideoFrameBuffer) {
    this.cropVideoProcessor = new CropVideoProcessor(cropLevel);
    this.targetCanvas = document.createElement('canvas');
    this.canvasVideoFrameBuffer = new CanvasVideoFrameBuffer(this.targetCanvas);
  }

  destroy() {
    this.targetCanvas = null;
    this.canvasVideoFrameBuffer.destroy();
  }

  process(buffers) {
    this.cropVideoProcessor.processFrame(buffers[0].asCanvasElement(), this.targetCanvas);
    buffers[0] = this.canvasVideoFrameBuffer;
    return buffers;
  }
}
