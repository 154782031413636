import { CLIENT_CONSENT_ACTIONS, GLOBAL_EVENT, GLOBAL_EVENT_TYPES } from 'frontend/constants';
import { headers } from 'frontend/utils/fetch-data';
import { recordingConsent } from 'frontend/utils/dialogs';
import { set } from '@ember/object';
import Service, { service } from '@ember/service';

export default class TwilioRecordingService extends Service {
  @service mixpanel;
  @service floatingUiElements;
  @service session;
  @service errorHandling;
  @service('twilio/data-track') dataTrack;

  async startRecording(roomSid) {
    await this.collectClientConsent();
    await this.startRecordingRequest(roomSid);
    this.mixpanel.track('twilio recording started');
  }

  async stopRecording(roomSid) {
    if (this.rejectClientConsent) {
      this.floatingUiElements.hideRecordingNotification();
      return this.rejectClientConsent();
    }
    await this.stopRecordingRequest(roomSid);
    this.floatingUiElements.hideRecordingNotification();
    this.mixpanel.track('twilio recording stopped');
  }

  async startRecordingRequest(roomSid) {
    let init = {
      method: 'POST',
      body: JSON.stringify({ roomSid }),
      headers: {
        ...headers(),
        'Content-Type': 'application/json',
      },
    };
    let url = `/frontend/recordings`;

    let { ok } = await fetch(url, init);
    if (!ok) throw new Error('Failed to start recording');
  }

  async stopRecordingRequest(roomSid) {
    let init = {
      method: 'PATCH',
      body: JSON.stringify({ roomSid, stop: true }),
      headers: {
        ...headers(),
        'Content-Type': 'application/json',
      },
    };
    let url = `/frontend/recordings/${roomSid}`;

    let { ok } = await fetch(url, init);
    if (!ok) throw new Error('Failed to stop recording');
  }

  async collectClientConsent() {
    this.floatingUiElements.showWaitingRecordingNotification();

    return new Promise((resolve, reject) => {
      set(this, 'rejectClientConsent', reject);
      let receiveMessage = ({ message }) => {
        set(this, 'rejectClientConsent', null);
        if (message.clientAction === CLIENT_CONSENT_ACTIONS.allowed) {
          this.floatingUiElements.showAlowedRecordingNotification();
          this.dataTrack.removeMessageHandler(
            GLOBAL_EVENT_TYPES.collectClientConsent,
            receiveMessage
          );
          return resolve();
        }
        this.floatingUiElements.showDeclinedRecordingNotification();
        this.dataTrack.removeMessageHandler(
          GLOBAL_EVENT_TYPES.collectClientConsent,
          receiveMessage
        );
        return reject();
      };

      this.dataTrack.addMessageHandler(GLOBAL_EVENT_TYPES.collectClientConsent, receiveMessage);
      this.dataTrack.sendMessage(
        {
          type: GLOBAL_EVENT_TYPES.collectClientConsent,
          clientAction: CLIENT_CONSENT_ACTIONS.request,
        },
        GLOBAL_EVENT
      );
    });
  }

  async handleClientConsent(message) {
    if (message.clientAction !== CLIENT_CONSENT_ACTIONS.request) return;

    let { dismiss } = await recordingConsent();

    this.dataTrack.sendMessage(
      {
        type: GLOBAL_EVENT_TYPES.collectClientConsent,
        clientAction: dismiss ? CLIENT_CONSENT_ACTIONS.declined : CLIENT_CONSENT_ACTIONS.allowed,
      },
      GLOBAL_EVENT_TYPES.collectClientConsent
    );
  }
}
