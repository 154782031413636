import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { modifier } from 'ember-modifier';
import { reads } from 'macro-decorators';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import CallScreenSharingContainer from 'frontend/components/call/screen-sharing-container';
import CallSidebarScrollArrow from 'frontend/components/call/sidebar/scroll-arrow';
import CallSpeaker from 'frontend/components/call/speaker';
import Component from '@glimmer/component';
import PreviewVideoTile from 'frontend/components/call/preview-video-tile';
import WaitingPlaceholder from 'frontend/components/waiting-placeholder';
import styles from './roster.module.scss';
export default class CallSidebarRoster extends Component {
    @service('chime.remote-videos')
    remoteVideos;
    @service('chime.meeting-manager')
    meetingManager;
    @service('chime.local-audio-video')
    localAudioVideo;
    @service('chime.roster')
    rosterService;
    @service('chime.content-sharing')
    contentSharing;
    @service
    session;
    @service
    appointment;
    @service
    uiElements;
    @tracked
    isArrowUpShown = false;
    @tracked
    isArrowDownShown = false;
    @reads('appointment.mainAttendeeId')
    mainAttendeeId;
    @reads('contentSharing.sharedEntity')
    sharedEntity;
    @reads('uiElements.pinnedAttendeeId')
    pinnedAttendeeId;
    @reads('uiElements.isSidebarShown')
    isSidebarShown;
    @reads('uiElements.isSelfViewShown')
    isSelfViewShown;
    @reads('localAudioVideo.tileId')
    localTileId;
    @reads('session.inWaitingRoom')
    inWaitingRoom;
    @reads('meetingManager.localAttendeeId')
    localAttendeeId;
    @reads('meetingManager.isLeft')
    isLeft;
    @reads('rosterService.roster')
    roster;
    element = null;
    get isLocalAttendeeShown() {
        return this.localAttendeeId !== this.mainAttendeeId && this.isSelfViewShown;
    }
    get shownAttendees() {
        if (!this.localAttendeeId || this.inWaitingRoom || this.isLeft) return null;
        let attendees1 = [];
        let hideMainAttendee1 = !!this.pinnedAttendeeId || !this.sharedEntity;
        this.roster.forEach((_1, attendeeId1)=>{
            if (attendeeId1 === this.mainAttendeeId && hideMainAttendee1) return;
            if (!this.isLocalAttendeeShown && this.rosterService.isLocal(attendeeId1)) return;
            attendees1.push(attendeeId1);
        });
        return attendees1;
    }
    get showWaitingPlaceholder() {
        return !this.rosterService.hasRemoteAttendees && !!this.pinnedAttendeeId;
    }
    get showLocalVideoPreview() {
        return !this.isLeft && this.inWaitingRoom;
    }
    get showScreenShare() {
        return this.contentSharing.isScreenShared && this.pinnedAttendeeId;
    }
    @action
    handleScrollOrResize(isEvent1 = true) {
        this._handleSizeChangeTask.perform(isEvent1);
    }
    _handleSizeChangeTask = restartableTask(async (isEvent1)=>{
        await timeout(100);
        if (!this.element?.isConnected) return;
        let { clientHeight: clientHeight1 = 0, scrollTop: scrollTop1, scrollHeight: scrollHeight1 } = this.element;
        let hasScroll1 = clientHeight1 !== scrollHeight1;
        let scrolledToBottom1 = isEvent1 && scrollTop1 + clientHeight1 === scrollHeight1;
        this.isArrowUpShown = scrollTop1 !== 0;
        this.isArrowDownShown = hasScroll1 && !scrolledToBottom1;
    });
    initChangeHandlers = modifier((element1)=>{
        let mutationObserver1 = new MutationObserver(this.handleScrollOrResize);
        this.element = element1;
        window.addEventListener('resize', this.handleScrollOrResize);
        mutationObserver1.observe(this.element, {
            childList: true
        });
        this.element.addEventListener('scroll', this.handleScrollOrResize);
        this.handleScrollOrResize(false);
        return ()=>{
            window.removeEventListener('resize', this.handleScrollOrResize);
            mutationObserver1.disconnect();
            this.element.removeEventListener('scroll', this.handleScrollOrResize);
        };
    });
    static{
        template(`
    <div
      class={{concat styles.component (unless this.isSidebarShown " collapsed")}}
      {{this.initChangeHandlers}}
    >
      <CallSidebarScrollArrow @up={{true}} class={{unless this.isArrowUpShown "hidden"}} />

      {{#if this.showWaitingPlaceholder}}
        <WaitingPlaceholder @smallView={{true}} />
      {{/if}}

      {{#if this.showLocalVideoPreview}}
        <PreviewVideoTile />
      {{/if}}

      {{#if this.showScreenShare}}
        <CallScreenSharingContainer class="sidebar-height" data-test-screen-preview-sidebar />
      {{/if}}

      {{#each this.shownAttendees as |attendeeId|}}
        <CallSpeaker @attendeeId={{attendeeId}} />
      {{/each}}
      <CallSidebarScrollArrow class={{unless this.isArrowDownShown "hidden"}} />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
