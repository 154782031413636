import { COLORS } from './colors';

export { WHITEBOARD_SHAPES, COMMON_SHAPES_CONFIG, SHAPES } from './shapes';
export { STICKERS_CONFIG, STICKERS } from './stickers';
export const PREVIEWABLE_NAMESPACE = 'previewable';
export const DRAWING_EVENT = 'mousemove.drawing touchmove.drawing';
export const DEFAULT_OPACITY = 1;
export const PREVIEW_OPACITY = 0.25;
export const WHITEBOARD_TOOLS = {
  draw: 'draw',
  text: 'text',
  select: 'select',
  image: 'image',
  shapes: 'shapes',
  stickers: 'stickers',
  vanishingPen: 'vanishing_pen',
};
export const PREVIEWABLE_TOOLS = [
  WHITEBOARD_TOOLS.shapes,
  WHITEBOARD_TOOLS.stickers,
  WHITEBOARD_TOOLS.vanishingPen,
  WHITEBOARD_TOOLS.draw,
];
export const MESSAGE_TYPE = 'whiteboard';
export const TRANSFORMER_ID = 'transformer-drag-box';
export const BACKGROUND_ID = 'background';
export const WHITEBOARD_COLORS = {
  black: COLORS.smoke700,
  blue: COLORS.ocean500,
  red: COLORS.wine500,
  green: COLORS.mint500,
  yellow: COLORS.lemon500,
  white: COLORS.white,
};

export const PEN_SIZE_OPTIONS = {
  highlight: {
    size: 24,
    opacity: PREVIEW_OPACITY,
  },
  thick: {
    size: 24,
  },
  medium: {
    size: 12,
  },
  thin: {
    size: 4,
  },
};

export const EVENT_TYPES = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  clear: 'clear',
};
export const BASE_WIDTH = 1104;
export const BASE_HEIGHT = 621;

export const FILE_TYPES = {
  pdf: 'application/pdf',
  png: 'image/png',
  image: 'image/*',
  json: 'application/json',
};

export const DEFAULT_LINE_OPTIONS = {
  opacity: DEFAULT_OPACITY,
  lineCap: 'round',
  lineJoin: 'round',
  tension: 1,
};

export const VANISHING_LINE_OPTIONS = {
  innerColor: COLORS.plum500,
  innerPreviewColor: COLORS.smoke500,
  innerRadius: 2,
  outerColor: COLORS.plum500,
  outerOpacity: 0.25,
  outerPreviewColor: COLORS.smoke700,
  outerPreviewOpacity: 0.12,
  outerRadius: 6,
};

export const WHITEBOARD_ENTITIES = {
  line: 'line',
  shape: 'shape',
  text: 'text',
  vanishingLine: 'vanishing-line',
};

export const BULLET_SYMBOL = ' • ';

export const TEXT_VIRTUAL_PADDING = 5;
export const TEXT_WIDTH_MULTIPLIER = 4;
export const TEXT_HEIGHT_MULTIPLIER = 1.5;
export const TOOLTIP_SHOW_COUNT_CAP = 4;
export const FIXED_WIDTH = 'fixed-width';
export const DRAW_DELAY_MS = 15;
