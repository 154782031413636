export const SETTINGS_TABS = {
  video: 'video',
  audio: 'audio',
  background: 'background',
  general: 'general',
  hostControl: 'host-control',
};

export const SETTINGS_TABS_LIST = [
  {
    id: SETTINGS_TABS.video,
    title: 'Video',
    icon: 'videocam',
  },
  {
    id: SETTINGS_TABS.audio,
    title: 'Audio',
    icon: 'mic',
  },
  {
    id: SETTINGS_TABS.background,
    title: 'Background',
    icon: 'person-background',
  },
  {
    id: SETTINGS_TABS.general,
    title: 'General',
    icon: 'gear',
  },
];

export const TIMER_START_TYPES = {
  calendarAppointmentStarts: 'calendar_appointment_starts',
  firstParticipantJoins: 'first_participant_joins',
  hostJoins: 'host_joins',
};

export const HOST_CONTROLS = {
  backgroundEnabled: 'backgroundEnabled',
  chatEnabled: 'chatEnabled',
  confirmEnding: 'confirmEnding',
  sharingEnabled: 'sharingEnabled',
  waitingRoomEnabled: 'waitingRoomEnabled',
};

export const EVENT_NAMES_BY_CONTROL = {
  [HOST_CONTROLS.backgroundEnabled]: 'background control',
  [HOST_CONTROLS.chatEnabled]: 'chat control',
  [HOST_CONTROLS.confirmEnding]:
    'get a confirmation message to prevent ending a session unintentionally',
  [HOST_CONTROLS.sharingEnabled]: 'share control',
  [HOST_CONTROLS.waitingRoomEnabled]: 'waiting room',
};

export const PARTICIPANT_PERMISSIONS = [
  HOST_CONTROLS.chatEnabled,
  HOST_CONTROLS.sharingEnabled,
  HOST_CONTROLS.backgroundEnabled,
];
