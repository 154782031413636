import MobileDetect from 'mobile-detect';

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
}

export function isTablet() {
  let md = new MobileDetect(navigator?.userAgent);
  return !!md.tablet() || isNewIpad();
}

// After Apple iOS 13 release, navigator.userAgent in Safari on iPad iOS 13 is same as on MacOS.
export function isNewIpad() {
  return /Macintosh/i.test(navigator.userAgent) && navigator?.maxTouchPoints > 2;
}

export function isMobileOrTablet() {
  let md = new MobileDetect(navigator?.userAgent);
  return !!md.mobile() || isNewIpad();
}
