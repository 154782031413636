import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import { typeOf } from '@ember/utils';

const ALLOWED_ATTRIBUTE_NAMES = ['rel', 'class', 'target'];

// URL regex courtesy of https://github.com/kevva/url-regex
function urlRegex() {
  return /(["'])?(?:(?:(?:(?:https?|ftp|\w):)?\/\/)|(?:www.))(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:1\d\d|2[0-4]\d|25[0-4]|[1-9]\d?))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/#]\S*)?\1/gi;
}
const escape = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '`': '&#x60;',
  '=': '&#x3D;',
};

const badChars = /[&<>"'`=]/g;
const possible = /[&<>"'`=]/;

function escapeChar(chr) {
  return escape[chr];
}
export function escapeExpression(string) {
  if (typeof string !== 'string') {
    // don't escape SafeStrings, since they're already safe
    if (string && string.toHTML) {
      return string.toHTML();
    } else if (string === null) {
      return '';
    } else if (!string) {
      return string + '';
    }

    // Force a string conversion as this will be done by the append regardless and
    // the regex test will do this transparently behind the scenes, causing issues if
    // an object's to string has escaped characters in it.
    string = '' + string;
  }

  if (!possible.test(string)) {
    return string;
  }
  return string.replace(badChars, escapeChar);
}

export function linkify(params, options) {
  let textToLinkify = escapeExpression(params[0]);
  let sharedAttributes = opts2attrs(options);

  textToLinkify = textToLinkify.replace(urlRegex(), function (s) {
    let url;
    let displayText = s.trim();

    if (s.trim().match(/^www\./gi)) {
      url = '//' + s.trim();
    } else {
      url = s.trim();
    }

    return `<a href="${url}"${sharedAttributes}>${displayText}</a>`;
  });

  return htmlSafe(textToLinkify);
}

export default helper(linkify);

function opts2attrs(options) {
  let stringOfAttributes = [''];

  if (typeOf(options) === 'object') {
    for (let i = 0; i < ALLOWED_ATTRIBUTE_NAMES.length; i++) {
      let attributeName = ALLOWED_ATTRIBUTE_NAMES[i];
      if (attributeName in options) {
        stringOfAttributes.push(`${attributeName}="${options[attributeName]}"`);
      }
    }
  }

  return stringOfAttributes.join(' ');
}
