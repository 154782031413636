import { template } from "@ember/template-compiler";
import Modal from 'frontend/components/shared/modal';
import styles from './diagnostics-modal.module.scss';
export default template(`
  <Modal
    @close={{@close}}
    @class={{styles.component}}
    @didInsert={{@trackDiagnosticsMixpanelEvent}}
    data-test-diagnostics-modal
  >
    <section class="container-body">
      <iframe
        id="diagnosticsModal"
        allow="autoplay;camera;microphone;speaker-selection"
        title="Diagnostics Modal"
        width="100%"
        height="100%"
        frameborder="0"
        src={{@frameSrc}}
      >
      </iframe>
    </section>
  </Modal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
