/* import __COLOCATED_TEMPLATE__ from './users.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from 'frontend/components/call/sidebar/roster.module.scss';

@classic
@classNameBindings('isExpanded::collapsed')
@classNames(styles.component)
export default class TwilioSidebarUsers extends Component {
  @service('twilio/video-stream') videoStream;

  @reads('videoStream.hideSelfView') hideSelfView;
  @reads('videoStream.localParticipant') localParticipant;
  @reads('videoStream.participants') participants;
  @reads('videoStream.noParticipants') noParticipants;
  @reads('videoStream.shareParticipant') shareParticipant;
  @reads('videoStream.selectedParticipant') selectedParticipant;

  @computed('hideSelfView', 'localParticipant', 'selectedParticipant')
  get hidePublisher() {
    return this.videoStream.hideSelfView || this.localParticipant === this.selectedParticipant;
  }

  @computed('shareParticipant', 'selectedParticipant')
  get showScreenPreview() {
    return !!this.shareParticipant && !!this.selectedParticipant;
  }

  @computed('selectedParticipant', 'noParticipants')
  get showWaitingPlaceholder() {
    return this.noParticipants && !!this.selectedParticipant;
  }
}
