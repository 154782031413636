/* import __COLOCATED_TEMPLATE__ from './media-settings-modal.hbs'; */
import { PROCESSOR_TYPES } from 'frontend/constants/backgrounds';
import { SETTINGS_TABS, SETTINGS_TABS_LIST } from 'frontend/constants/settings';
import { VIDEO_QUALITY_TYPES } from 'frontend/constants';
import { action } from '@ember/object';
import { alias, and, equal, not, or, reads } from 'macro-decorators';
import { isSafari } from 'frontend/utils/detect-browser';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import styles from './media-settings-modal.module.scss';

export default class CallMediaSettingsModal extends Component {
  @service('twilio/room') twilioRoom;
  @service('twilio/local-tracks') localTracks;
  @service mediaDevices;
  @service mediaSettings;
  @service persistentProperties;
  @service appointmentSettings;
  @service session;
  @service mixpanel;
  @service devicesAdapter;

  settingsTabs = SETTINGS_TABS;
  defaultVideoQuality = VIDEO_QUALITY_TYPES.sd;
  noiseCancellationError = false;
  isSafari = isSafari();
  styles = styles;

  @reads('devicesAdapter.audioInputDevices') audioInputDevices;
  @reads('devicesAdapter.audioOutputDevices') audioOutputDevices;
  @reads('devicesAdapter.selectedAudioInputDeviceId') selectedAudioInputDeviceId;
  @reads('devicesAdapter.selectedAudioOutputDeviceId') selectedAudioOutputDeviceId;
  @reads('devicesAdapter.selectedVideoDeviceId') selectedVideoDeviceId;
  @reads('devicesAdapter.videoDevices') videoDevices;
  @reads('mediaSettings.activeSettingsTab') activeSettingsTab;
  @reads('persistentProperties.selectedVideoQuality') selectedVideoQuality;
  @reads('session.isHost') isHost;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('twilioRoom.audioTrackPublishing') audioTrackPublishing;
  @reads('twilioRoom.hdNotSupported') hdNotSupported;
  @reads('twilioRoom.selectedVideoProcessorType') selectedVideoProcessorType;
  @reads('twilioRoom.toggleNoiseCancellationTask.last.isError') toggleNoiseCancellationTaskError;
  @reads('twilioRoom.videoTrackPublishing') videoTrackPublishing;
  @alias('persistentProperties.noiseCancellationEnabled') noiseCancellationEnabled;
  @alias('persistentProperties.isSelfViewMirrored') isSelfViewMirrored;
  @equal('selectedVideoProcessorType', PROCESSOR_TYPES.crop) isVideoCropped;
  @or('videoTrackPublishing', 'processingQuality') disableVideoChanging;
  @or('selectedVideoQuality', 'defaultVideoQuality') videoQuality;
  @or('twilioRoom.toggleNoiseCancellationTask.isRunning', 'audioInputChangeTask.isRunning')
  isAudioTrackProcessing;
  @or(
    'isAudioTrackProcessing',
    'toggleNoiseCancellationTaskError',
    'isNoiseCancellationNotSupportedByBrowser'
  )
  isNoiseCancellationDisabled;
  @not('session.roomModel.featureThChime') isTwilio;
  @and('isSafari', 'isTwilio') isNoiseCancellationNotSupportedByBrowser;

  audioInputChangeTask = task(async ({ target: { value } }) => {
    await this.devicesAdapter.handleAudioInputDeviceChange(value);
  });

  videoInputChangeTask = task(async ({ target: { value } }) => {
    await this.devicesAdapter.handleVideoDeviceChange(value);
  });

  videoQualityChangeTask = task(async ({ target: { value } }) => {
    if (this.videoQuality === value) return;
    await this.devicesAdapter.handleVideoQualityChange(value);
  });

  @action
  async noiseCancellationChanged() {
    await this.devicesAdapter.handleNoiseCancellationChange();
  }

  get audioInputDevicesList() {
    return this.#convertDevicesToOptionsList(this.audioInputDevices);
  }

  get audioOutputDevicesList() {
    return this.#convertDevicesToOptionsList(this.audioOutputDevices);
  }

  get videoDevicesList() {
    return this.#convertDevicesToOptionsList(this.videoDevices);
  }

  get videoOptions() {
    return [
      {
        value: VIDEO_QUALITY_TYPES.auto,
        label: 'Auto',
      },
      {
        value: VIDEO_QUALITY_TYPES.ld,
        label: 'Low-definition (LD)',
      },
      {
        value: VIDEO_QUALITY_TYPES.sd,
        label: 'Standard-definition (SD)',
      },
      {
        value: VIDEO_QUALITY_TYPES.hd,
        label: 'High-definition (HD)',
        disabled: this.hdNotSupported,
        disabledTooltip: 'Selected device does not support HD quality.',
      },
    ];
  }

  get settingsTabsList() {
    if (!this.isHost || !this.featureThClinicianAuth) return SETTINGS_TABS_LIST;

    return [
      { id: SETTINGS_TABS.hostControl, title: 'Host control', icon: 'user-shield' },
      ...SETTINGS_TABS_LIST,
    ];
  }

  get processingQuality() {
    return this.videoInputChangeTask.isRunning || this.videoQualityChangeTask.isRunning;
  }

  @action
  getDevices() {
    this.mediaDevices.getDevices();
  }

  @action
  close() {
    this.mediaSettings.closeSettingsModal();
  }

  @action
  audioOutputChanged({ target: { value } }) {
    return this.devicesAdapter.handleAudioOutputDeviceChange(value);
  }

  @action
  async cropVideoChanged(value) {
    await this.twilioRoom.setVideoProcessorType(
      this.twilioRoom.videoTrack,
      value ? PROCESSOR_TYPES.crop : PROCESSOR_TYPES.none
    );
  }

  @action
  mirrorSelfViewChanged() {
    this.persistentProperties.setProp('isSelfViewMirrored', !this.isSelfViewMirrored);
    this.mixpanel.track('mirror self view', {
      status: this.isSelfViewMirrored ? 'enabled' : 'disabled',
    });
  }

  #convertDevicesToOptionsList(devices = []) {
    return devices.map(({ deviceId: value, label }) => ({ value, label }));
  }
}
