import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, not } from 'ember-truth-helpers';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { toggle } from 'ember-composable-helpers/helpers/toggle';
import { tracked } from '@glimmer/tracking';
import AttachTooltip from 'ember-attacher/components/attach-tooltip';
import Component from '@glimmer/component';
import styles from './item.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class CallControlsItem extends Component {
    @tracked
    processing = false;
    @tracked
    ariaExpanded = false;
    get ariaLabel() {
        return this.args.ariaLabel || 'Call control action';
    }
    get active() {
        return this.args.active ?? true;
    }
    get isDisabled() {
        return this.args.disabled || this.processing;
    }
    get ariaHasPopup() {
        return !!this.args.popoverComponent;
    }
    @action
    async onClick() {
        if (this.isDisabled || !this.args.onClick) return;
        this.processing = true;
        try {
            await this.args.onClick();
        } finally{
            this.processing = false;
        }
    }
    static{
        template(`
    <button
      aria-expanded={{this.ariaExpanded}}
      aria-disabled={{this.isDisabled}}
      aria-label={{this.ariaLabel}}
      aria-haspopup={{this.ariaHasPopup}}
      class={{concat
        styles.component
        (concat " " @variant)
        (if this.isDisabled " disabled")
        (if @ghost " ghost")
      }}
      data-test-call-control={{@label}}
      ...attributes
      type="button"
      {{on "click" this.onClick}}
    >
      <div class="svg-wrapper {{concat @variant (if this.active ' active')}}">
        {{#if (and (not this.active) @inactiveIcon)}}
          {{svgJar @inactiveIcon class="icon-error"}}
        {{else}}
          {{svgJar @icon class="icon-error"}}
        {{/if}}
      </div>
      <div class="label">
        {{if this.active @label @inactiveLabel}}
      </div>
      {{#if (and @disabled @disabledTooltip)}}
        <AttachTooltip @placement="top" @class="tooltip {{unless @controlsShown 'hidden'}}">
          {{@disabledTooltip}}
        </AttachTooltip>
      {{/if}}

      {{#if (and @popoverComponent (not this.isDisabled))}}
        <@popoverComponent
          @onChange={{toggle "ariaExpanded" this}}
          @ariaControls={{this.controlsElementId}}
        />
      {{/if}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
