/* import __COLOCATED_TEMPLATE__ from './unsupported-browser-modal.hbs'; */
import { classNames } from '@ember-decorators/component';
import Super from 'ember-simplepractice/components/shared/unsupported-browser-modal';
import classic from 'ember-classic-decorator';
import styles from './unsupported-browser-modal.module.scss';

@classic
@classNames(styles.component)
export default class UnsupportedBrowserModal extends Super {
  styles = styles;
}
