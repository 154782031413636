import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat, fn } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import { reads } from 'macro-decorators';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ActionButton from 'frontend/components/action-button';
import CallScreenSharingContainer from 'frontend/components/call/screen-sharing-container';
import CallSpeaker from 'frontend/components/call/speaker';
import Component from '@glimmer/component';
import styles from './grid-view.module.scss';
export default class CallGridView extends Component {
    @service('chime.remote-videos')
    remoteVideos;
    @service('chime.content-sharing')
    contentSharing;
    @service('chime.roster')
    rosterService;
    @service
    uiElements;
    @tracked
    mainViewWidth = 0;
    @tracked
    mainViewHeight = 0;
    @tracked
    page = 1;
    maxParticipantsCount = 16;
    widthProportion = 16;
    heightProportion = 9;
    element = null;
    @reads('rosterService.roster')
    roster;
    @reads('uiElements.isSelfViewShown')
    isSelfViewShown;
    get visibleTilesCount() {
        return this.shownAttendees.length + (this.contentSharing.shareAttendeeId ? 1 : 0);
    }
    get participantsCountStyle() {
        if (this.visibleTilesCount === 1) {
            return 'single';
        } else if (this.visibleTilesCount === 2) {
            return 'two';
        } else if (this.visibleTilesCount < 5) {
            return 'up-to-4';
        } else if (this.visibleTilesCount < 10) {
            return 'up-to-9';
        }
        return 'up-to-16';
    }
    get hasPagination() {
        return this.visibleTilesCount > this.maxParticipantsCount;
    }
    get paginationPageClass() {
        return this.hasPagination ? `page-${this.page}` : '';
    }
    get paginationButtonClass() {
        return this.page === 1 ? 'right' : 'left';
    }
    get style() {
        return htmlSafe(`width:${this.mainViewWidth}px;height:${this.mainViewHeight}px;`);
    }
    get shownAttendees() {
        let attendeeIds1 = [];
        this.roster.forEach((_1, attendeeId1)=>{
            if (!this.isSelfViewShown && this.rosterService.isLocal(attendeeId1)) return;
            attendeeIds1.push(attendeeId1);
        });
        return attendeeIds1;
    }
    @action
    changePagination(buttonType1) {
        this.page += buttonType1 === 'right' ? 1 : -1;
    }
    @action
    resizeView() {
        this.resizeViewTask.perform();
    }
    initGridView = modifier((element1)=>{
        let mutationObserver1 = new MutationObserver(this.resizeView);
        this.element = element1.parentElement;
        mutationObserver1.observe(element1, {
            childList: true
        });
        window.addEventListener('resize', this.resizeView);
        this.resizeView();
        return ()=>{
            window.removeEventListener('resize', this.resizeView);
            mutationObserver1.disconnect();
        };
    });
    resizeViewTask = restartableTask(async ()=>{
        await timeout(100);
        if (!this.element?.isConnected) return;
        let { widthProportion: widthProportion1, heightProportion: heightProportion1 } = this;
        let specificWidthProportion1 = this.visibleTilesCount === 2 ? widthProportion1 * 2 : widthProportion1;
        let { height: height1, width: width1 } = this.element.getBoundingClientRect();
        if (height1 / width1 < heightProportion1 / specificWidthProportion1) {
            this.mainViewWidth = Math.round((height1 / heightProportion1) * specificWidthProportion1);
            this.mainViewHeight = height1;
        } else {
            this.mainViewWidth = width1;
            this.mainViewHeight = Math.round((width1 / specificWidthProportion1) * heightProportion1);
        }
    });
    static{
        template(`
    <div class={{styles.component}} ...attributes>
      <div
        id="grid-view"
        class={{concat
          this.participantsCountStyle
          " "
          this.paginationPageClass
          (if this.hasPagination " container-justify-start")
          " users-container"
        }}
        style={{this.style}}
        data-test-grid-view
        {{this.initGridView}}
      >
        <ActionButton
          @icon="chevron-right"
          @onClick={{fn this.changePagination this.paginationButtonClass}}
          class={{concat
            "pagination-button "
            this.paginationButtonClass
            (unless this.hasPagination " hidden")
          }}
          data-test-pagination-button
        />
        {{#if this.contentSharing.shareAttendeeId}}
          <CallScreenSharingContainer class="tile" />
        {{/if}}
        {{#each this.shownAttendees as |attendeeId|}}
          <CallSpeaker @attendeeId={{attendeeId}} class="tile" />
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
