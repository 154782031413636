export const JOIN_REQUEST_ACTION_SOURCES = {
  modal: 'modal',
  notification: 'notification',
};

export const JOIN_REQUEST_ACTIONS = {
  admitAll: 'admit_all',
  admitOne: 'admit_one',
  admitRemaining: 'admit_remaining',
  decline: 'decline',
};

export const JOIN_NOTIFICATION_ACTIONS = {
  viewAll: 'view_all',
  hide: 'hide_notification',
  expand: 'expand_from_pill',
};
