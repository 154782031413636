import { set, setProperties } from '@ember/object';
import { underscoreKeys } from 'frontend/utils/transform-object';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';
import mixpanel from 'mixpanel-browser';

const NAME_PREFIX = 'telehealth: ';

@classic
export default class MixpanelService extends Service {
  apiKey;
  eventPayload;
  initialized = false;

  init() {
    super.init(...arguments);
    this.mixpanel = window.mixpanel || mixpanel;
  }

  _initialize(key) {
    if (!key) return false;

    set(this, 'initialized', true);
    this.mixpanel.init(key);

    return true;
  }

  setInitialData(data) {
    let {
      mixpanelKey,
      roomId,
      appointmentId,
      clinicianId,
      token: appointmentToken,
      featureThChime,
    } = data;

    setProperties(this, {
      apiKey: mixpanelKey,
      eventPayload: underscoreKeys({
        roomId,
        appointmentId,
        clinicianId,
        appointmentToken,
        chime: featureThChime,
      }),
    });
  }

  track(eventName, properties = {}) {
    let { skipPrefix, ...rest } = properties;
    let isInitialized = this.initialized || this._initialize(this.apiKey);
    if (!isInitialized) return;

    if (!skipPrefix) {
      eventName = `${NAME_PREFIX}${eventName}`;
    }

    this.mixpanel.track(eventName, { ...this.eventPayload, ...rest, source: 'web' });
  }

  trackLinks(selector, eventName, properties) {
    let isInitialized = this.initialized || this._initialize(this.apiKey);
    if (!isInitialized) return;

    this.mixpanel.track_links(selector, eventName, properties);
  }
}
