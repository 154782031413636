import { task, timeout } from 'ember-concurrency';
import Service from '@ember/service';

const INITIAL_DELAY_MS = 2000;
const MAX_RETRIES = 3;
const RETRY_FACTOR = 2;

export default class DataFetcherService extends Service {
  @task(function* (fetchFn) {
    let delay = INITIAL_DELAY_MS;
    let retryCount = 0;
    let error;

    while (retryCount < MAX_RETRIES) {
      try {
        return yield fetchFn();
      } catch (e) {
        error = e;
        retryCount++;
        yield timeout(delay);
        delay *= RETRY_FACTOR;
      }
    }

    throw error;
  })
  fetchWithRetry;
}
