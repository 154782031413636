import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './video.module.scss';

@classic
@classNames('video-wrap', styles.component)
export default class SharedMediaModalContainerVideo extends Component {
  @computed('videoId', 'wistiaParams')
  get iframeSrc() {
    return `https://fast.wistia.net/embed/iframe/${this.videoId}?${this.wistiaParams}`;
  }

  @computed('isAutoPlayUrl', 'isControlHidden', 'isVideoLooped')
  get wistiaParams() {
    let params = [];
    if (this.isAutoPlayUrl) {
      params.push('autoPlay=true');
    }
    if (this.isControlHidden) {
      params.push(
        'playbar=false',
        'settingsControl=false',
        'smallPlayButton=false',
        'volumeControl=false',
        'muted=true'
      );
    }
    if (this.isVideoLooped) {
      params.push('endVideoBehavior=loop');
    }
    return params.join('&');
  }
}
