import { registerDeprecationHandler } from '@ember/debug';

const IGNORED_DEPRECATIONS = [
  'ember-string.add-package', // NOTE: buggy deprecation https://deprecations.emberjs.com/v4.x/#toc_ember-string-add-package
];

export function initialize() {
  registerDeprecationHandler((message, options, _next) => {
    if (IGNORED_DEPRECATIONS.includes(options.id)) {
      return;
    }

    throw new Error(message);
  });
}

export default { initialize };
