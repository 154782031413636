import { CHAT_MESSAGE_TYPE } from 'frontend/constants';
import { and, reads } from '@ember/object/computed';
import { getOwner } from '@ember/owner';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class ChatService extends Service {
  @service('chime.roster') roster;
  @service persistentProperties;
  @service floatingUiElements;
  @service session;

  @tracked messages = [];
  @tracked isChatShown = false;
  @tracked firstUnseenMessage = null;
  @tracked lastUnseenMessage = null;
  messagingService;

  @reads('persistentProperties.isChatNotificationEnabled') isChatNotificationEnabled;
  @reads('floatingUiElements.chatNotificationShown') chatNotificationShown;
  @reads('session.roomModel.featureThChime') featureThChime;
  @and('isChatNotificationEnabled', 'chatNotificationShown') isNotificationShown;

  init() {
    super.init(...arguments);
    let messagingServiceName = this.featureThChime
      ? 'service:chime.data-messaging'
      : 'service:twilio.data-track';
    this.messagingService = getOwner(this).lookup(messagingServiceName);
    this.messagingService.addMessageHandler(CHAT_MESSAGE_TYPE, this.receiveMessage.bind(this));
  }

  receiveMessage(message) {
    if (this.featureThChime) {
      message.participantName = this.roster.getAttendee(message.senderAttendeeId)?.name;
    }
    if (!this.isChatShown) {
      this.lastUnseenMessage = message;
      this.firstUnseenMessage = this.firstUnseenMessage || message;
      this.floatingUiElements.setChatNotificationVisibility(true);
    }
    this.addMessage(message);
  }

  addMessage(message, isOwn = false) {
    this.messages.pushObject({
      ...message,
      isOwn,
      samePreviousAuthor: this.messages.lastObject?.participantSid === message.participantSid,
    });
  }

  async sendMessage(text) {
    let message = await this.messagingService.sendMessage(text, CHAT_MESSAGE_TYPE);

    this.addMessage(message, true);
  }

  toggleChat() {
    this.toggleProperty('isChatShown');

    if (this.isChatShown) {
      this.lastUnseenMessage = null;
      this.closeNotification();
    }
  }

  closeNotification() {
    this.floatingUiElements.setChatNotificationVisibility(false);
  }

  resetUnseenMessage() {
    this.firstUnseenMessage = null;
  }

  resetChatProperties() {
    this.messages = [];
    this.isChatShown = false;
    this.firstUnseenMessage = null;
    this.lastUnseenMessage = null;
    this.closeNotification();
  }
}
