import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import Component from '@glimmer/component';
import styles from './scroll-arrow.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class CallSidebarScrollArrow extends Component {
    @action
    handleClick({ target: target1 }) {
        let { parentElement: el1 } = target1;
        el1.scrollTop += this.args.up ? -el1.clientHeight : el1.clientHeight;
    }
    static{
        template(`
    <button
      class={{concat styles.component " " (if @up "up" "down")}}
      {{on "click" this.handleClick}}
      type="button"
      ...attributes
    >
      {{svgJar "chevron-down"}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
