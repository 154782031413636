export function computedSessionJSON(key) {
  return {
    get() {
      try {
        return JSON.parse(sessionStorage.getItem(key));
      } catch {
        return null;
      }
    },
    set(_, value) {
      try {
        sessionStorage.setItem(key, JSON.stringify(value));
      } catch {
        // noop
      }
      // return value in any case to make it cached
      // so it works before page reload even with disabled cookies
      return value;
    },
  };
}
