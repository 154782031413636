import { enqueueTask, restartableTask, timeout } from 'ember-concurrency';
import Service, { service } from '@ember/service';
import joinRequestSoundSrc from '/assets/audio/waiting.mp3';
import joinSoundSrc from '/assets/audio/join.mp3';
import leaveSoundSrc from '/assets/audio/leave.mp3';

export default class SoundNotificationService extends Service {
  @service devicesAdapter;

  joinSound = new Audio(joinSoundSrc);
  leaveSound = new Audio(leaveSoundSrc);
  joinRequestSound = new Audio(joinRequestSoundSrc);
  notificationsAllowed = false;
  notificationsDelayAfterJoin = 2000;

  playJoinSound() {
    return this.#playSound('joinSound');
  }

  playLeaveSound() {
    return this.#playSound('leaveSound');
  }

  playJoinRequestSound() {
    return this.#playSound('joinRequestSound', { ignoreDelay: true });
  }

  async #playSound(soundName, { ignoreDelay } = {}) {
    if (!this.notificationsAllowed && !ignoreDelay) return;

    try {
      await this.playTask.perform(soundName);
    } catch (_err) {
      // sometimes user device is not available to play sound
    }
  }

  playTask = enqueueTask(async soundName => {
    let sound = this[soundName];

    if (this.devicesAdapter.selectedAudioOutputDeviceId) {
      await sound.setSinkId?.(this.devicesAdapter.selectedAudioOutputDeviceId).catch(() => {});
    }
    await sound.play();
    await timeout(sound.duration);
    sound.currentTime = 0;
  });

  notificationDelayTask = restartableTask(async () => {
    await timeout(this.notificationsDelayAfterJoin);
    this.notificationsAllowed = true;
  });

  handleStart() {
    this.notificationDelayTask.perform();
    this.joinSound.load();
    this.leaveSound.load();
    this.joinRequestSound.load();
  }

  resetDelay() {
    this.notificationsAllowed = false;
    this.playTask.cancelAll();
  }
}
