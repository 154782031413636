export default class CropVideoProcessor {
  constructor(cropLevel) {
    this.cropLevel = cropLevel;
  }

  processFrame(inputFrameBuffer, outputFrameBuffer) {
    let croppedWidth = inputFrameBuffer.width * (1 - this.cropLevel * 2);
    outputFrameBuffer.width = inputFrameBuffer.width;
    outputFrameBuffer.height = inputFrameBuffer.height;
    let context = outputFrameBuffer.getContext('2d');

    context.drawImage(
      inputFrameBuffer,
      inputFrameBuffer.width * this.cropLevel,
      inputFrameBuffer.height * this.cropLevel,
      croppedWidth,
      (croppedWidth / 16) * 9,
      0,
      0,
      outputFrameBuffer.width,
      outputFrameBuffer.height
    );
  }
}
