/* import __COLOCATED_TEMPLATE__ from './room-instructions.hbs'; */
import { action } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { modifier } from 'ember-modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitForNext } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import styles from './room-instructions.module.scss';

const START_TIME_LIMIT = 600_000;

export default class WelcomeScreenRoomInstructions extends Component {
  @service floatingUiElements;
  @service mixpanel;
  @service session;

  @tracked isEarly = true;
  styles = styles;

  @reads('session.roomModel') model;
  @reads('model.featureThClinicianAuth') featureThClinicianAuth;
  @reads('model.featureThChime') featureThChime;
  @reads('floatingUiElements.showDiagnosticsModal') showDiagnosticsModal;

  initSessionStartTask = task(async () => {
    while (this.isEarly) {
      let timeToBreakLimit = this.diffFromStart - START_TIME_LIMIT;
      this.setIsEarly();
      if (isEmberTesting()) break;

      await timeout(timeToBreakLimit);
    }
  });

  initSessionStart = modifier(async () => {
    await waitForNext();
    this.initSessionStartTask.perform();
  });

  setIsEarly() {
    this.isEarly = this.diffFromStart > START_TIME_LIMIT;
  }

  @action
  toggleDiagnosticsModal() {
    this.floatingUiElements.toggleDiagnosticsModal();
  }

  @action
  trackDiagnosticsMixpanelEvent() {
    this.mixpanel.track('precall test run');
  }

  get diffFromStart() {
    return this.model.startTime.diff(moment());
  }

  get diagnosticsModalUrl() {
    if (isEmberTesting()) return '';

    let url = new URL(window.location.host.replace(/^[^.]*/, 'https://diagnostics'));

    if (this.featureThChime) {
      url.searchParams.set('chime', true);
    }

    return url.toString();
  }
}
