import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import CallControlsItem from 'frontend/components/call/controls/item';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class WelcomeScreenLocalVideoControls extends Component {
    get controlsList() {
        return [
            {
                icon: 'control-mic',
                inactiveIcon: 'mic-off',
                onClick: this.args.toggleLocalAudio,
                active: this.args.isAudioPublished,
                disabled: !this.args.isMicrophoneAvailable,
                ariaLabel: this.args.isAudioPublished ? 'Mute microphone' : 'Unmute microphone'
            },
            {
                icon: 'control-video',
                inactiveIcon: 'cam-off',
                onClick: this.args.toggleLocalVideo,
                active: this.args.isVideoPublished,
                disabled: !this.args.isCameraAvailable,
                ariaLabel: this.args.isVideoPublished ? 'Stop video' : 'Start video'
            }
        ];
    }
    static{
        template(`
    <button
      class="button ghost settings-button"
      type="button"
      {{on "click" @openSettings}}
      data-test-control-media-settings-button
      ...attributes
    >
      {{svgJar "gear"}}
      Telehealth settings
    </button>

    <div class="media-controls-container">
      {{#each this.controlsList key="@index" as |control|}}
        <CallControlsItem
          @icon={{control.icon}}
          @inactiveLabel={{control.inactiveLabel}}
          @inactiveIcon={{control.inactiveIcon}}
          @active={{control.active}}
          @onClick={{control.onClick}}
          @disabled={{control.disabled}}
          @ariaLabel={{control.ariaLabel}}
          @ghost={{true}}
          class="call-controls-item"
        />
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
