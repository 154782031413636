import { template } from "@ember/template-compiler";
import { HOST_CONTROLS } from 'frontend/constants/settings';
import { SHAREABLE_ENTITIES } from 'frontend/constants';
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { empty, reads } from 'macro-decorators';
import { ensureAppointmentEnding } from 'frontend/utils/dialogs';
import { service } from '@ember/service';
import CallControlsItem from 'frontend/components/call/controls/item';
import Component from '@glimmer/component';
import SharePopoverComponent from 'frontend/components/call/controls/share-popover';
import styles from './panel.module.scss';
export default class CallControlsPanel extends Component {
    @service('twilio/recording')
    recording;
    @service
    chat;
    @service
    router;
    @service
    session;
    @service
    floatingUiElements;
    @service
    appointmentSettings;
    @service
    appointment;
    @service
    audioVideoAdapter;
    @service
    call;
    disableControls = false;
    @reads('audioVideoAdapter.isAudioPublished')
    publishAudio;
    @reads('audioVideoAdapter.isVideoPublished')
    publishVideo;
    @reads('audioVideoAdapter.isSharing')
    isSharing;
    @reads('audioVideoAdapter.sharedEntity')
    sharedEntity;
    @reads('call.shouldShowRatingPage')
    shouldShowRatingPage;
    @reads('chat.isChatShown')
    isChatShown;
    @reads('chat.lastUnseenMessage')
    lastUnseenMessage;
    @reads('floatingUiElements.controlsShown')
    controlsShown;
    @reads('session.roomSid')
    roomSid;
    @reads('session.roomModel.featureThChime')
    featureThChime;
    @reads('session.roomModel.featureThClinicianAuth')
    featureThClinicianAuth;
    @reads('session.roomModel.featureThHostControls')
    featureThHostControls;
    @reads('session.roomModel.featureAiNotetaker')
    featureAiNotetaker;
    @reads('session.isRecording')
    isRecording;
    @reads('session.isHost')
    isHost;
    @reads('session.inWaitingRoom')
    inWaitingRoom;
    @empty('session.participants')
    hasNoParticipants;
    @reads('appointmentSettings.sharingEnabled')
    isSharingEnabled;
    get controlsList() {
        return [
            this.microphoneButtonOptions,
            this.cameraButtonOptions,
            this.noteTakerButtonOptions,
            this.chatButtonOptions,
            this.shareButtonOptions,
            this.endCallButtonOptions
        ].filter(Boolean);
    }
    @action
    toggleMute() {
        return this.audioVideoAdapter.toggleLocalAudio();
    }
    @action
    toggleVideo() {
        return this.audioVideoAdapter.toggleLocalVideo();
    }
    @action
    toggleChat() {
        this.chat.toggleChat();
    }
    @action
    async endCall() {
        let shouldShowRatingPage1 = this.shouldShowRatingPage;
        if (this.isHost && this.appointmentSettings.confirmEnding) {
            let { value: value1, dismiss: dismiss1 } = await ensureAppointmentEnding();
            if (value1) {
                this.appointmentSettings.update({
                    [HOST_CONTROLS.confirmEnding]: false
                });
            }
            if (dismiss1) return;
        }
        this.audioVideoAdapter.leave();
        if (shouldShowRatingPage1) {
            this.router.transitionTo('appointment.rating');
        } else {
            this.router.transitionTo('appointment');
        }
    }
    get microphoneButtonOptions() {
        return {
            icon: 'control-mic',
            label: 'Mute mic',
            inactiveIcon: 'mic-off',
            inactiveLabel: 'Unmute',
            onClick: this.toggleMute,
            active: this.publishAudio,
            disabled: this.args.disableControls,
            ariaLabel: `${this.publishAudio ? 'Mute' : 'Unmute'} microphone`
        };
    }
    get cameraButtonOptions() {
        return {
            icon: 'control-video',
            label: 'Stop video',
            inactiveIcon: 'cam-off',
            inactiveLabel: 'Start video',
            onClick: this.toggleVideo,
            active: this.publishVideo,
            disabled: this.args.disableControls,
            ariaLabel: this.publishVideo ? 'Stop video' : 'Start video'
        };
    }
    get noteTakerButtonOptions() {
        if (!this.isHost || !this.featureAiNotetaker || this.featureThChime) return null;
        return {
            icon: 'start-recording',
            label: 'Record audio',
            inactiveIcon: 'stop-recording',
            inactiveLabel: 'Stop recording',
            onClick: ()=>this.isRecording ? this.recording.stopRecording(this.roomSid) : this.recording.startRecording(this.roomSid),
            active: !this.isRecording,
            disabled: this.disableControls || !this.roomSid || this.hasNoParticipants,
            ariaLabel: 'Record audio',
            variant: 'grey-disabled'
        };
    }
    get chatButtonOptions() {
        if (this.inWaitingRoom) return null;
        return {
            icon: 'chat',
            label: 'Chat',
            inactiveIcon: this.lastUnseenMessage ? 'chat-unread' : 'chat',
            inactiveLabel: 'Chat',
            onClick: this.toggleChat,
            active: this.isChatShown,
            ariaLabel: this.isChatShown ? 'Close chat' : 'Open chat'
        };
    }
    get shareButtonOptions() {
        if (this.isShareHidden) return null;
        return {
            label: 'Share',
            onClick: this.isSharing ? this.toggleSharing : null,
            popoverComponent: this.isSharing ? null : SharePopoverComponent,
            active: !this.isSharing,
            ariaLabel: this.sharedEntity ? `Stop ${this.sharedEntity} sharing` : 'Start sharing',
            disabled: this.args.disableControls || (!this.isSharing && this.sharedEntity),
            disabledTooltip: !this.args.disableControls ? `Another participant is sharing ${this.sharedEntity === SHAREABLE_ENTITIES.screen ? 'their screen' : 'a Whiteboard'}.` : null,
            icon: 'control-screen-share',
            inactiveIcon: 'control-stop-sharing',
            inactiveLabel: 'Stop sharing',
            variant: 'green'
        };
    }
    get endCallButtonOptions() {
        let label1 = !this.featureThClinicianAuth || this.isHost ? 'End' : 'Leave';
        let inactiveLabel1 = !this.featureThClinicianAuth || this.isHost ? 'Ending...' : 'Leaving...';
        return {
            icon: 'control-call',
            variant: 'red',
            label: label1,
            inactiveLabel: inactiveLabel1,
            onClick: this.endCall,
            active: true,
            ariaLabel: 'End call',
            disabled: this.disableControls
        };
    }
    get isShareHidden() {
        return (this.inWaitingRoom || (this.featureThHostControls && !this.isSharingEnabled && !this.isHost));
    }
    @action
    toggleSharing() {
        this.audioVideoAdapter.toggleSharing();
    }
    static{
        template(`
    <div
      class={{concat
        styles.component
        (unless this.controlsShown " fadeout")
        (if this.sharedEntity " docked")
      }}
      data-test-call-controls-panel
      ...attributes
    >
      {{#each this.controlsList key="@index" as |control|}}
        {{#unless control.hidden}}
          <CallControlsItem
            @icon={{control.icon}}
            @label={{control.label}}
            @variant={{control.variant}}
            @inactiveLabel={{control.inactiveLabel}}
            @inactiveIcon={{control.inactiveIcon}}
            @active={{control.active}}
            @onClick={{control.onClick}}
            @disabled={{control.disabled}}
            @disabledTooltip={{control.disabledTooltip}}
            @ariaLabel={{control.ariaLabel}}
            @popoverComponent={{control.popoverComponent}}
            @controlsElementId={{control.controlsElementId}}
            @controlsShown={{this.controlsShown}}
          />
        {{/unless}}
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
