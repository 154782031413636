import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';

export class Randomizer {
  baseValues;

  constructor(length) {
    this.baseValues = range(1, length).sort(() => Math.random() - 0.5);
  }

  getItem(index) {
    if (isEmberTesting()) {
      return 1;
    }
    return this.baseValues[index % this.baseValues.length];
  }
}

export const range = (from, to) =>
  Array(to - from + 1)
    .fill(null)
    .map((_, i) => i + from);
