import { ROOM_REFERRER } from 'frontend/constants';
import { action, computed } from '@ember/object';
import { alias, or, reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class AppointmentRoomController extends Controller {
  @service('twilio/video-stream') twilioVideoStream;
  @service('twilio/room') twilioRoom;
  @service chat;
  @service mediaDevices;
  @service session;
  @service router;
  @service floatingUiElements;
  @service appointment;
  @service uiElements;
  @service call;

  @tracked sidebarElement = null;

  queryParams = ['referrer'];
  referrer = null;

  @reads('twilioRoom.connected') twilioConnected;
  @reads('twilioRoom.isVideoPublished') isVideoPublished;
  @reads('twilioRoom.isAudioPublished') isAudioPublished;
  @reads('twilioRoom.localParticipant.networkLevel') localNetworkLevel;
  @reads('twilioRoom.connectionError') connectionError;
  @reads('twilioVideoStream.hasMainView') twilioHasMainView;
  @reads('chat.isChatShown') isChatShown;
  @reads('chat.isNotificationShown') isChatNotificationShown;
  @reads('floatingUiElements.hideControlsTask') hideControlsTask;
  @reads('floatingUiElements.eventNotificationShown') eventNotificationShown;
  @reads('floatingUiElements.controlsShown') controlsShown;
  @reads('floatingUiElements.joinRequestNotificationShown') joinRequestNotificationShown;
  @reads('floatingUiElements.RecordingNotificationHidden') RecordingNotificationHidden;
  @reads('session.isHost') isHost;
  @reads('session.inWaitingRoom') inWaitingRoom;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('call.isConnected') isConnected;
  @alias('twilioVideoStream.gridView') twilioGridView;
  @or('isVideoPublished', 'isAudioPublished') mediaEnabled;

  joinCallTask = task(async () => {
    this._processReferrer();

    if (this.featureThChime) {
      return this.appointment.join();
    }

    let devices = await this.mediaDevices.enumerateDevices();
    await this.mediaDevices.takeDevicesControl(devices);
    let { roomModel } = this.twilioRoom;

    this.twilioRoom.setConnectionError(null);
    if (this.session.isHost || !roomModel.featureThClinicianAuth) {
      await this.twilioRoom.joinRoom();
    } else {
      await this.twilioRoom.joinTestRoom();
    }

    if (this.connectionError) {
      this.router.transitionTo('appointment');
    }
  });

  @computed('isHost', 'isConnected', 'featureThClinicianAuth')
  get isWaiting() {
    return this.featureThClinicianAuth && !this.isHost && !this.isConnected;
  }

  @action
  activateView() {
    this.floatingUiElements.setControlsVisibility(true);
    this.floatingUiElements.hideControlsTask.perform();
  }

  @action
  onSidebarPanelInsert(element) {
    this.sidebarElement = element;
  }

  _processReferrer() {
    if (!this.session.isAuthenticated || this.referrer !== ROOM_REFERRER) return;

    this.session.showLoginNotification();
    this.router.transitionTo({ queryParams: { referrer: null } });
  }
}
