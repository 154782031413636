import { template } from "@ember/template-compiler";
import { modifier } from 'ember-modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class CallMeetingAudio extends Component {
    @service('chime.meeting-manager')
    meetingManager;
    @service
    call;
    @reads('call.isConnected')
    isConnected;
    initAudio = modifier((audioElement1)=>{
        this.meetingManager.audioVideo?.bindAudioElement(audioElement1);
    });
    willDestroy() {
        super.willDestroy(...arguments);
        this.meetingManager.audioVideo?.unbindAudioElement();
    }
    static{
        template(`
    {{#if this.isConnected}}
      {{! template-lint-disable require-media-caption }}
      <audio {{this.initAudio}} style="display: none"></audio>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
