import EmberRouter from '@ember/routing/router';
import classic from 'ember-classic-decorator';
import config from './config/environment';

@classic
class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('unsupported-browser');
  this.route('download-app');
  this.route('appointment', { path: '/:room_id' }, function () {
    this.route('rating');
    this.route('room');
    this.route('ended');
  });
  this.route('error', function () {
    this.route('video-disabled');
    this.route('invalid-appointment');
    this.route('session-creation-failed');
  });
  this.route('error/invalid-appointment', { path: '/*path' });
});

export default Router;
