import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class ChimeRemoteVideosService extends Service {
  @service('chime.meeting-manager') meetingManager;

  tileIdToAttendeeId = {};
  attendeeIdToTileId = tracked({});

  setup() {
    this.audioVideoObserver = {
      videoTileDidUpdate: this.videoTileDidUpdate,
      videoTileWasRemoved: this.videoTileWasRemoved,
    };
    this.meetingManager.audioVideo.addObserver(this.audioVideoObserver);
  }

  @action
  videoTileDidUpdate(tileState) {
    if (
      tileState?.boundAttendeeId &&
      tileState?.tileId &&
      !tileState.isContent &&
      !tileState.localTile
    ) {
      let { tileId, boundAttendeeId: attendeeId } = tileState;
      let tileStr = tileId.toString();
      let isPresent = this.tileIdToAttendeeId[tileStr];

      if (isPresent) return;

      this.tileIdToAttendeeId[tileStr] = attendeeId;
      this.attendeeIdToTileId[attendeeId] = tileId;
    }
  }

  @action
  videoTileWasRemoved(tileId) {
    let attendeeId = this.tileIdToAttendeeId[tileId];

    if (!attendeeId) return;

    delete this.tileIdToAttendeeId[tileId.toString()];
    delete this.attendeeIdToTileId[attendeeId];
  }

  reset() {
    this.tileIdToAttendeeId = {};
    this.attendeeIdToTileId = tracked({});
    this.meetingManager.audioVideo?.removeObserver(this.audioVideoObserver);
  }
}
