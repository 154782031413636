import { LATEST_API_VERSION } from 'frontend/constants';
import {
  camelCaseKeys,
  capitalizeKeys,
  dropNullValues,
  underscoreKeys,
} from 'frontend/utils/transform-object';
import moment from 'moment-timezone';

const BAD_REQUEST_STATUS = 400;

export const headers = () => {
  return {
    'Content-Type': 'application/vnd.api+json',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
  };
};

export const fetchSafely = async (url, init) => {
  let response = await fetch(url, init);

  if (!response.ok) {
    return {
      error: new Error('Fetch error', { cause: response }),
    };
  }

  return { response };
};

export const fetchUserData = async () => {
  let { response, error } = await fetchSafely('/frontend/user');

  if (error) throw error;

  try {
    let responseJson = await response.json();

    if (Object.prototype.hasOwnProperty.call(responseJson, 'errors')) {
      return null;
    }

    let { id, ...rest } = camelCaseKeys(responseJson);
    return { id: parseInt(id, 10), ...rest };
  } catch (e) {
    return null;
  }
};

export const fetchRoomModelData = async (roomId, participantId) => {
  let { response, error } = await fetchSafely(
    `/frontend/rooms/${roomId}?participant_id=${participantId}`,
    {
      headers: { 'Api-Version': LATEST_API_VERSION },
    }
  );

  if (error && error.cause.status !== BAD_REQUEST_STATUS) throw error;

  if (error?.cause.status === BAD_REQUEST_STATUS) {
    response = error.cause;
  }

  let responseJson = await response.json();

  if (Object.prototype.hasOwnProperty.call(responseJson, 'errors')) {
    return { error: responseJson };
  }
  let { startTime, endTime, hostJoinTime, ...rest } = camelCaseKeys(responseJson);
  let roomModel = {
    roomId,
    startTime: moment(startTime),
    endTime: moment(endTime),
    hostJoinTime: hostJoinTime && moment(hostJoinTime),
    featureThAppleReactions: true,
    ...rest,
  };

  return { roomModel };
};

export async function fetchMeetingData(requestParams) {
  let { response, error } = await fetchSafely('/frontend/meetings', {
    method: 'POST',
    body: JSON.stringify({ meeting: underscoreKeys(requestParams) }),
    headers: {
      ...headers(),
      'Api-Version': LATEST_API_VERSION,
      'Content-Type': 'application/json',
    },
  });

  if (error) throw error;

  const { meeting, attendees } = await response.json();

  return {
    meeting: capitalizeKeys(camelCaseKeys(dropNullValues(meeting))),
    attendee: capitalizeKeys(camelCaseKeys(dropNullValues(attendees[0]))),
  };
}

export async function fetchDynamicAppLink({ roomId, origin }) {
  let url = `/frontend/dynamic_links?room_id=${roomId}`;
  if (origin) {
    url = `${url}&origin=${origin}`;
  }
  let { response, error } = await fetchSafely(url);

  if (error) throw error;

  let { dynamicLink } = camelCaseKeys(await response.json());

  return dynamicLink;
}

export async function fetchNearestChimeRegion() {
  let { response, error } = await fetchSafely('https://nearest-media-region.l.chime.aws');

  if (error) throw error;

  let { region } = await response.json();

  return region;
}
