import { Shape } from 'konva/lib/Shape';

export default class Grid extends Shape {
  _sceneFunc(ctx) {
    ctx.beginPath();
    ctx.moveTo(0, this.height() / 2);
    ctx.lineTo(this.width(), this.height() / 2);
    ctx.moveTo(this.width() / 2, 0);
    ctx.lineTo(this.width() / 2, this.height());
    ctx.strokeShape(this);
  }
}
