import * as ActionCable from '@rails/actioncable';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class ActionCableService extends Service {
  @service persistentProperties;
  @service session;

  @reads('persistentProperties.uuid') uuid;
  @reads('session.roomModel.roomId') roomId;

  @computed('uuid', 'session.userData', 'roomId')
  get socketURL() {
    let socketUrl = `/websocket?uuid=${this.uuid}&room_id=${this.roomId}`;
    let { userData } = this.session;

    return userData?.id ? `${socketUrl}&clinician_id=${userData.id}` : socketUrl;
  }

  @computed('socketURL')
  get consumer() {
    return ActionCable.createConsumer(this.socketURL);
  }
}
