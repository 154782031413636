export const VIRTUAL_BACKGROUNDS_MAP = {
  light: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/light.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/light.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/light.jpg',
  },
  calm: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/calm.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/calm.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/calm.jpg',
  },
  cozy: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/cozy.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/cozy.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/cozy.jpg',
  },
  evening: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/evening.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/evening.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/evening.jpg',
  },
  sky: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/sky.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/sky.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/sky.jpg',
  },
  neutral: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/neutral.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/neutral.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/neutral.jpg',
  },
  spring: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/spring.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/spring.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/spring.jpg',
  },
  summer: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/summer.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/summer.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/summer.jpg',
  },
  fall: {
    thumbnail: '/assets/images/virtual-backgrounds/thumbnail/fall.jpg',
    sd: '/assets/images/virtual-backgrounds/sd/fall.jpg',
    hd: '/assets/images/virtual-backgrounds/hd/fall.jpg',
  },
};

export const DEFAULT_BLUR_PROCESSOR_OPTIONS = {
  assetsPath: '/tflite',
  blurFilterRadius: 10,
  maskBlurRadius: 1,
};

export const DEFAULT_VIRTUAL_PROCESSOR_OPTIONS = {
  assetsPath: '/tflite',
  maskBlurRadius: 1,
  fitType: 'Cover',
};

export const PROCESSOR_TYPES = Object.keys(VIRTUAL_BACKGROUNDS_MAP).reduce(
  (acc, name) => ({ ...acc, [name]: name }),
  { none: 'none', crop: 'crop', blur: 'blur' }
);
