import { debounce } from '@ember/runloop';
import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';

function cleanup(instance) {
  let { element, resizeObserver } = instance;

  if (element && resizeObserver) {
    resizeObserver.unobserve(element);
  }
}

export default class AspectRatioModifier extends Modifier {
  element = null;
  resizeObserver = null;
  widthProportion = null;
  heightProportion = null;
  onResize = null;

  #resizeElement() {
    if (!this.element) return;

    let { widthProportion, heightProportion, onResize } = this;
    let { height, width } = this.element.parentElement.getBoundingClientRect();

    if (height / width < heightProportion / widthProportion) {
      let normalizedHeight = this.#normalizeLength(height, heightProportion);
      this.element.style.width = `${(normalizedHeight / heightProportion) * widthProportion}px`;
      this.element.style.height = `${normalizedHeight}px`;
    } else {
      let normalizedWidth = this.#normalizeLength(width, widthProportion);
      this.element.style.width = `${normalizedWidth}px`;
      this.element.style.height = `${(normalizedWidth / widthProportion) * heightProportion}px`;
    }

    onResize?.(this.element);
  }

  #normalizeLength(length, proportion) {
    let diff = length % proportion;
    return diff > proportion / 2 ? length + diff : length - diff;
  }

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, cleanup);
  }

  modify(element, _, { widthProportion, heightProportion, onResize }) {
    if (!this.element) {
      this.element = element;
    }

    cleanup(this);
    this.widthProportion = widthProportion;
    this.heightProportion = heightProportion;
    this.onResize = onResize;
    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(() => {
        debounce(this, this.#resizeElement, 150);
      });
    });
    this.resizeObserver.observe(this.element.parentElement);
  }
}
