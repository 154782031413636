import { Shape } from 'konva/lib/Shape';

export const AREA_HEIGHT = 48;

export default class Line extends Shape {
  constructor() {
    super(...arguments);

    this.height(AREA_HEIGHT);
  }

  _sceneFunc(ctx) {
    ctx.save();
    ctx.translate(0, this.height() / 2);
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(this.width(), 0);
    ctx.restore();
    ctx.strokeShape(this);
  }

  getResizeOptions({ width }) {
    return { width };
  }
}
