import { template } from "@ember/template-compiler";
import { eq } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import styles from './settings-select.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default template(`
  <div class={{styles.component}} ...attributes>
    <label for={{@id}} class="select-title">{{@title}}</label>
    <div class="select-wrap">
      <select
        id={{@id}}
        class="select {{if @disabled 'loading'}}"
        disabled={{@disabled}}
        {{on "change" @onChanged}}
        data-test-select={{@id}}
      >
        {{#each @optionsList key=@itemKey as |item|}}
          <option value={{item.value}} selected={{eq @selectedValue item.value}}>
            {{item.label}}
          </option>
        {{/each}}
      </select>
      {{svgJar "caret" class="select-caret"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
