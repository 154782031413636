import {
  HD_VIDEO_CONSTRAINTS,
  LD_VIDEO_CONSTRAINTS,
  SD_WIDE_VIDEO_CONSTRAINTS,
  VIDEO_QUALITY_TYPES,
} from 'frontend/constants/index';

export const CHIME_MEETING_STATUS = {
  Loading: 0,
  Succeeded: 1,
  Left: 2,
};

const DEFAULT_FRAME_RATE = 15;
export const QUALITY_OPTIONS = {
  [VIDEO_QUALITY_TYPES.ld]: {
    width: LD_VIDEO_CONSTRAINTS.width,
    height: LD_VIDEO_CONSTRAINTS.height,
    frameRate: DEFAULT_FRAME_RATE,
    maxBandwidth: 600,
  },
  [VIDEO_QUALITY_TYPES.sd]: {
    width: SD_WIDE_VIDEO_CONSTRAINTS.width.exact,
    height: SD_WIDE_VIDEO_CONSTRAINTS.height.exact,
    frameRate: DEFAULT_FRAME_RATE,
    maxBandwidth: 1400,
  },
  [VIDEO_QUALITY_TYPES.hd]: {
    width: HD_VIDEO_CONSTRAINTS.width.exact,
    height: HD_VIDEO_CONSTRAINTS.height.exact,
    frameRate: DEFAULT_FRAME_RATE,
    maxBandwidth: 1400,
  },
};

export const CHIME_MEETING_RECONNECT_TIMEOUT_MS = 45_000;
export const CHIME_MEETING_SESSION_CONNECTION_TIMEOUT_MS = 30_000;
export const CHIME_MEETING_SESSION_RECONNECT_TIMEOUT_MS = 30_000;
