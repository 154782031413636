import {
  DEFAULT_LINE_OPTIONS,
  VANISHING_LINE_OPTIONS,
  WHITEBOARD_ENTITIES,
} from 'frontend/constants/whiteboard';
import { drawLine } from 'frontend/utils/whiteboard';
import { later } from '@ember/runloop';
import { setProperties } from '@ember/object';
import BaseEntity from 'frontend/entities/whiteboard/base';

export default class WhiteboardVanishingLine extends BaseEntity {
  type = WHITEBOARD_ENTITIES.vanishingLine;
  showDuration = 3000;
  hideAnimationDuration = 500;
  KonvaTween;

  setInitAttributes({ previewMode, points }) {
    this.konvaObject.setAttrs({ sceneFunc: this._sceneFunc.bind(this), previewMode, points });
  }

  constructor({ KonvaTween }) {
    super(...arguments);
    setProperties(this, { KonvaTween });
  }

  scheduleVanishing() {
    let tween = new this.KonvaTween({
      node: this.konvaObject,
      duration: this.hideAnimationDuration / 1000,
      onFinish: () => this.konvaObject.destroy(),
      opacity: 0,
    });

    later(() => tween.play(), this.showDuration);
  }

  get points() {
    return this.konvaObject.points();
  }

  get opacity() {
    return this.konvaObject.opacity();
  }

  _sceneFunc(context, _shape) {
    let {
      outerPreviewOpacity,
      outerOpacity,
      outerColor,
      innerPreviewColor,
      outerPreviewColor,
      outerRadius,
      innerColor,
      innerRadius,
    } = VANISHING_LINE_OPTIONS;
    let isPreviewMode = this.konvaObject.attrs.previewMode;
    let strokeOpacity = isPreviewMode ? outerPreviewOpacity : outerOpacity;
    let strokeColor = isPreviewMode ? outerPreviewColor : outerColor;
    let fillColor = isPreviewMode ? innerPreviewColor : innerColor;
    let options = { strokeColor, fillColor, outerRadius, innerRadius };

    context.globalAlpha = strokeOpacity * this.opacity;

    // According to the spec, a browser should prune all zero-length line segments from the path.
    // https://html.spec.whatwg.org/multipage/canvas.html#trace-a-path
    if (this.points.length === 2) {
      this._drawOnePoint(context, options);
    } else {
      this._drawVanishingLine(context, options);
    }
  }

  _drawOnePoint(context, { strokeColor, fillColor, outerRadius, innerRadius }) {
    let { points } = this;

    context.beginPath();
    context.moveTo(points[0], points[1]);
    context.fillStyle = strokeColor;
    context.arc(points[0], points[1], outerRadius, 0, Math.PI * 2);
    context.fill();
    context.beginPath();
    context.fillStyle = fillColor;
    context.globalAlpha = this.opacity;
    context.arc(points[0], points[1], innerRadius, 0, Math.PI * 2);
    context.fill();
  }

  _drawVanishingLine(context, { strokeColor, fillColor, outerRadius, innerRadius }) {
    let { points } = this;
    let { lineCap, lineJoin } = DEFAULT_LINE_OPTIONS;

    context.strokeStyle = strokeColor;
    context.lineCap = lineCap;
    context.lineJoin = lineJoin;
    [outerRadius * 2, innerRadius * 2].forEach(lineWidth => {
      context.beginPath();
      context.moveTo(points[0], points[1]);
      context.lineWidth = lineWidth;
      drawLine(context, points);
      context.stroke();
      context.globalAlpha = this.opacity;
      context.strokeStyle = fillColor;
    });
  }
}
