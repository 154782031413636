/* import __COLOCATED_TEMPLATE__ from './toolbar.hbs'; */
import { COLORS } from 'frontend/constants/colors';
import { FILE_TYPES, WHITEBOARD_TOOLS } from 'frontend/constants/whiteboard';
import { action, computed, set } from '@ember/object';
import { classNameBindings, classNames, tagName } from '@ember-decorators/component';
import {
  getColorPaletteOptions,
  getShapesPaletteOptions,
  getStickersPaletteOptions,
} from 'frontend/utils/whiteboard';
import { information } from 'frontend/utils/modals';
import { not } from '@ember/object/computed';
import { offset } from '@floating-ui/dom';
import { ref } from 'ember-ref-bucket';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './toolbar.module.scss';

const MAXIMUM_FILE_SIZE = 1572864; // 1.5MB

@classic
@tagName('aside')
@classNameBindings('hidden')
@classNames(styles.component)
export default class WhiteboardToolbar extends Component {
  styles = styles;

  @ref('fileInput') fileInput;
  @ref('imageButton') imageButton;
  @not('isShown') hidden;

  middleware = [offset(6)];
  inputAccept = `${FILE_TYPES.image},${FILE_TYPES.pdf}`;
  tools = WHITEBOARD_TOOLS;
  colorPaletteOptions = getColorPaletteOptions();
  stickersOptions = getStickersPaletteOptions();
  shapesOptions = getShapesPaletteOptions();

  @computed('isWhiteboardLocked', 'isSharing')
  get isShown() {
    return this.isSharing || !this.isWhiteboardLocked;
  }

  @computed('tool', 'drawColor')
  get drawButtonStyle() {
    if (this.tool !== WHITEBOARD_TOOLS.draw) {
      return '';
    }

    return `background-color:${this.drawColor};${
      this.drawColor === COLORS.white
        ? `fill:${COLORS.smoke700};box-shadow: inset 0 0 0 1px ${COLORS.smoke400}`
        : `fill:${COLORS.white}`
    };`;
  }

  @computed('tool', 'textColor')
  get textButtonStyle() {
    if (this.tool !== WHITEBOARD_TOOLS.text) {
      return '';
    }

    return `background-color:${this.textColor};${
      this.textColor === COLORS.white
        ? `fill:${COLORS.smoke700};box-shadow: inset 0 0 0 1px ${COLORS.smoke400}`
        : `fill:${COLORS.white}`
    };`;
  }

  @action
  setIsDrawPopoverShown(isShown) {
    if (isShown) {
      this.onSelectTool(WHITEBOARD_TOOLS.draw);
    }

    set(this, 'isDrawPopoverShown', isShown);
  }

  @action
  setIsTextPopoverShown(isShown) {
    if (isShown) {
      this.onSelectTool(WHITEBOARD_TOOLS.text);
    }

    set(this, 'isTextPopoverShown', isShown);
  }

  @action
  setIsShapePopoverShown(isShown) {
    if (isShown) {
      this.onSelectTool(WHITEBOARD_TOOLS.shapes);
    }

    set(this, 'isShapePopoverShown', isShown);
  }

  @action
  setIsStickerPopoverShown(isShown) {
    if (isShown) {
      this.onSelectTool(WHITEBOARD_TOOLS.stickers);
    }

    set(this, 'isStickerPopoverShown', isShown);
  }

  @action
  onInsertImageClick() {
    this.onSelectTool('image');
    this.fileInput.click();
  }

  @action
  async handleFileUpload() {
    let file = this.fileInput.files[0];
    let humanReadableSize = MAXIMUM_FILE_SIZE / 1024 / 1024;

    this.fileInput.value = null;

    if (file.size > MAXIMUM_FILE_SIZE) {
      await information({
        title: 'The image is too big',
        text: `File ${file.name} exceeds the maximum image size of ${humanReadableSize} Mb.`,
        confirmButtonText: 'Close',
        allowOutsideClick: false,
      });
      return;
    }

    this.onUploadFile(file);
    this.imageButton.blur();
  }

  @action
  selectShape(shape) {
    this.setIsShapePopoverShown(false);
    this.onSelectShape(shape);
  }

  @action
  selectSticker(sticker) {
    this.setIsStickerPopoverShown(false);
    this.onSelectSticker(sticker);
  }
}
