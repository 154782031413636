import { Shape } from 'konva/lib/Shape';

export default class Triangle extends Shape {
  _sceneFunc(context) {
    let { width, height } = this.size();

    context.beginPath();
    context.moveTo(0, height);
    context.lineTo(width / 2, 0);
    context.lineTo(width, height);
    context.closePath();
    context.strokeShape(this);
  }
}
